import * as React from 'react';

import { ConnectionDegree } from '@xing-com/badge';
import { SuperEllipse } from '@xing-com/super-ellipse';

import { ProfileImageResponsive } from '..';
import { mapProfileSizeToBadgeSize } from '../util/map-profile-size-to-badge-size';

import * as Styled from './profile-image.styles';
import type { ProfileImageProps } from './profile-image.types';

export const ProfileImage = React.forwardRef<HTMLDivElement, ProfileImageProps>(
  (
    {
      className,
      connectionDegree,
      progressBadge,
      size,
      sizeConfined,
      sizeWide,
      sizeXWide,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const connectionDegreeSize = mapProfileSizeToBadgeSize(size);
    const connectionDegreeSizeConfined =
      mapProfileSizeToBadgeSize(sizeConfined);
    const connectionDegreeSizeWide = mapProfileSizeToBadgeSize(sizeWide);
    const connectionDegreeSizeXWide = mapProfileSizeToBadgeSize(sizeXWide);

    if (variant === 'responsive') {
      return (
        <ProfileImageResponsive
          connectionDegree={connectionDegree}
          ref={forwardedRef}
          {...props}
        />
      );
    }

    return (
      <Styled.Container
        $size={size}
        $sizeConfined={sizeConfined}
        $sizeWide={sizeWide}
        $sizeXWide={sizeXWide}
        className={className}
        data-xds="ProfileImage"
        ref={forwardedRef}
      >
        <SuperEllipse
          size={size}
          sizeConfined={sizeConfined}
          sizeWide={sizeWide}
          sizeXWide={sizeXWide}
          {...props}
        />
        {Boolean(connectionDegree) && (
          <Styled.BadgeContainer
            $size={size}
            $sizeConfined={sizeConfined}
            $sizeWide={sizeWide}
            $sizeXWide={sizeXWide}
          >
            <ConnectionDegree
              size={connectionDegreeSize}
              sizeConfined={connectionDegreeSizeConfined}
              sizeWide={connectionDegreeSizeWide}
              sizeXWide={connectionDegreeSizeXWide}
            >
              {connectionDegree}
            </ConnectionDegree>
          </Styled.BadgeContainer>
        )}
        {progressBadge &&
          !connectionDegree &&
          (size === 'xsmall' || size === 'small') && (
            <Styled.ProgressBadgeContainer $size={size}>
              {progressBadge}
            </Styled.ProgressBadgeContainer>
          )}
      </Styled.Container>
    );
  }
);

ProfileImage.displayName = 'ProfileImage';
