import * as React from 'react';
import { useIntl } from 'react-intl';

import * as Styled from './mini-badge.styles';
import type { MiniBadgeProps } from './mini-badge.types';

export const MiniBadge = React.forwardRef<HTMLSpanElement, MiniBadgeProps>(
  ({ className, role = 'status', ...props }, forwardedRef): JSX.Element => {
    const intl = useIntl();

    return (
      <Styled.MiniBadge
        aria-label={intl.formatMessage({
          id: 'XDS_MINI_BADGE_A11Y',
          defaultMessage: 'New notification',
        })}
        aria-live="polite"
        className={className}
        data-xds="MiniBadge"
        ref={forwardedRef}
        role={role}
        {...props}
      />
    );
  }
);

MiniBadge.displayName = 'MiniBadge';
