import type * as React from 'react';

import * as Styled from './button.styles';
const { sizes, variants } = Styled;

export type ButtonSizes = keyof typeof sizes;
export type ButtonVariants = keyof typeof variants;

export type ButtonProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Label of the button, should be text, icons are handled separately */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Disable the Button */
  disabled?: boolean;
  /** Activate text truncation. This needs a fixed button width or flex container around the button to work */
  ellipsis?: boolean;
  /** The href, if it should be an anchor */
  href?: string;
  /** Hand over an icon component for the button */
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  /** @deprecated Please use the "ref" property instead */
  innerRef?: React.Ref<HTMLButtonElement | HTMLAnchorElement>;
  /** Loading state of the button */
  loading?: boolean;
  /** @callback */
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  /** The size on mobile screens or larger */
  size?: ButtonSizes;
  /** The size on tablet screens or larger */
  sizeConfined?: ButtonSizes;
  /** The size on desktop screens or larger */
  sizeWide?: ButtonSizes;
  /** The to, if it should be an anchor */
  to?: string;
  /** The type of button */
  type?: 'button' | 'submit' | 'reset';
  /** The variant of button */
  variant?: ButtonVariants;
} & React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>;
