import type { XingletInterface } from '../../jobs-xinglet';
import { JobsXingletWrapper } from '../../jobs-xinglet';

import { SearchAlertsListLoadingError } from './search-alerts-list-loading-error';
import { SearchAlertsListSkeleton } from './search-alerts-list-skeleton';

export type SearchAlertsListProps = {
  notifyError: () => void;
  clearPrevError: () => void;
};

export type SearchAlertsListXinglet = XingletInterface<SearchAlertsListProps>;

export const SearchAlertsList = JobsXingletWrapper<SearchAlertsListProps>(
  '@xing-com/crate-jobs-domain-search-alerts-list',
  <SearchAlertsListSkeleton />,
  <SearchAlertsListLoadingError />
);
