import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import {
  IconReaction,
  IconVCard,
  IconNews,
  IconButterflyMono,
} from '@xing-com/icons';
import { IllustrationStickerDreamJob } from '@xing-com/illustrations';
import { mediaTopBarVisible, mediaNavExtended } from '@xing-com/layout-tokens';
import { Headline } from '@xing-com/typography';

import { InspirationalCard } from '../inspirational-card';
import { useTrackSignUpGoogle } from '../tracking';
import { useTrackSignUp } from '../tracking/tracking';

import { IconGoogle } from './assets/icon-google';
import {
  StyledSection,
  StyledImageCard,
  StyledIllustrationCard,
  StyledRow,
  StyledProfileCard,
  StyledBodyCopy,
  StyledButtonsContainer,
  StyledButton,
  StyledImageCardWrapper,
  StyledDesktopOnlyWrapper,
} from './deep-dive-section.styles';

export const DeepDiveSection: React.FC = () => {
  const isMobile = useMatchMedia(mediaTopBarVisible); // be careful: during SSR it will be always true
  const isLargeScreen = useMatchMedia(mediaNavExtended); // be careful: during SSR it will be always false
  const urlWithoutTracking = '/start/signup';
  const urlWithTracking = `${urlWithoutTracking}?sc_o=losp_sign_up_button_click&sc_o_PropActionOrigin=losp_sign_up_button_click_cta_1&signup_channel=minireg_losp`;
  const trackSignUpGoogle = useTrackSignUpGoogle();
  const trackSignUp = useTrackSignUp();
  const handleAnchorClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    window.location.assign(urlWithTracking);

    trackSignUp();
  };
  const onClickStoreButton = (): void => {
    trackSignUpGoogle();
  };

  return (
    <StyledSection>
      <StyledImageCardWrapper>
        <StyledImageCard
          size={isMobile ? 'medium' : 'large'}
          variant={1}
          minHeight="100%"
          minWidth="270px"
          maxWidth="368px"
        />
      </StyledImageCardWrapper>
      <div>
        <StyledRow>
          <StyledProfileCard
            size={isMobile ? 'medium' : 'large'}
            variant={1}
            minHeight="100%"
          >
            <Headline size="xxlarge" sizeConfined="xxxlarge">
              <FormattedMessage id="CRATE_LOSP_PROFILE_CARD_TITLE" />
            </Headline>
            <StyledBodyCopy size="large">
              <FormattedMessage id="CRATE_LOSP_PROFILE_CARD_DESCRIPTION" />
            </StyledBodyCopy>
            <StyledButtonsContainer>
              <StyledButton
                variant="onColorStrong"
                icon={IconButterflyMono}
                size="large"
                href={urlWithoutTracking}
                onClick={handleAnchorClick}
              >
                <FormattedMessage id="CRATE_LOSP_REGISTER_BUTTON" />
              </StyledButton>
              <StyledButton
                variant="onColor"
                icon={IconGoogle}
                to={
                  '/signup/oauth?caller=losp&signup_channel=minireg_losp_oauth_google'
                }
                onClick={onClickStoreButton}
                size="large"
              >
                <FormattedMessage id="CRATE_LOSP_REGISTER_WITH_GOOGLE_BUTTON" />
              </StyledButton>
            </StyledButtonsContainer>
          </StyledProfileCard>
          <StyledDesktopOnlyWrapper>
            <StyledIllustrationCard
              size={isMobile ? 'medium' : 'large'}
              variant={1}
              minHeight="100%"
              minWidth={isLargeScreen ? '240px' : 'auto'}
            >
              <IllustrationStickerDreamJob size="xxlarge" />
            </StyledIllustrationCard>
          </StyledDesktopOnlyWrapper>
        </StyledRow>
        <StyledRow>
          <InspirationalCard
            icon={IconVCard}
            title="CRATE_LOSP_CV_CARD_TITLE"
            description="CRATE_LOSP_CV_CARD_DESCRIPTION"
            link="/lebenslauf"
            variant={7}
            size={isMobile ? 'medium' : 'large'}
            headlineSize="xlarge"
            minHeight="100%"
            wrapHeadline
            trackingParam="?sc_o=losp_lebenslauf_teaser_click"
          />
          <StyledDesktopOnlyWrapper>
            <StyledIllustrationCard
              size={isMobile ? 'medium' : 'large'}
              variant={4}
              minHeight="100%"
              minWidth={isLargeScreen ? '112px' : 'auto'}
            >
              <IconReaction width={64} height={64} />
            </StyledIllustrationCard>
          </StyledDesktopOnlyWrapper>
          <InspirationalCard
            icon={IconNews}
            title="CRATE_LOSP_CAREER_HUB_CARD_TITLE"
            description="CRATE_LOSP_CAREER_HUB_CARD_DESCRIPTION"
            link="/karriere-ratgeber"
            variant={6}
            size={isMobile ? 'medium' : 'large'}
            headlineSize="xlarge"
            minHeight="100%"
            wrapHeadline
            trackingParam="?sc_o=losp_career_hub_teaser_click"
          />
        </StyledRow>
      </div>
    </StyledSection>
  );
};
