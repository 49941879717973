import * as React from 'react';
import { useIntl } from 'react-intl';

import { cornerRadiusXL } from '@xing-com/tokens';

import type { SkeletonCardProps } from '../';

import * as Styled from './skeleton-card.styles';

export const SkeletonCard = React.forwardRef<HTMLDivElement, SkeletonCardProps>(
  ({ children, className, noPadding, ...props }, forwardedRef): JSX.Element => {
    const intl = useIntl();

    return (
      <Styled.Card
        $noPadding={noPadding}
        aria-busy="true"
        aria-label={intl.formatMessage({
          id: 'XDS_SKELETON_GENERIC_A11Y',
          defaultMessage: 'Loading',
        })}
        className={className}
        cornerRadius={cornerRadiusXL}
        data-xds="SkeletonCard"
        ref={forwardedRef}
        role="status"
        variant="outline"
        {...props}
      >
        {children}
      </Styled.Card>
    );
  }
);

SkeletonCard.displayName = 'SkeletonCard';
