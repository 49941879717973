import times from 'lodash/times';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { scale130, scale160 } from '@xing-com/tokens';

import { SkeletonSuperEllipse } from '..';
import type { SkeletonFacepileProps } from '..';

import * as Styled from './skeleton-facepile.styles';

export const SkeletonFacepile = React.forwardRef<
  HTMLDivElement,
  SkeletonFacepileProps
>(
  (
    { amount = 3, className, size = 'medium', ...props },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();
    const sizes = {
      small: scale130,
      medium: scale160,
    };

    return (
      <Styled.Container
        $size={size}
        aria-busy="true"
        aria-label={intl.formatMessage({
          id: 'XDS_SKELETON_GENERIC_A11Y',
          defaultMessage: 'Loading',
        })}
        className={className}
        data-xds="SkeletonContentSwitcher"
        ref={forwardedRef}
        role="status"
        {...props}
      >
        {times(amount, (index) => (
          <SkeletonSuperEllipse key={index} size={sizes[size]} />
        ))}
      </Styled.Container>
    );
  }
);

SkeletonFacepile.displayName = 'SkeletonFacepile';
