/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconButterflyMono = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconButterflyMono"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m5.914 7.09 1.847 3.344L5 15.366h3.291l2.743-4.932L9.187 7.09zM15.714 4l-5.324 9.623L13.823 20h3.341l-3.488-6.377L19 4z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconButterflyMono;
