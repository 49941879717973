import * as React from 'react';
import { useIntl } from 'react-intl';

import * as Styled from './dot-loading-indicator.styles';
import type { DotLoadingIndicatorProps } from './dot-loading-indicator.types';

export const DotLoadingIndicator = React.forwardRef<
  HTMLDivElement,
  DotLoadingIndicatorProps
>(({ className, ...props }, forwardedRef): JSX.Element => {
  const intl = useIntl();

  return (
    <Styled.DotLoadingIndicator
      aria-busy="true"
      aria-label={intl.formatMessage({
        id: 'XDS_SKELETON_GENERIC_A11Y',
        defaultMessage: 'Loading',
      })}
      className={className}
      data-xds="DotLoadingIndicator"
      ref={forwardedRef}
      role="status"
      {...props}
    >
      <Styled.Dot1 aria-hidden="true" />
      <Styled.Dot2 aria-hidden="true" />
      <Styled.Dot3 aria-hidden="true" />
    </Styled.DotLoadingIndicator>
  );
});

DotLoadingIndicator.displayName = 'DotLoadingIndicator';
