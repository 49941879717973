import * as React from 'react';
import { useIntl } from 'react-intl';

import { cornerRadiusXL } from '@xing-com/tokens';

import type { SkeletonContentBannerProps } from '../';
import { SkeletonRectangle } from '../';

export const SkeletonContentBanner = React.forwardRef<
  HTMLDivElement,
  SkeletonContentBannerProps
>(({ className, ...props }, forwardedRef): JSX.Element => {
  const intl = useIntl();

  return (
    <SkeletonRectangle
      aria-busy="true"
      aria-label={intl.formatMessage({
        id: 'XDS_SKELETON_GENERIC_A11Y',
        defaultMessage: 'Loading',
      })}
      className={className}
      cornerRadius={cornerRadiusXL}
      data-xds="SkeletonContentBanner"
      ref={forwardedRef}
      role="status"
      variant="outline"
      {...props}
    />
  );
});

SkeletonContentBanner.displayName = 'SkeletonContentBanner';
