import type * as React from 'react';

import * as Styled from './skeleton-profile-image.styles';
const { sizes } = Styled;

export type SkeletonProfileImageSize = keyof typeof sizes;

export type SkeletonProfileImageProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** The size on mobile screens or larger */
  size?: SkeletonProfileImageSize;
  /** The size on tablet screens or larger */
  sizeConfined?: SkeletonProfileImageSize;
  /** The size on desktop screens or larger */
  sizeWide?: SkeletonProfileImageSize;
} & React.HTMLAttributes<SVGElement>;
