import { useIntl } from 'react-intl';

import type { Maybe } from '@xing-com/crate-common-graphql-types';
import { Currency } from '@xing-com/crate-common-graphql-types';
import { FILTER_TYPENAME_TO_PROPERTY_NAME } from '@xing-com/crate-jobs-domain-search-maps';
import type {
  JobSearchQueryBodyFragment,
  JobSearchQueryBodySplitBenefitsFragment,
} from '@xing-com/crate-jobs-graphql';
import { formatSalaryRange } from '@xing-com/crate-jobs-helpers';

import { useIsAiSearch } from './use-is-ai-search';

const KEYWORDS_MAX_LENGTH = 24;

type Options = {
  keywords?: Maybe<string>;
  location?: Maybe<string>;
  radius?: Maybe<number>;
};

export const useJoinFilters = (
  filterCollection:
    | JobSearchQueryBodyFragment['filterCollection']
    | JobSearchQueryBodySplitBenefitsFragment['filterCollection'],
  { keywords, location, radius }: Options = {}
): string => {
  const { formatNumber, formatMessage } = useIntl();
  const isAiSearch = useIsAiSearch();

  if (isAiSearch && keywords) {
    return keywords;
  }

  if (!filterCollection) {
    return '';
  }

  let hasCompanyFilter = false;
  const translatedFilters = filterCollection
    .map((filter) => {
      if (!filter?.__typename) {
        return false;
      }

      if (filter.__typename === 'JobFilterCompany') {
        hasCompanyFilter = true;
        return false;
      }

      if (
        filter.__typename === 'JobFilterSalary' &&
        typeof filter.min === 'number' &&
        typeof filter.max === 'number'
      ) {
        return formatSalaryRange(
          formatNumber,
          [filter.min, filter.max],
          Currency.Eur
        );
      }

      if ('entityId' in filter) {
        const filterField = FILTER_TYPENAME_TO_PROPERTY_NAME[filter.__typename];
        // @ts-expect-error: The only way to solve this issue is to use a switch or a type guard for each filter type
        return filter[filterField]?.localizationValue ?? false;
      }
    })
    .filter(Boolean);

  const slicedKeywords =
    keywords && keywords.length > KEYWORDS_MAX_LENGTH
      ? `${keywords.substring(0, KEYWORDS_MAX_LENGTH)}...`
      : keywords;

  const filtersArray = [
    slicedKeywords,
    location,
    radius ? `${radius}km` : undefined,
    ...(hasCompanyFilter
      ? [formatMessage({ id: 'JOBS_SEARCH_COMPANY_CULTURE_FILTER_VALUE' })]
      : []),
    ...translatedFilters,
  ];

  return filtersArray.filter(Boolean).join(' + ');
};
