import { useIntl } from 'react-intl';

import AppleStoreSvgDe from './assets/app-store-badge-de.svg';
import AppleStoreSvgEn from './assets/app-store-badge-en.svg';
import GooglePlaySvgDe from './assets/google-play-badge-de.svg';
import GooglePlaySvgEn from './assets/google-play-badge-en.svg';
import QrCodeSvg from './assets/qrcode.svg';
import {
  Background,
  LeftContainer,
  RightContainer,
  StyledHero,
  StyledHeadline,
  ServiceStoreImageWrapper,
  ServiceStoreImage,
  QrCodeWrapper,
  StyledButterfly,
  StyledQRCode,
} from './native-apps-section.styles';

export const appleStoreUrl = 'https://apps.apple.com/de/app/xing/id297625850';
export const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=com.xing.android';

type Props = {
  trackClickStoreButton: (action: string) => void;
};

export const NativeAppsSection: React.FC<Props> = ({
  trackClickStoreButton,
}) => {
  const { formatMessage, locale } = useIntl();

  return (
    <Background>
      <LeftContainer>
        <StyledHero forwardedAs="h2" size="small" sizeConfined="medium">
          {formatMessage({ id: 'QR_CODE_BANNER_TITLE' })}
        </StyledHero>
        <StyledHeadline size="large" sizeConfined="xxlarge" forwardedAs="p">
          {formatMessage({ id: 'QR_CODE_BANNER_SUBTITLE' })}
        </StyledHeadline>
        <ServiceStoreImageWrapper>
          <a
            href={googlePlayUrl}
            data-qa={'google-store-badge'}
            onClick={() =>
              trackClickStoreButton('losp_download_android_app_cta_click')
            }
          >
            <ServiceStoreImage
              src={locale === 'de' ? GooglePlaySvgDe : GooglePlaySvgEn}
              alt="google-store-badge"
              loading="lazy"
            />
          </a>
          <a
            data-qa={'apple-store-badge'}
            href={appleStoreUrl}
            onClick={() =>
              trackClickStoreButton('losp_download_ios_app_cta_click')
            }
          >
            <ServiceStoreImage
              src={locale === 'de' ? AppleStoreSvgDe : AppleStoreSvgEn}
              alt="apple-store-badge"
              loading="lazy"
            />
          </a>
        </ServiceStoreImageWrapper>
      </LeftContainer>
      <RightContainer>
        <QrCodeWrapper>
          <StyledButterfly size={48} />
          <StyledQRCode
            src={QrCodeSvg}
            alt="QR Code - mobile apps"
            loading="lazy"
          />
        </QrCodeWrapper>
      </RightContainer>
    </Background>
  );
};
