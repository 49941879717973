import * as React from 'react';

import { Styled } from './content-switcher-button.styles';
import type { ContentSwitcherButtonProps } from './content-switcher-button.types';

export const ContentSwitcherButton = ({
  buttonListRef,
  children,
  disabled = false,
  href,
  icon: Icon,
  innerRef,
  miniBadge,
  onClick,
  selected = false,
  size,
  sizeConfined,
  sizeWide,
  variant,
  to,
  ...props
}: ContentSwitcherButtonProps): JSX.Element => {
  const defaultRef = React.useRef(null);
  const ref = innerRef || defaultRef;

  const onClickHandler = disabled
    ? (e: React.MouseEvent) => e.preventDefault()
    : onClick;
  const iconSize = size === 'small' ? 18 : 24;
  const commonProps = {
    $selected: selected,
    $size: size,
    $sizeConfined: sizeConfined,
    $sizeWide: sizeWide,
    $variant: variant,
    'aria-disabled': disabled,
    'aria-selected': selected,
    'data-state': selected ? 'active' : null,
    onClick: (e: React.MouseEvent) => onClickHandler && onClickHandler(e),
    role: 'tab',
  };

  React.useEffect(() => {
    if (selected) {
      buttonListRef &&
        buttonListRef.current &&
        buttonListRef.current.scrollTo &&
        buttonListRef.current.scrollTo({
          left:
            ref.current.offsetLeft -
            (ref.current.parentElement.parentElement.offsetWidth / 2 -
              ref.current.offsetWidth / 2),
          behavior: 'smooth',
        });
    }
  }, [buttonListRef, ref, selected]);

  const buttonContent = (
    <Styled.Inner>
      {miniBadge ? <Styled.MiniBadge /> : null}
      {Icon ? (
        <Styled.ButtonIcon>
          <Icon height={iconSize} width={iconSize} aria-hidden="true" />
        </Styled.ButtonIcon>
      ) : null}
      <span>
        {typeof children === 'string' && children.length > 25
          ? children.substring(0, 22) + '…'
          : children}
      </span>
    </Styled.Inner>
  );

  return (
    <div ref={ref}>
      {to || href ? (
        <Styled.RouterLink
          {...(to ? { to } : {})}
          {...(href ? { href } : {})}
          {...commonProps}
          {...props}
        >
          {buttonContent}
        </Styled.RouterLink>
      ) : (
        <Styled.Button disabled={disabled} {...commonProps} {...props}>
          {buttonContent}
        </Styled.Button>
      )}
    </div>
  );
};
