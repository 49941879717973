import { useEffect, useState } from 'react';

import { useExperiment, useLoginState } from '@xing-com/hub';

type TcfVendorType = { consent: boolean; id: number }[];

// https://usercentrics.com/docs/web/features/events/uc-ui-cmp-event/
type EventDetail = {
  source: 'none' | 'button' | 'first' | 'second' | 'embeddings' | '__ucCmp';
  type:
    | 'CMP_SHOWN'
    | 'ACCEPT_ALL'
    | 'DENY_ALL'
    | 'SAVE'
    | 'MORE_INFORMATION_LINK'
    | 'IMPRINT_LINK'
    | 'PRIVACY_POLICY_LINK';
};

const getMarketingConsent = (): boolean => {
  const tcfVendors: TcfVendorType =
    // @ts-expect-error UC_UI comes from the usercentrics consent script
    window.UC_UI?.getTCFVendors();

  const hasMarketingConsent = tcfVendors.filter(
    (vendor) => vendor.id === 251 // 251 = YieldLove GmbH
  )[0];

  return hasMarketingConsent?.consent || false;
};

export const useDisplayAds = (): { displayAdsAllowed: boolean } => {
  const isLoggedOut = useLoginState() === 'LOGGED_OUT';
  const [hasMarketingConsent, setHasMarketingConsent] = useState(false);
  const hasDisplayAdsFeature = useExperiment('ABACUS-482') === 'B';

  const isDebugMode =
    global?.window?.localStorage?.getItem('xam_display_ads_debug') === 'true';

  const displayAdsAllowed =
    (isLoggedOut && isDebugMode) ||
    (isLoggedOut && hasMarketingConsent && hasDisplayAdsFeature);

  const handleInitializedEvent = (): void => {
    setHasMarketingConsent(getMarketingConsent());
  };

  const handleCmpEvent = (event: CustomEvent<EventDetail>): void => {
    const eventType = event.detail.type;
    if (
      eventType === 'SAVE' ||
      eventType === 'ACCEPT_ALL' ||
      eventType === 'DENY_ALL'
    ) {
      setHasMarketingConsent(getMarketingConsent());
    }
  };

  useEffect(() => {
    window.addEventListener('UC_UI_INITIALIZED', handleInitializedEvent);
    window.addEventListener(
      'UC_UI_CMP_EVENT' as keyof WindowEventMap,
      handleCmpEvent as EventListener
    );

    return () => {
      window.removeEventListener('UC_UI_INITIALIZED', handleInitializedEvent);
      window.removeEventListener(
        'UC_UI_CMP_EVENT' as keyof WindowEventMap,
        handleCmpEvent as EventListener
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    displayAdsAllowed,
  };
};
