import { IconArrowUp, IconArrowDown } from '@xing-com/icons';
import { Menu } from '@xing-com/menu';
import { usePopOver } from '@xing-com/pop-over';
import { spaceS } from '@xing-com/tokens';

import { ContentSwitcherMoreItem } from '../content-switcher-more-item';

import { Styled } from './content-switcher-more.styles';
import type { ContentSwitcherMoreProps } from './content-switcher-more.types';

export const ContentSwitcherMore = ({
  buttonListRef,
  children,
  data,
  isFixed,
  selected,
  size,
  sizeConfined,
  sizeWide,
  variant,
  ...props
}: ContentSwitcherMoreProps): JSX.Element => {
  const popOver = usePopOver();
  const Icon = popOver.getState() ? IconArrowUp : IconArrowDown;
  const iconSize = size === 'small' ? 18 : 24;

  const handleClick = (): void => popOver.togglePopOver();

  return (
    <Styled.MoreContainer $hidden={data.length === 0} {...props}>
      <Styled.MoreButton
        aria-haspopup="true"
        buttonListRef={buttonListRef}
        data-qa="more-button"
        data-testid="more-button"
        icon={(props) => (
          <Icon
            aria-hidden="true"
            height={iconSize}
            width={iconSize}
            {...props}
          />
        )}
        innerRef={popOver.triggerRef}
        onClick={handleClick}
        selected={selected}
        size={size}
        sizeConfined={sizeConfined}
        sizeWide={sizeWide}
        variant={variant}
      >
        {children}
      </Styled.MoreButton>
      <Menu
        isInFlow={isFixed}
        isStatic={isFixed}
        offset={parseInt(spaceS)}
        onOutsideClick={popOver.handleHide}
        role="menu"
        show={popOver.show}
        triggerRef={popOver.triggerRef}
      >
        <Styled.MoreList>
          {data.map((item, index) => (
            <ContentSwitcherMoreItem
              data-qa={`more-menu-item-${index}`}
              data-testid={`more-menu-item-${index}`}
              key={index}
              popOver={popOver}
              {...item}
            >
              {item.copy}
            </ContentSwitcherMoreItem>
          ))}
        </Styled.MoreList>
      </Menu>
    </Styled.MoreContainer>
  );
};
