/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationEmptyStateError = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationEmptyStateError"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#DBE2E6"
        fillRule="evenodd"
        d="M2.583 58.177 31.662 6.694 60.74 58.177z"
        clipRule="evenodd"
      />
      <path
        fill="#5E737D"
        fillRule="evenodd"
        d="m33.864 10.113 1.475-3.29 30.079 54.482H6.258l1.643-3.55 52.84.422z"
        clipRule="evenodd"
      />
      <path
        fill="#5E737D"
        fillRule="evenodd"
        d="M28.825 40.918H34.5V24.832h-5.674zm6.336 6.91a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default IllustrationEmptyStateError;
