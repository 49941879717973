import type React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { CardInspire } from '@xing-com/card';
import type { CardInspireVariants, CardInspireSizes } from '@xing-com/card';
import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { useHost } from '@xing-com/crate-xinglet';
import { mediaTiny } from '@xing-com/layout-tokens';
import type { HeadlineSize } from '@xing-com/typography';

import {
  StyledIconWrapper,
  StyledHeadline,
  StyledBodyCopy,
} from './inspirational-card.styles';

export type InspirationalCard = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  link: string;
  variant: CardInspireVariants;
  trackingParam?: string;
  jobsCount?: number;
  size?: CardInspireSizes;
  minHeight?: string;
  headlineSize?: HeadlineSize;
  wrapHeadline?: boolean;
};

export const InspirationalCard: React.FC<InspirationalCard> = ({
  icon: Icon,
  title,
  description,
  link,
  variant,
  trackingParam = '',
  jobsCount = 0,
  size = 'auto',
  minHeight = '100%',
  headlineSize = 'large',
  wrapHeadline,
}) => {
  const isMobile = useMatchMedia(mediaTiny); // be careful: during SSR it will be always true
  const { locale, formatMessage } = useIntl();
  const { isPreview } = useHost();

  const finalLink =
    link === 'https://login.xing.com/'
      ? isPreview
        ? 'https://login.preview.xing.com'
        : 'https://login.xing.com/'
      : link;

  const handleAnchorClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    window.location.assign(`${finalLink}${trackingParam}`);
  };

  return (
    <CardInspire
      variant={variant}
      minHeight={minHeight ? minHeight : isMobile ? '100%' : '150px'}
      size={size}
      href={link}
      onClick={handleAnchorClick}
      linkProps={{ 'aria-label': formatMessage({ id: title }) }}
    >
      <StyledIconWrapper>
        <Icon height={32} width={32} />
      </StyledIconWrapper>
      <StyledHeadline
        size={headlineSize}
        forwardedAs="h3"
        $wrapHeadline={wrapHeadline}
      >
        <FormattedMessage id={title} />
        {jobsCount > 0 &&
          ` (${new Intl.NumberFormat(locale).format(jobsCount)})`}
      </StyledHeadline>
      <StyledBodyCopy size="medium">
        <FormattedMessage id={description} />
      </StyledBodyCopy>
    </CardInspire>
  );
};
