import styled from 'styled-components';

import { spaceM, spaceS, spaceXXL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const StyledPillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spaceS};
`;

export const StyledHeader = styled(BodyCopy)`
  margin-top: ${spaceXXL};
  margin-bottom: ${spaceM};
`;
