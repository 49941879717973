import * as React from 'react';

import { SuperEllipseResponsive } from '@xing-com/super-ellipse';

import * as Styled from './profile-image-responsive.styles';
import type { ProfileImageResponsiveProps } from './profile-image-responsive.types';

export const ProfileImageResponsive = React.forwardRef<
  HTMLDivElement,
  ProfileImageResponsiveProps
>(
  ({ className, connectionDegree, ...props }, forwardedRef): JSX.Element => (
    <Styled.MaximumSizeContainer
      className={className}
      data-xds="ProfileImageResponsive"
      ref={forwardedRef}
    >
      <Styled.Container>
        <SuperEllipseResponsive {...props} />
        {Boolean(connectionDegree) && (
          <Styled.ConnectionDegree variant="responsive">
            <svg
              aria-label={String(connectionDegree)}
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <text
                x="50%"
                y="55%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="12"
              >
                {connectionDegree}
              </text>
            </svg>
          </Styled.ConnectionDegree>
        )}
      </Styled.Container>
    </Styled.MaximumSizeContainer>
  )
);

ProfileImageResponsive.displayName = 'ProfileImageResponsive';
