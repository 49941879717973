import type React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import {
  IconPublic,
  IconClock,
  IconSync,
  IconPartTime,
  IconMiniJob,
  IconPlus,
} from '@xing-com/icons';
import { IllustrationStickerDiscover } from '@xing-com/illustrations';
import { mediaTiny } from '@xing-com/layout-tokens';
import { Tag } from '@xing-com/tag';
import { BodyCopy } from '@xing-com/typography';

import { InspirationalCard } from '../inspirational-card';
import { PreferencesSection } from '../preferences-section';

import { useJobsCount } from './search-inspiration-hooks';
import {
  StyledInspirationalCards,
  StyledColumnLeft,
  StyledColumnRight,
  StyledImageCard,
  StyledIllustrationCard,
  StyledPreferencesCard,
  StyledInnerColumn,
  StyledTags,
  StyledButton,
  StyledHeadline,
  StyledModalPage,
} from './search-inspiration-section.styles';

export const SearchInspirationSection: React.FC = () => {
  const isMobile = useMatchMedia(mediaTiny); // be careful: during SSR it will be always true
  const [showPreferences, setShowPreferences] = useState(false);
  const togglePreferences = (): void => {
    setShowPreferences(!showPreferences);
  };
  const { jobsCount } = useJobsCount();

  return (
    <>
      <StyledInspirationalCards>
        <StyledColumnLeft>
          <StyledPreferencesCard size="auto" variant={2} minHeight="100%">
            <div />
            <div>
              <StyledHeadline size="xxlarge" sizeConfined="xxxlarge">
                <FormattedMessage id="LOSP_SEARCH_INSPIRATION_PREFERENCES_TITLE" />
              </StyledHeadline>
              <BodyCopy size="medium">
                <FormattedMessage id="LOSP_SEARCH_INSPIRATION_PREFERENCES_DESCRIPTION" />
                <b>
                  {' '}
                  <FormattedMessage id="LOSP_SEARCH_INSPIRATION_PREFERENCES_DESCRIPTION_2" />
                </b>
              </BodyCopy>
              <StyledTags>
                <Tag
                  leadingIcon={IconPlus}
                  onClick={togglePreferences}
                  hideBorder
                >
                  <FormattedMessage id="LOSP_SEARCH_INSPIRATION_CAREER_LEVEL" />
                </Tag>
                <Tag
                  leadingIcon={IconPlus}
                  onClick={togglePreferences}
                  hideBorder
                >
                  <FormattedMessage id="LOSP_SEARCH_INSPIRATION_WORKPLACE" />
                </Tag>
                <Tag
                  leadingIcon={IconPlus}
                  onClick={togglePreferences}
                  hideBorder
                >
                  <FormattedMessage id="LOSP_SEARCH_INSPIRATION_SALARY" />
                </Tag>
              </StyledTags>
            </div>
            <div>
              <StyledButton
                size="medium"
                variant="onColorStrong"
                onClick={togglePreferences}
                data-qa="losp-set-preferences"
              >
                <FormattedMessage id="LOSP_SEARCH_INSPIRATION_SET_PREFERENCES" />
              </StyledButton>
            </div>
          </StyledPreferencesCard>
          <StyledImageCard size="auto" variant={1} maxHeight="434px" />
        </StyledColumnLeft>
        <StyledColumnRight>
          <StyledInnerColumn>
            <InspirationalCard
              icon={IconPublic}
              title="LOSP_SEARCH_INSPIRATION_TITLE_CARD_1"
              description="LOSP_SEARCH_INSPIRATION_DESCRIPTION_CARD_1"
              link="/jobs/remote"
              variant={1}
              trackingParam="?sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_remote_work"
              jobsCount={jobsCount?.remote?.total}
            />
            <InspirationalCard
              icon={IconMiniJob}
              title="TITLE_CARD_6"
              description="LOSP_SEARCH_INSPIRATION_DESCRIPTION_CARD_6"
              link="/jobs/t-minijob"
              variant={6}
              trackingParam="?sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_minijobs"
              jobsCount={jobsCount?.minijob?.total}
            />
            <InspirationalCard
              icon={IconSync}
              title="LOSP_SEARCH_INSPIRATION_TITLE_CARD_3"
              description="DESCRIPTION_CARD_3"
              link="/jobs/t-quereinsteiger?keywords=Quereinsteiger"
              variant={3}
              trackingParam="&sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_career_change"
              jobsCount={jobsCount?.careerChanger?.total}
            />
          </StyledInnerColumn>
          <StyledInnerColumn>
            <InspirationalCard
              icon={IconClock}
              title="TITLE_CARD_2"
              description="DESCRIPTION_CARD_2"
              link="/jobs/search?benefit=1.795d28"
              variant={6}
              trackingParam="&sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_flexible_work"
              jobsCount={jobsCount?.flexibleWork?.total}
            />
            {!isMobile && (
              <StyledIllustrationCard size="auto" variant={3} minHeight="150px">
                <IllustrationStickerDiscover size="large" />
              </StyledIllustrationCard>
            )}
            <InspirationalCard
              icon={IconPartTime}
              title="TITLE_CARD_5"
              description="LOSP_SEARCH_INSPIRATION_DESCRIPTION_CARD_5"
              link="/jobs/k-teilzeit"
              variant={1}
              trackingParam="&sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_part_time"
              jobsCount={jobsCount?.partTime?.total}
            />
          </StyledInnerColumn>
        </StyledColumnRight>
      </StyledInspirationalCards>

      <StyledModalPage
        isShown={showPreferences}
        closeButton={{
          onClick: togglePreferences,
        }}
        isAnimated
        noGrid
      >
        <PreferencesSection />
      </StyledModalPage>
    </>
  );
};
