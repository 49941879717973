const CSRParamsList = [
  'sc_o',
  'sc_o_PropActionOrigin',
  'signup_channel',
  'ijt',
  'dest_url',
  'sc_cdvid',
  'register_redirect_to',
  'sc_p',
];

export const removeCSRParams = (url: string): string => {
  let parsedUrl;
  try {
    parsedUrl = new URL(url, 'https://fallback-base-domain.com');
  } catch (error) {
    console.error('Failed to parse URL:', error);
    return url;
  }
  // Remove CSR parameters
  for (const param of CSRParamsList) {
    parsedUrl.searchParams.delete(param);
  }

  let result = parsedUrl.toString();

  // For relative URLs, remove the base URL
  if (result.startsWith('https://fallback-base-domain.com')) {
    const prefix = url.startsWith('/')
      ? 'https://fallback-base-domain.com'
      : 'https://fallback-base-domain.com/';
    result = result.replace(prefix, '');
  }

  // Maintain trailing slash consistency with original URL
  if (!url.endsWith('/') && result.endsWith('/') && result.length > 1) {
    result = result.slice(0, -1);
  }

  return result.replaceAll('+', '%20');
};
