import type { JobPostingSkillFragment } from '@xing-com/crate-jobs-graphql';
import type {
  JobDetails,
  UserJobPreferencesData,
} from '@xing-com/crate-jobs-helpers';

import { JobsXingletWrapper } from '../../../jobs-xinglet';

import { JobSummarySkeleton } from './job-summary-skeleton';

type JobSummaryProps = {
  job: JobDetails;
  userJobPreferences?: UserJobPreferencesData;
  matchedSkills?: JobPostingSkillFragment[];
  notMatchedSkills?: JobPostingSkillFragment[];
};

export const JobSummary = JobsXingletWrapper<JobSummaryProps>(
  '@xing-com/crate-jobs-domain-job-details-job-summary',
  <JobSummarySkeleton />
);
