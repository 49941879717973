import { useQuery } from '@apollo/client';

import { SEARCH_BOX_MAX_RECENT_SEARCHES } from '@xing-com/crate-jobs-constants';
import { GetRecentSearchesDocument } from '@xing-com/crate-jobs-graphql';

type UseRecentSearchesArgs = { skip?: boolean };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useRecentSearches = ({ skip }: UseRecentSearchesArgs) => {
  const { data, loading, refetch } = useQuery(GetRecentSearchesDocument, {
    variables: {
      consumer: 'loggedin.web.jobs.search',
      limit: SEARCH_BOX_MAX_RECENT_SEARCHES,
    },
    skip,
  });

  return {
    isLoading: loading,
    recentSearches: data?.viewer?.jobRecentSearchesJS?.searches,
    refetch,
  };
};
