import * as React from 'react';

import { TagContent } from './tag-content';
import * as Styled from './tag.styles';
import type { DefaultTagProps } from './tag.types';

export const DefaultTag = React.forwardRef<HTMLDivElement, DefaultTagProps>(
  (
    {
      children,
      className,
      fontWeight,
      hideBorder,
      leadingIcon: LeadingIcon,
      maxWidth,
      profile,
      trailingIcon: TrailingIcon,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const hasChildren = Boolean(children);
    const hasLeadingContent =
      !hasChildren || Boolean(LeadingIcon) || Boolean(profile);
    const hasTrailingContent = !hasChildren || Boolean(TrailingIcon);

    return (
      <Styled.DefaultTag
        $fontWeight={fontWeight}
        $hasLeadingContent={hasLeadingContent}
        $hasTrailingContent={hasTrailingContent}
        $hideBorder={hideBorder}
        className={className}
        data-xds="Tag"
        ref={forwardedRef}
        role="status"
        tabIndex={-1}
        {...props}
      >
        <TagContent
          badgeProps={undefined}
          disabled={false}
          leadingIcon={
            LeadingIcon && (
              <LeadingIcon aria-hidden="true" width={18} height={18} />
            )
          }
          maxWidth={maxWidth}
          profile={profile}
          selected={false}
          trailingIcon={
            TrailingIcon && (
              <TrailingIcon aria-hidden="true" width={18} height={18} />
            )
          }
        >
          {children}
        </TagContent>
      </Styled.DefaultTag>
    );
  }
);

DefaultTag.displayName = 'DefaultTag';
