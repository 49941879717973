import * as React from 'react';
import { useIntl } from 'react-intl';

import { scale110, scale150 } from '@xing-com/tokens';

import { SkeletonPillShape } from '../';
import type { SkeletonToggleProps } from '../';

export const SkeletonToggle = React.forwardRef<
  HTMLDivElement,
  SkeletonToggleProps
>(({ className, ...props }, forwardedRef): JSX.Element => {
  const intl = useIntl();

  return (
    <SkeletonPillShape
      aria-busy="true"
      aria-label={intl.formatMessage({
        id: 'XDS_SKELETON_GENERIC_A11Y',
        defaultMessage: 'Loading',
      })}
      className={className}
      data-xds="SkeletonToggle"
      height={scale110}
      ref={forwardedRef}
      role="status"
      width={scale150}
      {...props}
    />
  );
});

SkeletonToggle.displayName = 'SkeletonToggle';
