import { useState, useEffect } from 'react';

type AdRefreshType = {
  isSmallScreen: boolean;
  slots: {
    mobile: string;
    desktop: string;
  };
};

export const useAdRefresh = ({
  isSmallScreen,
  slots,
}: AdRefreshType): {
  refreshMobile: boolean;
  refreshDesktop: boolean;
} => {
  const currentAd = isSmallScreen ? slots.mobile : slots.desktop;

  const [initialRenderedAd, setInitialRenderedAd] = useState(currentAd);
  const [refreshMobile, setRefreshMobile] = useState(false);
  const [refreshDesktop, setRefreshDesktop] = useState(false);

  useEffect(() => {
    setInitialRenderedAd(currentAd);
  }, [currentAd]);

  useEffect(() => {
    if (currentAd !== initialRenderedAd) {
      setRefreshMobile(currentAd === slots.mobile);
      setRefreshDesktop(currentAd === slots.desktop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAd, initialRenderedAd]);

  return {
    refreshMobile,
    refreshDesktop,
  };
};
