/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconArrowLeft"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.293 6.293 3.586 12l5.707 5.707 1.414-1.414L7.415 13H20v-2H7.415l3.292-3.293z"
    />
  </svg>
);
export default IconArrowLeft;
