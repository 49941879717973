import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, space4XL, space3XL } from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

export const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 670px;
`;

export const Title = styled(Headline)`
  margin-top: ${spaceL};
  margin-bottom: ${spaceL};
`;

export const Subtitle = styled(BodyCopy)`
  margin-bottom: ${space3XL};

  @media ${mediaConfined} {
    margin-bottom: ${space4XL};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: ${space3XL};

  @media ${mediaConfined} {
    margin-top: ${space4XL};
  }
`;
