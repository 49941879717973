import classnames from 'classnames';
import type * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS does not know about CSS files
import styles from './grid.css';

type GridProps = {
  /** adds custom styles to Grid */
  className?: string;
};
/**
 * **Grid** defines a section that contains rows and columns layed out
 * in a 12-column grid.
 *
 * Allows adding arbitrary props that are allowed on `div` elements
 * (e.g. `id`, `style` or `title`)
 *
 * @deprecated Use `@xing-com/grid` instead
 */
export const Grid: React.FC<
  GridProps & React.HTMLAttributes<HTMLDivElement>
> = ({ className = '', ...other }) => {
  const classes = classnames(styles.grid, className);

  return <div className={classes} {...other} />;
};

Grid.displayName = 'Grid';
