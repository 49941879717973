/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReadLoggedOutPreferencesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ReadLoggedOutPreferencesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    salaryExpectationsPreference?: {
      __typename?: 'SalaryExpectationsPreference';
      salaryExpectations?: number | null;
    } | null;
    workplacePreference?: {
      __typename?: 'WorkplacePreference';
      office: boolean;
      partlyHome: boolean;
      mostlyHome: boolean;
      homeOffice: boolean;
    } | null;
    careerLevelPreference?: {
      __typename?: 'CareerLevelPreference';
      minimum?: number | null;
      maximum?: number | null;
    } | null;
  } | null;
};

export const ReadLoggedOutPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'readLoggedOutPreferences' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salaryExpectationsPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salaryExpectations' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workplacePreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'partlyHome' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mostlyHome' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homeOffice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'careerLevelPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minimum' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maximum' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReadLoggedOutPreferencesQuery,
  ReadLoggedOutPreferencesQueryVariables
>;
