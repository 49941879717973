import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { IconReaction } from '@xing-com/icons';
import { SuperEllipse } from '@xing-com/super-ellipse';
import {
  spaceXS,
  spaceS,
  spaceM,
  spaceL,
  spaceXXL,
  scale010,
  scale050,
  scale160,
  scale400,
  xdlColorCardInspire03,
  xdlColorCardInspireText03,
  xdlColorTextTertiaryOnLight,
  xdlColorBackgroundSecondary,
  xdlColorText,
  cornerRadiusXL,
  xdlColorMarkerHighlightText,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const StyledAnchor = styled.a`
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: ${xdlColorText};
  }
`;

export const StyledCard = styled(Card)`
  box-shadow: rgba(189, 189, 189, 0.25) 0px 2px 8px 1px;
  border-radius: ${cornerRadiusXL};
  border: 0;
  justify-content: flex-start;
  height: 455px;
  padding: ${spaceXXL} ${spaceL};
  width: calc(${scale050} + ${scale400});
  display: flex;

  &&& > div {
    height: 100%;
    display: flex;
  }
`;

export const StyledAnchorProfileImage = styled.div`
  display: contents;
`;

export const ProfileImage = styled(SuperEllipse)`
  align-self: center;
  margin-bottom: ${spaceXXL};
`;

export const LowerSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FlagRatingContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${spaceM};
  gap: ${spaceXS};
`;

export const Flag = styled.span`
  align-items: center;
  background: ${xdlColorCardInspire03};
  border-radius: calc(${scale010} + ${scale160});
  color: ${xdlColorCardInspireText03};
  display: flex;
  margin-bottom: ${spaceM};
  padding: 0 ${spaceS};
  width: fit-content;
`;

export const CompanyRatingWrapper = styled.span`
  align-items: center;
  background: ${xdlColorBackgroundSecondary};
  border-radius: calc(${scale010} + ${scale160});
  display: flex;
  gap: ${spaceXS};
  height: 100%;
  padding: 0 ${spaceS};
  width: fit-content;
`;

export const RecruiterInfoWrapper = styled.div`
  display: flex;
  gap: ${spaceM};
  margin-bottom: ${spaceL};
  min-height: 48px;
  // align-items: center;
`;

export const RecruiterInfoIcon = styled(SuperEllipse)`
  svg,
  img {
    width: 48px;
    height: 48px;
  }
`;

export const StyledIcon = styled(IconReaction)`
  color: ${xdlColorMarkerHighlightText};
`;

export const Insight = styled(BodyCopy)`
  color: ${xdlColorTextTertiaryOnLight};
  flex: 1;
`;

export const InsightsWrapper = styled.div`
  display: flex;
  gap: ${spaceM};
`;

export const CardCtaContainer = styled.div`
  &&& button {
    display: block;
  }

  display: flex;
  align-items: flex-end;
  justify-content: center;

  justify-self: flex-end;
`;
