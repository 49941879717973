import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useDisplayAds } from './use-display-ads';

export const DisplayAdsBiddingScript: React.FC = () => {
  const { displayAdsAllowed } = useDisplayAds();

  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      googletag.pubads().disableInitialLoad();
      googletag.enableServices();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!displayAdsAllowed) {
    return null;
  }

  return (
    <Helmet>
      <script src="https://cdn-a.yieldlove.com/v2/yieldlove.js?xing.com"></script>
      <script
        async
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      ></script>
    </Helmet>
  );
};
