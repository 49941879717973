import classnames from 'classnames';
import type React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS does not know about CSS files
import styles from './grid.css';

type AllowedColumnSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type AllowedColumnOffset = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

type ColumnProps = {
  /** adds custom styles to Column */
  className?: string;
  /** the number of columns */
  size: AllowedColumnSize;
  /** the number of columns in **tiny** resolutions */
  sizeTiny?: AllowedColumnSize;
  /** @private */
  sizeTablet?: AllowedColumnSize;
  /** the number of columns in **tablet and higher** resolutions */
  sizeConfined?: AllowedColumnSize;
  /** @private */
  sizeDesktop?: AllowedColumnSize;
  /** the number of columns in **desktop** resolutions */
  sizeWide?: AllowedColumnSize;
  /** the number of columns in **desktop** resolutions */
  sizeXWide?: AllowedColumnSize;
  /** the offset in columns */
  offset?: AllowedColumnOffset;
  /** the offset in columns in **tiny** resolutions */
  offsetTiny?: AllowedColumnOffset;
  /** @private */
  offsetTablet?: AllowedColumnOffset;
  /** the offset in columns in **tablet and higher** resolutions */
  offsetConfined?: AllowedColumnOffset;
  /** @private */
  offsetDesktop?: AllowedColumnOffset;
  /** the offset in columns in **desktop\** resolutions */
  offsetWide?: AllowedColumnOffset;
  /** the offset in columns in **desktop\** resolutions */
  offsetXWide?: AllowedColumnOffset;
};

/**
 * **Column** is a sizeble vertical slice inside a grid row. You can define the number
 * of grid columns for different screen sizes.
 *
 * Allows adding arbitrary props that are allowed on `div` elements
 * (e.g. `id`, `style` or `title`)
 *
 * @deprecated Use `@xing-com/grid` instead
 */
export const Column: React.FC<
  ColumnProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  size,
  sizeTiny,
  sizeTablet,
  sizeConfined,
  sizeDesktop,
  sizeWide,
  sizeXWide,
  offset,
  offsetTiny,
  offsetTablet,
  offsetConfined,
  offsetDesktop,
  offsetWide,
  offsetXWide,
  className,
  ...other
}) => {
  sizeConfined =
    typeof sizeConfined === 'undefined' ? sizeTablet : sizeConfined;
  sizeWide = typeof sizeWide === 'undefined' ? sizeDesktop : sizeWide;

  offsetConfined =
    typeof offsetConfined === 'undefined' ? offsetTablet : offsetConfined;
  offsetWide = typeof offsetWide === 'undefined' ? offsetDesktop : offsetWide;

  const classNames = classnames(
    styles.col,
    {
      [styles[`default${size}`]]: size || size === 0,
      [styles[`tiny${sizeTiny}`]]: sizeTiny || sizeTiny === 0,
      [styles[`confined${sizeConfined}`]]: sizeConfined || sizeConfined === 0,
      [styles[`wide${sizeWide}`]]: sizeWide || sizeWide === 0,
      [styles[`xwide${sizeXWide}`]]: sizeXWide || sizeXWide === 0,

      [styles[`defaultOffset${offset}`]]: offset || offset === 0,
      [styles[`tinyOffset${offsetTiny}`]]: offsetTiny || offsetTiny === 0,
      [styles[`confinedOffset${offsetConfined}`]]:
        offsetConfined || offsetConfined === 0,
      [styles[`wideOffset${offsetWide}`]]: offsetWide || offsetWide === 0,
      [styles[`xwideOffset${offsetXWide}`]]: offsetXWide || offsetXWide === 0,
    },
    className
  );

  return <div className={classNames} {...other} />;
};

Column.displayName = 'Column';
