import styled, { css } from 'styled-components';

import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';
import {
  mediaConfined,
  mediaWide,
  zIndexBackgroundLayer1,
  zIndexContentLayer2,
} from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import {
  motionEasingEnter,
  motionTimeS,
  scale010,
  scale130,
  scale150,
  scale160,
  scale170,
  spaceS,
  xdlColorButtonSecondaryHover,
  xdlColorControlBorderSecondary,
  xdlColorControlFillDark,
  xdlColorControlFillLight,
  xdlColorControlFillQuaternary,
  xdlColorControlFillTertiary,
  xdlColorDisabledOnColor,
  xdlColorDisabledSoft,
  xdlColorDisabledStrong,
  xdlColorHoverOnColor,
  xdlColorHoverOnColorStrong,
  xdlColorHoverTertiary,
  xdlColorInfoSoft,
  xdlColorPremium,
  xdlColorPremiumSecondary,
  xdlColorPrimaryButtonHover,
  xdlColorProBusiness,
  xdlColorProBusinessSecondary,
  xdlColorProJobsFill,
  xdlColorProJobsSecondary,
  xdlColorSelected,
  xdlColorText,
  xdlColorTextInvert,
  xdlColorTextOnDark,
  xdlColorTextOnLight,
  xdlColorXING,
} from '@xing-com/tokens';

import type { ButtonSizes, ButtonVariants } from './button.types';

type ButtonTransientProps = {
  $ellipsis?: boolean;
  $iconOnly: boolean;
  $size?: ButtonSizes;
  $sizeConfined?: ButtonSizes;
  $sizeWide?: ButtonSizes;
  $variant?: ButtonVariants;
};

type IconContainerTransientProps = {
  $iconSize?: 18 | 24;
};

type LoadingTransientProps = {
  $size?: ButtonSizes;
  $sizeConfined?: ButtonSizes;
  $sizeWide?: ButtonSizes;
};

type TextTransientProps = {
  $ellipsis?: boolean;
  $loading?: boolean;
};

type InnerContentTransientProps = {
  $ellipsis?: boolean;
};

// *** Base ***
const baseButton = css`
  align-items: center;
  background-color: transparent;
  border-radius: 50px;
  border: none;
  color: ${xdlColorText};
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  vertical-align: top;
  z-index: ${zIndexContentLayer2};

  &::after {
    border-radius: 50px;
    box-sizing: border-box;
    content: '';
    cursor: pointer;
    height: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    transition-duration: ${motionTimeS};
    transition-property: transform, background-color;
    transition-timing-function: ${motionEasingEnter};
    width: 100%;
    z-index: ${zIndexBackgroundLayer1};
  }

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
  }

  &:disabled::after {
    cursor: default;
    user-select: none;
  }
`;

// *** Sizes ***
export const sizes = {
  small: css`
    font-size: 14px;
    height: ${scale130};
    min-width: ${scale130};
    padding-inline-end: calc(${scale130} / 2);
    padding-inline-start: calc(${scale130} / 2);
  `,
  medium: css`
    font-size: 16px;
    height: ${scale150};
    min-width: ${scale150};
    padding-inline-end: calc(${scale150} / 2);
    padding-inline-start: calc(${scale150} / 2);
  `,
  large: css`
    font-size: 18px;
    height: ${scale160};
    min-width: ${scale160};
    padding-inline-end: calc(${scale160} / 2);
    padding-inline-start: calc(${scale160} / 2);
  `,
  fabSize: css`
    font-size: 18px;
    height: ${scale170};
    min-width: ${scale170};
    padding-inline-end: calc(${scale170} / 2);
    padding-inline-start: calc(${scale170} / 2);
  `,
};

// *** variants ***
export const variants = {
  primary: css`
    color: ${xdlColorTextOnLight};

    &::after {
      background-color: ${xdlColorXING};
    }

    &:hover {
      color: ${xdlColorTextOnLight};

      &::after {
        background-color: ${xdlColorPrimaryButtonHover};
        color: ${xdlColorTextOnLight};

        @media (hover: none) {
          background-color: ${xdlColorXING};
        }
      }
    }

    &:active {
      color: ${xdlColorTextInvert};

      &::after {
        background-color: ${xdlColorSelected};
        color: ${xdlColorTextInvert};
        transform: scale(0.95);
      }
    }

    &:disabled {
      color: ${xdlColorDisabledStrong};

      &::after {
        background-color: ${xdlColorDisabledSoft};
        transform: none;
      }
    }

    &[data-loading='true'] {
      & [data-xds='DotLoadingIndicator'] > div {
        background-color: ${xdlColorTextOnLight};
      }

      &::after {
        background-color: ${xdlColorXING};
      }
    }
  `,
  secondary: css`
    color: ${xdlColorText};

    &::after {
      background-color: transparent;
      border-color: ${xdlColorControlBorderSecondary};
      border-style: solid;
      border-width: ${scale010};
    }

    &:hover {
      color: ${xdlColorText};

      @media (hover: none) {
        color: ${xdlColorText};
      }

      &::after {
        background: ${xdlColorButtonSecondaryHover};

        @media (hover: none) {
          background-color: ${xdlColorButtonSecondaryHover};
          color: ${xdlColorText};
        }
      }
    }

    &:active {
      color: ${xdlColorTextInvert};

      &::after {
        background-color: ${xdlColorSelected};
        border-color: ${xdlColorSelected};
        transform: scale(0.95);
      }
    }

    &:disabled {
      color: ${xdlColorDisabledStrong};

      &::after {
        background-color: transparent;
        border-color: ${xdlColorDisabledStrong};
        color: ${xdlColorDisabledStrong};
        transform: none;
      }
    }

    &[data-loading='true'] {
      &::after {
        background-color: transparent;
        border-color: ${xdlColorControlBorderSecondary};
      }
    }
  `,
  tertiary: css`
    color: ${xdlColorText};

    &::after {
      background-color: ${xdlColorControlFillTertiary};
    }

    &:hover::after {
      background-color: ${xdlColorHoverTertiary};

      @media (hover: none) {
        background-color: ${xdlColorControlFillTertiary};
      }
    }

    &:active {
      color: ${xdlColorTextInvert};

      &::after {
        background-color: ${xdlColorSelected};
        transform: scale(0.95);
      }
    }

    &:disabled {
      color: ${xdlColorDisabledStrong};

      &::after {
        background-color: ${xdlColorDisabledSoft};
        transform: none;

        &:hover::after {
          background-color: transparent;
        }
      }
    }

    &[data-loading='true'] {
      &::after {
        background-color: ${xdlColorControlFillTertiary};
      }
    }
  `,
  overlay: css`
    && {
      color: ${xdlColorTextOnDark};
    }

    &::after {
      background-color: ${xdlColorControlFillQuaternary};
      opacity: 0.6;
    }

    &:hover::after {
      background-color: ${xdlColorControlFillQuaternary};
      opacity: 0.8;

      @media (hover: none) {
        opacity: 0.6;
      }
    }

    &:active {
      color: ${xdlColorTextInvert};

      &::after {
        background-color: ${xdlColorSelected};
      }
    }

    &:disabled {
      color: ${xdlColorDisabledStrong};

      /** Button Text */
      & > div > span {
        opacity: 0.4;
      }

      /** The loading dots */
      & [data-xds='DotLoadingIndicator'] > div {
        background-color: ${xdlColorTextOnDark};
      }

      &::after {
        background-color: ${xdlColorControlFillQuaternary};
        opacity: 0.6;
        transform: none;
      }
    }
  `,
  onColor: css`
    color: ${xdlColorTextOnLight};

    &::after {
      background-color: ${xdlColorControlFillLight};
      color: ${xdlColorTextOnLight};
    }

    &:hover {
      color: ${xdlColorTextOnLight};

      &::after {
        background-color: ${xdlColorHoverOnColor};

        @media (hover: none) {
          background-color: ${xdlColorControlFillLight};
        }
      }
    }

    &:visited {
      color: ${xdlColorTextOnLight};
    }

    &:active {
      color: ${xdlColorTextOnDark};

      &::after {
        background-color: ${xdlColorControlFillDark};
        transform: scale(0.95);
      }
    }

    &:disabled {
      color: ${xdlColorDisabledOnColor};

      &::after {
        background-color: ${xdlColorControlFillLight};
        transform: none;
      }
    }

    &[data-loading='true'] {
      color: ${xdlColorTextOnLight};

      /** The loading dots */
      & [data-xds='DotLoadingIndicator'] > div {
        background-color: ${xdlColorTextOnLight};
      }

      &::after {
        background-color: ${xdlColorControlFillLight};
      }
    }
  `,
  onColorStrong: css`
    color: ${xdlColorTextOnDark};

    &::after {
      background-color: ${xdlColorControlFillDark};
      color: ${xdlColorTextOnDark};
    }

    &:hover {
      color: ${xdlColorTextOnDark};

      &::after {
        background-color: ${xdlColorHoverOnColorStrong};

        @media (hover: none) {
          background-color: ${xdlColorControlFillDark};
        }
      }
    }

    &:visited {
      color: ${xdlColorTextOnDark};
    }

    &:active {
      color: ${xdlColorTextOnLight};

      &::after {
        background-color: ${xdlColorControlFillLight};
        transform: scale(0.95);
      }
    }

    &:disabled {
      color: ${xdlColorDisabledOnColor};

      &::after {
        background-color: ${xdlColorControlFillLight};
        transform: none;
      }
    }

    &[data-loading='true'] {
      color: ${xdlColorTextOnDark};

      /** The loading dots */
      & [data-xds='DotLoadingIndicator'] > div {
        background-color: ${xdlColorTextOnDark};
      }

      &::after {
        background-color: ${xdlColorControlFillDark};
      }
    }
  `,
  premium: css`
    color: ${xdlColorTextOnDark};

    &::after {
      background-color: ${xdlColorPremium};
      color: ${xdlColorTextOnDark};
    }

    &:hover {
      color: ${xdlColorTextOnDark};

      &::after {
        background-color: ${xdlColorPremiumSecondary};

        @media (hover: none) {
          background-color: ${xdlColorPremium};
        }
      }
    }

    &:visited {
      color: ${xdlColorTextOnDark};
    }

    &:active {
      color: ${xdlColorTextOnDark};

      &::after {
        background-color: ${xdlColorSelected};
        transform: scale(0.95);
      }
    }

    &:disabled {
      color: ${xdlColorDisabledStrong};

      /** The loading dots */
      & [data-xds='DotLoadingIndicator'] > div {
        background-color: ${xdlColorTextOnDark};
      }

      &::after {
        background-color: ${xdlColorDisabledSoft};
        transform: none;
      }
    }

    &[data-loading='true'] {
      &::after {
        background-color: ${xdlColorPremium};
      }
    }
  `,
  proJobs: css`
    color: ${xdlColorTextOnLight};

    &::after {
      background-color: ${xdlColorProJobsFill};
    }

    &:hover {
      color: ${xdlColorTextOnLight};

      &::after {
        background-color: ${xdlColorProJobsSecondary};

        @media (hover: none) {
          background-color: ${xdlColorProJobsFill};
        }
      }
    }

    &:visited {
      color: ${xdlColorTextOnLight};
    }

    &:active {
      color: ${xdlColorTextInvert};

      &::after {
        background-color: ${xdlColorSelected};
        transform: scale(0.95);
      }
    }

    &:disabled {
      color: ${xdlColorDisabledStrong};

      /** The loading dots */
      & [data-xds='DotLoadingIndicator'] > div {
        background-color: ${xdlColorTextOnDark};
      }

      &::after {
        background-color: ${xdlColorDisabledSoft};
        transform: none;
      }
    }

    &[data-loading='true'] {
      &::after {
        background-color: ${xdlColorProJobsFill};
      }
    }
  `,
  proBusiness: css`
    color: ${xdlColorTextOnDark};

    &::after {
      background-color: ${xdlColorProBusiness};
    }

    &:hover {
      color: ${xdlColorTextOnDark};

      &::after {
        background-color: ${xdlColorProBusinessSecondary};

        @media (hover: none) {
          background-color: ${xdlColorProBusiness};
        }
      }
    }

    &:active {
      color: ${xdlColorTextOnDark};

      &::after {
        background-color: ${xdlColorSelected};
        transform: scale(0.95);
      }
    }

    &:visited {
      color: ${xdlColorTextOnDark};
    }

    &:disabled {
      color: ${xdlColorDisabledStrong};

      /** The loading dots */
      & [data-xds='DotLoadingIndicator'] > div {
        background-color: ${xdlColorTextOnDark};
      }

      &::after {
        background-color: ${xdlColorDisabledSoft};
        transform: none;
      }
    }

    &[data-loading='true'] {
      &::after {
        background-color: ${xdlColorProBusiness};
      }
    }
  `,
  // Experimental Style
  edit: css`
    color: ${xdlColorText};

    &::after {
      background-color: ${xdlColorInfoSoft};
    }

    &:hover {
      color: ${xdlColorText};

      &::after {
        background-color: ${xdlColorHoverTertiary};

        @media (hover: none) {
          background-color: ${xdlColorHoverTertiary};
        }
      }
    }

    &:active {
      color: ${xdlColorTextOnDark};

      &::after {
        background-color: ${xdlColorSelected};
        transform: scale(0.95);
      }
    }

    &:visited {
      color: ${xdlColorText};
    }

    &:disabled {
      color: ${xdlColorDisabledStrong};

      /** The loading dots */
      & [data-xds='DotLoadingIndicator'] > div {
        background-color: ${xdlColorText};
      }

      &::after {
        background-color: ${xdlColorDisabledSoft};
        transform: none;
      }
    }

    &[data-loading='true'] {
      &::after {
        background-color: ${xdlColorInfoSoft};
      }
    }
  `,
};

// *** Icon ***
export const IconContainer = styled.span<IconContainerTransientProps>`
  display: flex;
  margin-inline-end: ${spaceS};

  ${({ $iconSize }) =>
    $iconSize &&
    css`
      height: ${$iconSize}px;
      width: ${$iconSize}px;
    `};
`;

const iconOnly = css`
  padding: 0;

  & ${IconContainer} {
    margin: 0;
  }
`;

export const Button = styled.button<ButtonTransientProps>`
  ${baseButton};
  ${({ $variant }) => $variant && variants[$variant]};
  ${({ $size }) => $size && sizes[$size]};
  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
    `};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};

  ${({ $iconOnly }) => $iconOnly && iconOnly};
`;

export const RouterButton = styled(Link)<ButtonTransientProps>`
  ${baseButton};
  ${({ $variant }) => $variant && variants[$variant]};
  ${({ $size }) => $size && sizes[$size]};
  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
    `};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};

  ${({ $iconOnly }) => $iconOnly && iconOnly};
`;

export const InnerContent = styled.div<InnerContentTransientProps>`
  align-items: center;
  display: flex;
  justify-content: center;

  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
    `};
`;

const smallLoading = css`
  transform: translate(-50%, -50%) scale(0.6);
`;

const mediumLoading = css`
  transform: translate(-50%, -50%) scale(0.7);
`;

export const Loading = styled(DotLoadingIndicator)<LoadingTransientProps>`
  inset-block-start: 50%;
  inset-inline-start: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  ${({ $size }) => $size === 'small' && smallLoading};
  ${({ $size }) => $size === 'medium' && mediumLoading};

  ${({ $sizeConfined }) =>
    $sizeConfined === 'small' &&
    css`
      @media ${mediaConfined} {
        ${smallLoading}
      }
    `};

  ${({ $sizeConfined }) =>
    $sizeConfined === 'medium' &&
    css`
      @media ${mediaConfined} {
        ${mediumLoading}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide === 'small' &&
    css`
      @media ${mediaWide} {
        ${smallLoading}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide === 'medium' &&
    css`
      @media ${mediaWide} {
        ${mediumLoading}
      }
    `};
`;

export const Text = styled.span<TextTransientProps>`
  display: inline-flex;

  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  ${({ $loading }) =>
    $loading &&
    css`
      visibility: hidden;
    `};
`;
