import styled, { css } from 'styled-components';

import { MiniBadge as MiniMadgeComponent } from '@xing-com/badge';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import {
  scale070,
  scale080,
  scale130,
  scale150,
  spaceS,
  spaceL,
  xdlColorControlFillTertiary,
  xdlColorHover,
  xdlColorHoverTertiary,
  xdlColorSelected,
  xdlColorText,
  xdlColorTextInvert,
} from '@xing-com/tokens';

import type {
  ContentSwitcherSize,
  ContentSwitcherVariant,
} from '../content-switcher/content-switcher.types';

type ButtonTransientProps = {
  $selected: boolean;
  $size?: ContentSwitcherSize;
  $sizeConfined?: ContentSwitcherSize;
  $sizeWide?: ContentSwitcherSize;
  $variant?: ContentSwitcherVariant;
};

// *** Base ***
const baseButton = css`
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  border-radius: 50px;
  border-style: solid;
  border-width: 0;
  color: ${xdlColorText};
  cursor: pointer;
  display: flex;
  font-weight: 700;
  justify-content: center;
  margin-right: ${spaceS};
  padding-left: ${spaceL};
  padding-right: ${spaceL};
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  @media (hover: hover) {
    &:hover {
      background-color: ${xdlColorHover};
      outline: 0; /* when there is a focus or hover the button is changing the background color so we can remove the outline that looks really bad */
      text-decoration: none;
    }
  }
`;

// *** Sizes ***
export const sizes = {
  small: css`
    font-size: ${scale070};
    height: ${scale130};
  `,
  medium: css`
    font-size: ${scale080};
    height: ${scale150};
  `,
};

const selectedStyle = css`
  &&& {
    background-color: ${xdlColorSelected};
    color: ${xdlColorTextInvert};
  }
`;

const filledStyle = css`
  && {
    background-color: ${xdlColorControlFillTertiary};

    @media (hover: hover) {
      &:hover {
        background-color: ${xdlColorHoverTertiary};
      }
    }
  }
`;

// *** Components ***
const Button = styled.button<ButtonTransientProps>`
  ${baseButton};

  ${({ $size }) => $size && sizes[$size]};
  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};
  ${({ $variant }) => $variant === 'filled' && filledStyle};
  ${({ $selected }) => $selected && selectedStyle};
`;

const RouterLink = styled(Link)<ButtonTransientProps>`
  ${baseButton};
  ${({ $size }) => $size && sizes[$size]};
  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};
  ${({ $variant }) => $variant === 'filled' && filledStyle};
  ${({ $selected }) => $selected && selectedStyle};
`;

const MiniBadge = styled(MiniMadgeComponent)`
  margin-right: ${spaceS};
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ButtonIcon = styled.div`
  line-height: 0;
  margin-right: ${spaceS};
`;

export const Styled = {
  Button,
  ButtonIcon,
  Inner,
  MiniBadge,
  RouterLink,
};
