import styled from 'styled-components';

import type { LocationSuggestion } from '@xing-com/crate-jobs-hooks';
import { spaceXXS } from '@xing-com/tokens';
import { BodyCopy, Meta } from '@xing-com/typography';

export const locationRenderer =
  (locationInput: string) =>
  (item: LocationSuggestion): React.ReactNode => {
    const { name, meta } = item;
    const position = name.toLowerCase().indexOf(locationInput.toLowerCase());

    let formattedName: React.ReactNode = name;
    if (position >= 0) {
      const left = name.substring(0, position);
      const middle = name.substring(position, position + locationInput.length);
      const right = name.substring(position + locationInput.length);

      formattedName = (
        <>
          {left}
          <strong>{middle}</strong>
          {right}
        </>
      );
    }

    return (
      <TextWrapper>
        <BodyCopy size="large" ellipsis noMargin lineHeight="dense">
          {formattedName}
        </BodyCopy>
        <Meta size="small" ellipsis noMargin>
          {meta}
        </Meta>
      </TextWrapper>
    );
  };

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceXXS};
`;
