import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentSwitcher } from '@xing-com/content-switcher';
import { Tag } from '@xing-com/tag';

import { SerpTypes, SeoData } from './seo-data';
import {
  StyledSection,
  StyledAnchor,
  StyledSerpWrapper,
} from './seo-section.styles';

type Props = {
  trackClickSerpCategory: (action: string) => void;
  className?: string;
};

export const SeoSection: React.FC<Props> = ({
  trackClickSerpCategory,
  className,
}) => {
  const { formatMessage } = useIntl();
  const [serpActive, setSerpActive] = useState<number>(0);
  const serpTypes = SerpTypes.map((item, index) => ({
    copy: formatMessage({
      id: `LOSP_SEO_SECTION_HEADER_${item.copy.toUpperCase()}`,
    }),
    serpType: item.serpType,
    onClick: () => handleSerpCategory(item.serpType, index),
  }));

  const handleSerpCategory = (serpType: string, index: number): void => {
    setSerpActive(index);
    trackClickSerpCategory(serpType);
  };

  const handleAnchorClick = (
    e: React.MouseEvent,
    url: string,
    trackingParam: string
  ): void => {
    e.preventDefault();

    window.location.assign(`https://xing.com${url}${trackingParam}`);
  };

  return (
    <StyledSection className={className}>
      <ContentSwitcher activeItemIndex={serpActive} data={serpTypes} />

      <StyledSerpWrapper>
        {SeoData?.map((serp) =>
          serp ? (
            <StyledAnchor
              href={`https://www.xing.com${serp.url}`}
              key={`${serp.serpType}-${
                serp.anchorKey ? serp.anchorKey : serp.anchor
              }`}
              onClick={(e) =>
                handleAnchorClick(e, serp.url, serp.trackingParam)
              }
              isSerpActive={serp.serpType === serpTypes[serpActive].serpType}
            >
              <Tag onClick={() => null}>
                {serp.anchorKey
                  ? formatMessage({ id: serp.anchorKey })
                  : serp.anchor}
              </Tag>
            </StyledAnchor>
          ) : null
        )}
      </StyledSerpWrapper>
    </StyledSection>
  );
};
