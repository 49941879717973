import * as React from 'react';
import { useIntl } from 'react-intl';

import { scale030, scale110 } from '@xing-com/tokens';

import type { SkeletonSliderProps } from '../';

import * as Styled from './skeleton-slider.styles';

export const SkeletonSlider = React.forwardRef<
  HTMLDivElement,
  SkeletonSliderProps
>(({ className, ...props }, forwardedRef): JSX.Element => {
  const intl = useIntl();

  return (
    <Styled.Container
      aria-busy="true"
      aria-label={intl.formatMessage({
        id: 'XDS_SKELETON_GENERIC_A11Y',
        defaultMessage: 'Loading',
      })}
      className={className}
      ref={forwardedRef}
      role="status"
      {...props}
    >
      <Styled.Handle size={scale110} />
      <Styled.Slider height={scale030} />
    </Styled.Container>
  );
});

SkeletonSlider.displayName = 'SkeletonSlider';
