/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconCross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconCross"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12.007 13.421 4.242 4.243 1.414-1.415-4.242-4.242 4.242-4.243L16.25 6.35l-4.242 4.243L7.764 6.35 6.35 7.764l4.242 4.243L6.35 16.25l1.414 1.414z"
    />
  </svg>
);
export default IconCross;
