import * as React from 'react';

import { useLink } from '@xing-com/hub';

import { removeCSRParams } from './remove-csr-params';

export type To =
  | string
  | { pathname: string; hash?: string; search?: string; state?: unknown }
  | undefined;

// @TODO: use mutually exclusive prop instead of plain optional ones.
export type LinkProps = React.PropsWithChildren<{
  /** either a string or an object compatible to react router possibilities containing state */
  to?: To;
  href?: string;
  /** If on reach router, you may handover state directly with a prop */
  state?: unknown;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLAnchorElement>) => void;
  [key: string]: unknown;
}>;

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, to, onMouseOver, onFocus, href, ...props }, forwardRef) => {
    const Link = useLink();

    const [skipTracking, setSkipTracking] = React.useState(true);

    const handleOnMouseOver = (
      event: React.MouseEvent<HTMLAnchorElement>
    ): void => {
      setSkipTracking(false);
      if (onMouseOver) onMouseOver(event);
    };

    const handleOnFocus = (
      event: React.FocusEvent<HTMLAnchorElement>
    ): void => {
      setSkipTracking(false);
      if (onFocus) onFocus(event);
    };

    if (typeof to === 'string' && skipTracking) {
      to = removeCSRParams(to);
    }

    if (typeof href === 'string' && skipTracking) {
      href = removeCSRParams(href);
    }

    if (to) {
      return (
        <Link
          to={to}
          {...props}
          onMouseOver={handleOnMouseOver}
          onFocus={handleOnFocus}
        >
          {children}
        </Link>
      );
    } else {
      return (
        <a
          ref={forwardRef}
          href={href}
          {...props}
          onMouseOver={handleOnMouseOver}
          onFocus={handleOnFocus}
        >
          {children}
        </a>
      );
    }
  }
);
