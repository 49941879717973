/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconArrowDown"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.468 7 4 8.468l8 8 8-8L18.532 7 12 13.532z"
    />
  </svg>
);
export default IconArrowDown;
