import type * as React from 'react';

import type { ButtonProps } from '@xing-com/button';

import * as Styled from './carousel.styles';
import type { ItemProps } from './item';

const { verticalAlignments } = Styled;

export type VerticalAlignment = keyof typeof verticalAlignments;
export type CarouselProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Content of the carousel */
  children: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Allows to scroll a custom pixel width on button click */
  customScrollWidth?: number;
  /** Allows the component to be scrolled to a child at startup once the component is rendered */
  defaultActive?: number;
  /** Hide the scrollbar */
  hideScrollbar?: boolean;
  /** Hide the page buttons */
  hidePageButtons?: boolean;
  /** The padding left and right */
  horizontalPadding?: string;
  /** The padding left and right on tablet screens or larger */
  horizontalPaddingConfined?: string;
  /** The padding left and right on desktop screens or larger */
  horizontalPaddingWide?: string;
  /** Properties for the next page button */
  nextPageButtonProps?: ButtonProps;
  /** Called each time an item enters the viewport. Return value of callback is scroll 'direction' and 'itemIndex'. */
  onItemEnter?: (enterInfo: {
    direction: string | undefined;
    itemIndex: number;
  }) => void;
  /** Called each time the next button is clicked */
  onNextClick?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  /** Called each time the previous button is clicked */
  onPreviousClick?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  /** The bottom padding */
  paddingBottom?: string;
  /** The bottom padding on tablet screens or larger */
  paddingBottomConfined?: string;
  /** The bottom padding on desktop screens or larger */
  paddingBottomWide?: string;
  /** The top padding */
  paddingTop?: string;
  /** The top padding on tablet screens or larger */
  paddingTopConfined?: string;
  /** The top padding on desktop screens or larger */
  paddingTopWide?: string;
  /** Properties for the previous page button */
  previousPageButtonProps?: ButtonProps;
  /** Vertical alignment of items */
  verticalAlignment?: VerticalAlignment;
  /** Allow Items in Carousel to be focusable */
  tabbable?: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  Omit<
    ItemProps,
    | 'itemIndex'
    | 'lastItemSpace'
    | 'lastItemSpaceConfined'
    | 'lastItemSpaceWide'
    | 'onEnter'
  >;
