import * as React from 'react';
import { useIntl } from 'react-intl';

import { IconCross } from '@xing-com/icons';

import * as Styled from './bottom-sheet.styles';
import type { BottomSheetProps } from './bottom-sheet.types';

export const BottomSheetPrimitive = React.forwardRef<
  HTMLDivElement,
  BottomSheetProps & { closeModal: (event: React.SyntheticEvent) => void }
>(
  (
    { children, className, closeModal, show, showCloseButton, ...props },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();

    return (
      <Styled.BottomSheet
        aria-modal="true"
        className={className}
        data-show={show}
        data-xds="BottomSheet"
        ref={forwardedRef}
        role="dialog"
        {...props}
      >
        <Styled.Wrapper>
          {children}
          {showCloseButton ? (
            <Styled.Close
              aria-label={intl.formatMessage({
                id: 'XDS_CLOSE_BUTTON_A11Y',
                defaultMessage: 'Close',
              })}
              icon={IconCross}
              onClick={closeModal}
              type="button"
            />
          ) : null}
        </Styled.Wrapper>
      </Styled.BottomSheet>
    );
  }
);

BottomSheetPrimitive.displayName = 'BottomSheet (Primitive)';
