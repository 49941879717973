import type * as React from 'react';
import type { TextareaAutosizeProps } from 'react-textarea-autosize';

import * as Styled from './input-bar.styles';
const { sizes, variants } = Styled;

export type InputBarSize = keyof typeof sizes;
export type InputBarVariant = keyof typeof variants;

type CommonProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** Pass props to the close button */
  clearButtonProps?: React.HTMLAttributes<HTMLButtonElement>;
  /** Disable the input or textarea */
  disabled?: boolean;
  /** @callback */
  onClearInput?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /** The placeholder attribute of the input or textarea */
  placeholder?: string;
  /** The size on mobile screens or larger */
  size?: InputBarSize;
  /** The size on tablet screens or larger */
  sizeConfined?: InputBarSize;
  /** The size on desktop screens or larger */
  sizeWide?: InputBarSize;
  /** The value attribute of the input or textarea */
  value: string;
  /** The variant of the input-bar */
  variant?: InputBarVariant;
};

type InputProps = CommonProps & {
  /** leading icon */
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  /** @deprecated Please use the "ref" property instead */
  innerRef?: React.Ref<HTMLInputElement>;
  /** Render a textarea */
  multiline?: never;
  /** @callback */
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
} & React.HTMLAttributes<HTMLInputElement>;

type TextareaProps = CommonProps & {
  /** Leading icon */
  icon?: never;
  /** @deprecated Please use the "ref" property instead */
  innerRef?: React.Ref<HTMLTextAreaElement>;
  /** Render a textarea */
  multiline?: boolean;
  /** @callback */
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
} & React.HTMLAttributes<HTMLTextAreaElement & TextareaAutosizeProps>;

export type InputBarProps = InputProps | TextareaProps;
