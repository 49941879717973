/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconTrash"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5 4h15v2h-1v15H5V5.999L4 6V4zm2 2v13h10V6H7m4 2v9H9V8zm4 0v9h-2V8zm-1-7v2h-4V1z"
    />
  </svg>
);
export default IconTrash;
