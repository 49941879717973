import styled from 'styled-components';

import { xdlColorTextSecondary } from '@xing-com/tokens';

import { BodyCopy } from '../';

// *** Components ***
const Meta = styled(BodyCopy)`
  && {
    color: ${xdlColorTextSecondary};
  }
`;

export const Styled = {
  Meta,
};
