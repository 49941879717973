import times from 'lodash/times';
import * as React from 'react';
import { useIntl } from 'react-intl';

import devNote from '@xing-com/dev-note';

import * as Styled from './skeleton-headline.styles';
import type { SkeletonHeadlineProps } from './skeleton-headline.types';

export const SkeletonHeadline = React.forwardRef<
  HTMLDivElement,
  SkeletonHeadlineProps
>(
  (
    {
      alignment = 'left',
      amountRows = 2,
      className,
      size = 'medium',
      sizeConfined,
      sizeWide,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();

    if (variant) {
      devNote.deprecate(
        "The variant property is deprecated and should be removed. Variant 'secondary' is automatically mapped to 'primary'."
      );
    }

    return (
      <Styled.Headline
        $alignment={alignment}
        aria-busy="true"
        aria-label={intl.formatMessage({
          id: 'XDS_SKELETON_GENERIC_A11Y',
          defaultMessage: 'Loading',
        })}
        className={className}
        data-xds="SkeletonHeadline"
        ref={forwardedRef}
        role="status"
        {...props}
      >
        {times(amountRows, (i: number) => (
          <Styled.HeadlineRow
            $amountRows={amountRows}
            $size={size}
            $sizeConfined={sizeConfined}
            $sizeWide={sizeWide}
            key={i}
          />
        ))}
      </Styled.Headline>
    );
  }
);

SkeletonHeadline.displayName = 'SkeletonHeadline';
