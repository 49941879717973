import { useIntl } from 'react-intl';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import type { JobRecentSearchFragment } from '@xing-com/crate-jobs-graphql';
import { useRecentSearches } from '@xing-com/crate-jobs-hooks';
import type { LocationSuggestion } from '@xing-com/crate-jobs-hooks';
import { IconArrowRight, IconLocationPin, IconSearch } from '@xing-com/icons';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { LightMode } from '@xing-com/theme-mode-switcher';

import { useJobRoleAutocompletion, useLocationAutocompletion } from './hooks';
import { Promotions } from './promotions';
import {
  keywordRenderer,
  locationRenderer,
  recentSearchRenderer,
} from './renderers';
import * as S from './search-box.styles';
import { Suggestions, type SuggestionValues } from './suggestions';

type DefaultAutocompleteContent =
  | SuggestionValues
  | JobRecentSearchFragment
  | null;

const countries = ['de', 'at', 'ch'];
const locationSuggestionToString = (item: LocationSuggestion): string =>
  item.name;
const defaultContentToString = (item: DefaultAutocompleteContent): string => {
  if (typeof item === 'string' || item === null) {
    return '';
  }

  return item.guid || '';
};
const defaultContentRenderer = (
  item: DefaultAutocompleteContent
): React.ReactNode => {
  if (item === null) {
    return null;
  }

  if (typeof item === 'string') {
    // Render custom options
    return <Suggestions suggestion={item} />;
  }

  return recentSearchRenderer(item);
};

type Props = {
  keywordsInput: string;
  locationInput: string;
  isLocationDisabled?: boolean;
  condensedOnMobile?: boolean;
  withFilters?: boolean;
  withPromotions?: boolean;
  landingFilters?: React.ReactNode;
  onChangeKeywords: (value: string) => void;
  onSelectKeywords: (value: string) => void;
  onChangeLocation: (value: string) => void;
  onSelectLocation: (item: LocationSuggestion) => void;
  onSubmit: () => void;
};

export const SearchBox: React.FC<Props> = ({
  keywordsInput,
  locationInput,
  isLocationDisabled = false,
  condensedOnMobile = false,
  withFilters,
  withPromotions,
  landingFilters,
  onChangeKeywords,
  onSelectKeywords,
  onChangeLocation,
  onSelectLocation,
  onSubmit,
}) => {
  const { isLoggedIn } = useLoginState();
  const { formatMessage } = useIntl();
  const { jobRoleSuggestions, fetchJobRoleSuggestions } =
    useJobRoleAutocompletion();
  const { locationSuggestions, fetchLocationSuggestions } =
    useLocationAutocompletion({ countries });
  const { recentSearches, refetch: refetchRecentSearches } = useRecentSearches({
    skip: !isLoggedIn,
  });
  const isSmallScreen = useMatchMedia(mediaSmallOrTiny);

  const showDefaultContent = isLoggedIn && keywordsInput.length === 0;
  const defaultContent: DefaultAutocompleteContent[] = [
    'SWIMP' as const,
    'SWIMS' as const,
    ...(recentSearches ?? []).filter(Boolean),
    // Filter to remove possible null values
  ].filter(Boolean);

  const handleOnChangeKeywords = (value: string): void => {
    onChangeKeywords(value);
    if (value) {
      fetchJobRoleSuggestions(value.trim());
    } else {
      refetchRecentSearches();
    }
  };

  const handleOnChangeLocation = (value: string): void => {
    onChangeLocation(value);
    if (value) {
      fetchLocationSuggestions(value.trim());
    }
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit();

    if (isSmallScreen && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  return (
    <LightMode>
      <S.Form
        onSubmit={handleOnSubmit}
        $condensedOnMobile={condensedOnMobile}
        // FIXME: action="." is needed to show the search button on the iOS keyboards: https://stackoverflow.com/a/26287843}
        action="."
      >
        {/* @ts-expect-error FIXME: SC6 */}
        <S.KeywordsAutoComplete
          type="search"
          variant="inputBar"
          textFieldVariant="plain"
          size="xlarge"
          value={keywordsInput}
          placeholder={formatMessage({
            id: 'JOBS_FIND_JOBS_SEARCH_PLACEHOLDER',
          })}
          suggestions={showDefaultContent ? defaultContent : jobRoleSuggestions}
          onChange={handleOnChangeKeywords}
          onSelect={showDefaultContent ? undefined : onSelectKeywords}
          data-testid="search-keywords-autocomplete"
          id="keywords"
          icon={IconSearch}
          itemRenderer={
            showDefaultContent
              ? defaultContentRenderer
              : keywordRenderer(keywordsInput)
          }
          suggestionToString={
            showDefaultContent ? defaultContentToString : undefined
          }
          $condensedOnMobile={condensedOnMobile}
        />
        {/* @ts-expect-error FIXME: SC6 */}
        <S.LocationAutoComplete
          type="search"
          variant="inputBar"
          textFieldVariant="plain"
          size="xlarge"
          value={locationInput}
          placeholder={formatMessage({
            id: 'JOBS_FIND_JOBS_SEARCH_LOCATION_PLACEHOLDER',
          })}
          suggestions={locationSuggestions}
          onChange={handleOnChangeLocation}
          onSelect={onSelectLocation}
          itemRenderer={locationRenderer(locationInput)}
          suggestionToString={locationSuggestionToString}
          data-testid="search-location-autocomplete"
          id="location"
          icon={IconLocationPin}
          disabled={isLocationDisabled}
          $withFilters={withFilters}
          $condensedOnMobile={condensedOnMobile}
        />

        <S.SubmitButton
          id="search-button"
          variant="onColorStrong"
          type="submit"
          data-testid="search-button"
          icon={IconArrowRight}
          $condensedOnMobile={condensedOnMobile}
          aria-label={formatMessage({ id: 'JOBS_FIND_JOBS_SEARCH_BUTTON' })}
        />
      </S.Form>

      {withFilters ? landingFilters : null}
      {withPromotions ? <Promotions /> : null}
    </LightMode>
  );
};
