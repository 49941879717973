import styled from 'styled-components';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { scale130, scale190 } from '@xing-com/tokens';

import { useAdRefresh } from '../use-ad-refresh';

import { DisplayAdStartpageBillboard, DisplayAdStartpageRectangle01 } from '.';

export const SectionCol = styled.div`
  width: 100%;
  margin-bottom: ${scale190};
  margin-top: ${scale190};
  display: flex;
  justify-content: center;

  @media ${mediaSmallOrTiny} {
    margin-top: ${scale130};
    margin-bottom: 0;
  }
`;

const AdWrapper = styled.div`
  width: 300px;
  height: 250px;
  position: relative;
  z-index: 1;

  @media ${mediaConfined} {
    width: 970px;
    height: 250px;
  }
`;

export const DisplayAdStartpageSlot01: React.FC = () => {
  const isSmallScreen = useMatchMedia(mediaSmallOrTiny);

  const { refreshMobile, refreshDesktop } = useAdRefresh({
    isSmallScreen,
    slots: {
      mobile: 'rectangle01',
      desktop: 'billboard',
    },
  });

  return (
    <NoSSR>
      <SectionCol>
        <AdWrapper>
          {isSmallScreen ? (
            <DisplayAdStartpageRectangle01 refresh={refreshMobile} />
          ) : (
            <DisplayAdStartpageBillboard refresh={refreshDesktop} />
          )}
        </AdWrapper>
      </SectionCol>
    </NoSSR>
  );
};
