//Local storage
export const STORAGE_JOBS_NAMESPACE = 'XING::Jobs';
export const LO_APPLICATION_SUBMITTED_STORAGE_PREFIX = 'application-submitted';
export const JOBS_REPORTED_JOBS = `${STORAGE_JOBS_NAMESPACE}.reported.jobs`;
export const JOB_AI_SUMMARY_FEEDBACK = `${STORAGE_JOBS_NAMESPACE}.job-ai-summary-feedback`;
export const EXTERNAL_APPLY_FLOW_LOCALSTORAGE_KEY = `${STORAGE_JOBS_NAMESPACE}.external-application-flow`;

// Recommendations
export const RECOMMENDATIONS_COUNT = 50;

// Find jobs
export const DE_JOB_MANAGER_URL =
  'https://recruiting.xing.com/produkte/stellenanzeigen';
export const EN_JOB_MANAGER_URL =
  'https://recruiting.xing.com/en/products/job-ads';

// Search
export const SEARCH_PAGE_SIZE = 20;
export const DEFAULT_RADIUS = 0;
export const RADIUS_OPTIONS = [0, 10, 20, 50, 70, 100, 200];
export const DEFAULT_SORT = 'relevance';
export const MIN_SALARY = 0;
export const MAX_SALARY = 200_000;
export const SALARY_STEP = 1000;
export const SEARCH_STATE_FILTER_PARAMS = {
  // Filters
  benefit: 'benefit',
  careerLevel: 'careerLevel',
  city: 'city',
  company: 'company',
  country: 'country',
  discipline: 'discipline',
  employmentType: 'employmentType',
  industry: 'industry',
  remoteOption: 'remoteOption',
  salary: 'salary',
  // Other query params
  page: 'page',
  sort: 'sort',
} as const;
export const SEARCH_STATE_PARAMS = {
  // Inputs
  keywords: 'keywords',
  location: 'location',
  radius: 'radius',
  cityId: 'cityId',
  ...SEARCH_STATE_FILTER_PARAMS,
} as const;
export const SEARCH_STATE_DISABLED_FILTER_PARAMS = {
  careerLevel: SEARCH_STATE_PARAMS.careerLevel,
  employmentType: SEARCH_STATE_PARAMS.employmentType,
  remoteOption: SEARCH_STATE_PARAMS.remoteOption,
  salary: SEARCH_STATE_PARAMS.salary,
  location: SEARCH_STATE_PARAMS.location,
};
export const SEARCH_QUERY_ID_PARAM = 'id';
export const SEARCH_STATE_SEPARATOR = '*';
export const SEARCH_STATE_DISABLED_SUFFIX = '_off';
export const SMALL_SCREEN_BOTTOM_SHEET_PARAM = 'filter_view';
export const SEARCH_COUNT_LOCAL_STORAGE = 'SEARCH_QUERY_COUNT';
export const AI_SEARCH_ERROR_BANNER = 'ai_error';
export const AI_SEARCH_ERROR_TYPE = { EMPTY: 'empty' } as const;
export const AI_SEARCH_FEEDBACK_SURVERY_URL =
  'https://survey2.quantilope.com/HPwbgY4umGMwpyJfQ/L3xt4eYF38J6d4KMC';
/**
 * Currently we can keep a boolean parameter, but if we add more suggestions, we
 * can simply rename this variable, as well as the param, and instead of a
 * boolean, allow a set of strings
 */
export const AI_FORCE_CS_ANIMATION_KEY = 'a';
export const AI_FORCE_CS_ANIMATION_VALUE = {
  SWIMP: 'swimp',
  SWIMS: 'swims',
};
export const AI_SWIMP_TRACKING_TOKEN_PARAM = 'tt';
export const AI_SEARCH_AUTOFOCUS_PARAM = 'af';

// Search alerts
export const MAX_ALERT_COUNT = 40;
export const SEARCH_ALERT_COUNT_PARAM = 'sa_count';
export const SEARCH_ALERTS_MODAL_PARAM = 'sa_modal';
export const REFERRER_ALERT_ID_PARAM = 'referrer_alert_id';
export const SEARCH_ALERTS_MODAL_TYPES = {
  REPEAT: 'repeat',
  CREATE: 'create',
  JOB_DETAILS: 'job_details',
} as const;
export const EDIT_SEARCH_ALERT_PARAM = 'edit_alert';
export const SEARCH_ALERTS_ERROR_BANNER = 'sa_error';
export const SEARCH_ALERTS_ERROR_TYPE = {
  CREATE: 'create',
  DELETE: 'delete',
  DUPLICATED: 'duplicated',
} as const;
export const SEARCH_ALERTS_EMAIL_BANNER_STATE = 'email_banner';

// Recent searches
export const SEARCH_BOX_MAX_RECENT_SEARCHES = 5;

// Job details
export const SUPPORTED_SUMMARY_LANGUAGES = ['de', 'en'];
export const JDP_JOB_SLUGS = 'jdpJobSlugs';
export const JDP_SA_SEARCH_QUERY = 'jdpSearchAlertSearchQuery';
export const JDP_SA_DEFAULT_QUERY = 'jdpSearchAlertDefaultQuery';
export const JDP_BACK_PARAMS = 'jdpBackParams';
export const JDP_SUMMARY_MAX_SKILLS = 6;

// Generic
export const ORIGIN_PARAM = 'origin';
export const ORIGIN_PARAM_VALUES = {
  searchAlertsFindJobs: 'search_alerts_find_jobs',
  searchAlerts: 'search_alerts',
};

// Layout
export const TOP_BAR_HEIGHT_DESKTOP = 68;
export const TOP_BAR_HEIGHT_DESKTOP_LO = 84;
export const TOP_BAR_HEIGHT_MOBILE = 54;
export const BOTTOM_BAR_HEIGHT = 60;

// Brewery
export const FLAG = {
  AMBASSADOR: 'ambassador',
  BASIC: 'basic',
  PREMIUM: 'premium',
  EXECUTIVE: 'basic',
  INSIDER: 'insider',
  MODERATOR: 'moderator',
  PRO_COACH: 'proCoach',
  PRO_TRAINER: 'proTrainer',
} as const;
export const GENDER = {
  MALE: 'male-user',
  FEMALE: 'female-user',
  NONE: 'neutral-user',
  OTHER: 'neutral-user',
} as const;
