import { FabiJobSearchQueryGenerationUseCase } from '@xing-com/crate-common-graphql-types';
import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { useIsAiSearch } from '@xing-com/crate-jobs-hooks';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';

type TrackingProps = {
  PropActionOrigin: string;
  PropTrackAction: string;
};

const USE_CASE_TO_TRACKING: Record<
  FabiJobSearchQueryGenerationUseCase,
  string
> = {
  [FabiJobSearchQueryGenerationUseCase.SearchWithProfile]:
    'search_with_my_profile',
  [FabiJobSearchQueryGenerationUseCase.SearchWithSkills]:
    'search_with_my_skills',
};

type UseSuggestionsTrackingResult = (
  useCase: FabiJobSearchQueryGenerationUseCase
) => void;

// Currently it's only one suggestion that we have, yet we'll need to add more
export const useSuggestionsTracking = (): UseSuggestionsTrackingResult => {
  const trackNwt = useJobsNwtTracking();
  const { track: trackAdobe } =
    useTracking<InteractionTrackingEvent<TrackingProps>>();
  const isAiSearch = useIsAiSearch();

  const track = (useCase: FabiJobSearchQueryGenerationUseCase): void => {
    trackNwt({
      event: 'clicked',
      element: USE_CASE_TO_TRACKING[useCase],
      elementDetail: 'auto_complete_option',
      componentName: 'ai_search',
      eventSchema: 'aicomponent',
      page: isAiSearch ? 'jobs_search/serp' : 'jobs/ai_search',
    });
    trackAdobe({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: `${USE_CASE_TO_TRACKING[useCase]}_click`,
      PropActionOrigin: isAiSearch
        ? 'jobs_serp_auto_complete_option'
        : 'jobs_ai_search_auto_complete_option',
    });
  };

  return track;
};
