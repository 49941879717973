import styled, { css } from 'styled-components';

import { IconArrowRight } from '@xing-com/icons';
import { mediaConfined } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import { Marker as BaseMarker } from '@xing-com/marker';
import {
  cornerRadiusL,
  cornerRadiusXXL,
  motionEasingStandard,
  motionTimeS,
  scale130,
  spaceM,
  spaceS,
  spaceXXS,
  xdlColorTextOnDark,
  xdlPaletteOrange06,
  xdlPalettePurpleRain05,
  xdlPalettePurpleRain06,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

type Variant = {
  $variant: 'medium' | 'small' | 'xsmall';
};

export const Container = styled(Link)`
  &:hover,
  &:focus {
    text-decoration: none;

    & svg:only-of-type {
      transform: translateX(${spaceXXS});

      @media (prefers-reduced-motion) {
        transform: none;
      }
    }
  }
`;

// Avoid using it on the server/jest
if (typeof CSS !== 'undefined' && typeof CSS.registerProperty === 'function') {
  try {
    // Register property to enable transitions (it only works using JS)
    CSS.registerProperty({
      name: '--mousegradient-alpha',
      syntax: '<number>',
      inherits: false,
      initialValue: '0',
    });
  } catch (e) {
    // Currently it's not possible to check if a property has already been registered.
    // We are ignoring the error thrown in that case.
  }
}

export const Background = styled.div<Variant>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  color: ${xdlColorTextOnDark};

  ${({ $variant }) =>
    $variant === 'small'
      ? css`
          padding: ${spaceS} ${spaceM};
          border-radius: ${cornerRadiusXXL};
        `
      : $variant === 'xsmall'
        ? css`
            height: ${scale130};
            padding: 0 ${spaceM};
            border-radius: ${cornerRadiusXXL};
          `
        : css`
            padding: ${spaceM} 20px;

            @media ${mediaConfined} {
              border-bottom-left-radius: ${cornerRadiusL};
              border-bottom-right-radius: ${cornerRadiusL};
            }
          `};

  // Animation magic from Silke
  background-color: ${xdlPalettePurpleRain06};
  --x1: 0.21;
  --y1: 0.79;
  --r1: 150;

  --x2: 0.5;
  --y2: 0.21;
  --r2: 198;

  --x3: 0.5;
  --y3: 0.21;
  --r3: 299;

  --xmouse: 0.5;
  --ymouse: 0.5;
  --mousegradient-alpha: 0;
  transition: --mousegradient-alpha 0.8s ease-in-out;

  background-image:
    radial-gradient(
      circle 50px at calc(var(--xmouse) * 100%) calc(var(--ymouse) * 100%),
      rgba(254, 154, 149, var(--mousegradient-alpha)) 0%,
      rgba(214, 180, 254, 0) 100%
    ),
    radial-gradient(
      circle calc(var(--r1) * 1px) at calc(var(--x1) * 100%)
        calc(var(--y1) * 100%),
      ${xdlPalettePurpleRain05} 0%,
      rgba(189, 133, 251, 0) 100%
    ),
    radial-gradient(
      circle calc(var(--r2) * 1px) at calc(var(--x2) * 100%)
        calc(var(--y2) * 100%),
      ${xdlPalettePurpleRain06} 0%,
      rgba(164, 86, 246, 0) 100%
    ),
    radial-gradient(
      circle calc(var(--r3) * 1px) at calc(var(--x3) * 100%)
        calc(var(--y3) * 100%),
      ${xdlPaletteOrange06} 0%,
      rgba(254, 154, 149, 0) 100%
    );

  @media (prefers-reduced-motion) {
    background-image: none;
  }
`;

export const Marker = styled(BaseMarker)`
  flex-shrink: 0;
`;

export const MobileMarkerText = styled.span`
  @media ${mediaConfined} {
    display: none;
  }
`;

export const DesktopMarkerText = styled.span`
  display: none;

  @media ${mediaConfined} {
    display: block;
  }
`;

export const RightArrow = styled(IconArrowRight)<Variant>`
  transition: transform ${motionTimeS} ${motionEasingStandard};

  ${({ $variant }) =>
    $variant === 'xsmall' &&
    css`
      display: none;
    `}
`;

export const Text = styled(Headline)`
  color: ${xdlColorTextOnDark};
`;
