/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconSearch"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 2a9 9 0 0 1 6.568 15.154l3.089 3.089-1.414 1.414-3.201-3.2A9 9 0 1 1 12 2m0 2a7 7 0 1 0 0 14 7 7 0 0 0 0-14"
    />
  </svg>
);
export default IconSearch;
