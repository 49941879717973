import type { PreferencesData } from './types';

export const preferencesData: PreferencesData = {
  options: [
    {
      preference: 'preferred_workplace',
      header: 'LOSP_SEARCH_INSPIRATION_PREFERENCES_WORKPLACE_HEADLINE',
      possibleValues: {
        LOSP_PREFERENCES_EXPERIMENT_WORKPLACE_OFFICE: 'office',
        LOSP_PREFERENCES_EXPERIMENT_WORKPLACE_PARTLY_HOME: 'partlyHome',
        LOSP_PREFERENCES_EXPERIMENT_WORKPLACE_MOSTLY_HOME: 'mostlyHome',
        LOSP_PREFERENCES_EXPERIMENT_WORKPLACE_HOME_OFFICE: 'homeOffice',
      },
    },
    {
      preference: 'career_level',
      header: 'LOSP_SEARCH_INSPIRATION_PREFERENCES_CAREER_LEVEL_HEADLINE',
      possibleValues: {
        LOSP_PREFERENCES_EXPERIMENT_LEVEL_ENTRY: '2',
        LOSP_PREFERENCES_EXPERIMENT_LEVEL_PROFESSIONAL: '3',
        LOSP_PREFERENCES_EXPERIMENT_LEVEL_MANAGER: '4',
      },
    },
    {
      preference: 'salary_expectations',
      header: 'LOSP_SEARCH_INSPIRATION_PREFERENCES_SALARY_HEADLINE',
      possibleValues: {
        LOSP_PREFERENCES_EXPERIMENT_SALARY_THIRTY: '30000',
        LOSP_PREFERENCES_EXPERIMENT_SALARY_FORTY_FIVE: '45000',
        LOSP_PREFERENCES_EXPERIMENT_SALARY_SIXTY: '60000',
        LOSP_PREFERENCES_EXPERIMENT_SALARY_EIGHTY: '80000',
      },
    },
  ],
};
