import styled, { css } from 'styled-components';

import { mediaXWide, zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import {
  motionEasingEnter,
  motionEasingLeave,
  motionTimeL,
  space3XL,
  space4XL,
  space5XL,
} from '@xing-com/tokens';

type ContainerTProps = { $noMargin?: boolean };

export const modalPageDuration = motionTimeL;
export const modalPageTransitionName = 'slideFade';

export const Container = styled.div<ContainerTProps>`
  ${(props) =>
    !props.$noMargin &&
    css`
      margin-top: ${space4XL};

      @media ${mediaXWide} {
        margin-top: ${space5XL};
      }
    `};
`;

export const AnimatedModalPage = styled.div`
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  position: fixed;
  z-index: ${zIndexElevatedLayer5};

  &.${modalPageTransitionName}-enter {
    opacity: 0;
    transform: translateY(${space3XL});
  }

  &.${modalPageTransitionName}-appear {
    opacity: 0;
    transform: translateY(${space3XL});
  }

  &.${modalPageTransitionName}-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: ${motionTimeL}, ${motionTimeL};
    transition-property: opacity, transform;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${modalPageTransitionName}-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: ${motionTimeL}, ${motionTimeL};
    transition-property: opacity, transform;
    transition-timing-function: ${motionEasingEnter}, ${motionEasingEnter};
  }

  &.${modalPageTransitionName}-enter-done {
    opacity: 1;
    transform: translateY(0);
  }

  &.${modalPageTransitionName}-appear-done {
    opacity: 1;
    transform: translateY(0);
  }

  &.${modalPageTransitionName}-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.${modalPageTransitionName}-exit-active {
    opacity: 0;
    transform: translateY(${space3XL});
    transition-duration: ${motionTimeL}, ${motionTimeL};
    transition-property: opacity, transform;
    transition-timing-function: ${motionEasingLeave}, ${motionEasingLeave};
  }

  &.${modalPageTransitionName}-exit-done {
    opacity: 0;
    transform: translateY(${space3XL});
  }
`;
