import * as React from 'react';
import { useIntl } from 'react-intl';

import { IconArrowLeft, IconArrowRight } from '@xing-com/icons';
import { spaceS, spaceM, spaceXL } from '@xing-com/tokens';

import * as Styled from './carousel.styles';
import type { CarouselProps } from './carousel.types';
import { useWindowSize } from './hooks/use-window-size';
import { Item } from './item';

export const Carousel = React.forwardRef<HTMLDivElement, CarouselProps>(
  (
    {
      children,
      className,
      customScrollWidth,
      defaultActive,
      hidePageButtons,
      hideScrollbar,
      horizontalPadding,
      horizontalPaddingConfined,
      horizontalPaddingWide,
      itemMaxWidth,
      itemMinWidth,
      itemSnapPosition,
      itemSpacing = spaceM,
      itemSpacingConfined = spaceXL,
      itemSpacingWide,
      itemWidth = '80%',
      itemWidthConfined = '33%',
      itemWidthWide,
      nextPageButtonProps,
      onItemEnter,
      onNextClick,
      onPreviousClick,
      paddingBottom = spaceS,
      paddingBottomConfined,
      paddingBottomWide,
      paddingTop,
      paddingTopConfined,
      paddingTopWide,
      previousPageButtonProps,
      tabbable = false,
      verticalAlignment = 'stretch',
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();
    const size = useWindowSize();
    const carouselRef = React.useRef<HTMLDivElement>();

    const [carouselDimensions, setCarouselDimension] = React.useState({
      clientWidth: 0,
      scrollWidth: 0,
      scrollLeft: 0,
    });

    const itemProps = {
      horizontalPadding,
      horizontalPaddingConfined,
      horizontalPaddingWide,
      itemMaxWidth,
      itemMinWidth,
      itemSnapPosition,
      itemSpacing,
      itemSpacingConfined,
      itemSpacingWide,
      itemWidth,
      itemWidthConfined,
      itemWidthWide,
      lastItemSpace: horizontalPadding,
      lastItemSpaceConfined: horizontalPaddingConfined,
      lastItemSpaceWide: horizontalPaddingWide,
    };

    const calculateSizes = (): void => {
      if (carouselRef.current) {
        setCarouselDimension({
          clientWidth: carouselRef.current.clientWidth,
          scrollWidth: carouselRef.current.scrollWidth,
          scrollLeft: carouselRef.current.scrollLeft,
        });
      }
    };

    const isCarouselStart = carouselDimensions.scrollLeft === 0;
    const isCarouselEnd =
      carouselDimensions.clientWidth +
        Math.ceil(carouselDimensions.scrollLeft) >=
      carouselDimensions.scrollWidth;

    const onHandlePreviousClick = (
      event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
    ): void => {
      if (carouselRef.current) {
        if (onPreviousClick) onPreviousClick(event);
        carouselRef.current.scrollBy({
          top: 0,
          left: customScrollWidth
            ? -customScrollWidth
            : -carouselDimensions.clientWidth,
          behavior: 'smooth',
        });
      }
    };

    const onHandleNextClick = (
      event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
    ): void => {
      if (carouselRef.current) {
        if (onNextClick) onNextClick(event);
        carouselRef.current.scrollBy({
          top: 0,
          left: customScrollWidth || carouselDimensions.clientWidth,
          behavior: 'smooth',
        });
      }
    };

    const handleScroll = (): void => {
      calculateSizes();
    };

    React.useEffect(() => {
      setTimeout(() => {
        calculateSizes();
      }, 500);
    }, [size]);

    React.useEffect(() => {
      if (!defaultActive) return;

      setTimeout(() => {
        const carouselChildrens = carouselRef.current?.children;
        const defaultActiveChildren = carouselChildrens?.[defaultActive];

        if (defaultActiveChildren) {
          defaultActiveChildren.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }, [carouselRef, defaultActive]);

    return (
      <Styled.Container role="region">
        <Styled.Carousel
          $hideScrollbar={hideScrollbar}
          $horizontalPadding={horizontalPadding}
          $horizontalPaddingConfined={horizontalPaddingConfined}
          $horizontalPaddingWide={horizontalPaddingWide}
          $paddingBottom={paddingBottom}
          $paddingBottomConfined={paddingBottomConfined}
          $paddingBottomWide={paddingBottomWide}
          $paddingTop={paddingTop}
          $paddingTopConfined={paddingTopConfined}
          $paddingTopWide={paddingTopWide}
          $verticalAlignment={verticalAlignment}
          aria-live="polite"
          className={className}
          data-xds="Carousel"
          onScroll={handleScroll}
          ref={forwardedRef || carouselRef}
          role="list"
          {...props}
        >
          {React.Children.map(children, (item, index) => (
            <Item
              itemIndex={index}
              key={index}
              tabbable={tabbable}
              onEnter={(direction: string | undefined, itemIndex: number) =>
                onItemEnter && onItemEnter({ direction, itemIndex })
              }
              {...itemProps}
            >
              {item}
            </Item>
          ))}
        </Styled.Carousel>
        {isCarouselStart || hidePageButtons ? null : (
          <Styled.PreviousPageButton
            $paddingBottom={paddingBottom || '0px'}
            $paddingBottomConfined={paddingBottomConfined || paddingBottom}
            $paddingBottomWide={paddingBottomWide || paddingBottom}
            $paddingTop={paddingTop || '0px'}
            $paddingTopConfined={paddingTopConfined || paddingTop}
            $paddingTopWide={paddingTopWide || paddingTop}
            aria-label={intl.formatMessage({
              id: 'XDS_CAROUSEL_PREVIOUS_BUTTON_A11Y',
              defaultMessage: 'Previous page',
            })}
            icon={IconArrowLeft}
            onClick={onHandlePreviousClick}
            size="medium"
            variant="tertiary"
            {...previousPageButtonProps}
          />
        )}
        {isCarouselEnd || hidePageButtons ? null : (
          <Styled.NextPageButton
            $paddingBottom={paddingBottom || '0px'}
            $paddingBottomConfined={paddingBottomConfined || paddingBottom}
            $paddingBottomWide={paddingBottomWide || paddingBottom}
            $paddingTop={paddingTop || '0px'}
            $paddingTopConfined={paddingTopConfined || paddingTop}
            $paddingTopWide={paddingTopWide || paddingTop}
            aria-label={intl.formatMessage({
              id: 'XDS_CAROUSEL_NEXT_BUTTON_A11Y',
              defaultMessage: 'Next page',
            })}
            icon={IconArrowRight}
            onClick={onHandleNextClick}
            size="medium"
            variant="tertiary"
            {...nextPageButtonProps}
          />
        )}
      </Styled.Container>
    );
  }
);

Carousel.displayName = 'Carousel';
