import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { useHost } from '@xing-com/crate-xinglet';

import copy from './copy';

const shortcutIco =
  'https://www.xing.com/assets/frontend_minified/img/shared/xing_r1.ico';
const appleIco =
  'https://www.xing.com/assets/frontend_minified/img/shared/xing_icon_apple.png';

type HeadProps = {
  page: 'startPage' | 'languagePage';
};

export const Head: React.FC<React.PropsWithChildren<HeadProps>> = ({
  page = 'startPage',
  children,
}) => {
  const { formatMessage } = useIntl();
  const { isPreview } = useHost();

  const externalUrl = isPreview
    ? 'https://preview.xing.com '
    : 'https://www.xing.com';

  const linkTags = [
    { rel: 'shortcut icon', href: shortcutIco, type: 'image/ico' },
    {
      rel: 'apple-touch-icon',
      href: appleIco,
    },
    {
      rel: 'search',
      type: 'application/opensearchdescription+xml',
      title: 'XING',
      href: '/media/xing-opensearch.xml',
    },
    {
      rel: 'canonical',
      href: 'https://www.xing.com',
    },
  ];

  const metaTags = [
    {
      name: 'description',
      content: formatMessage(copy[`${page}Description`]),
    },
    {
      name: 'HandHeldFriendly',
      content: 'true',
    },
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'apple-mobile-web-app-status-bar-style',
      content: 'black',
    },
    { name: 'p:domain_verify', content: '67d537533de2cff8410b2b6048c8de0d' },
  ];

  const ogTags = [
    {
      property: 'og:title',
      content: formatMessage(copy[`${page}Title`]),
    },
    {
      property: 'og:site_name',
      content: formatMessage(copy[`${page}SiteName`]),
    },
    {
      property: 'og:url',
      content: externalUrl,
    },
    {
      property: 'og:image',
      content: `https://static.xingcdn.com/og-cover-image.png`,
    },
    {
      property: 'og:description',
      content: formatMessage(copy[`${page}Description`]),
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ];

  return (
    <Helmet
      title={formatMessage(copy[`${page}Title`])}
      meta={[...metaTags, ...ogTags]}
    >
      {linkTags.map((linkTag) => (
        <link key={linkTag.href} {...linkTag} />
      ))}
      {children}
    </Helmet>
  );
};
