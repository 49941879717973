import times from 'lodash/times';
import * as React from 'react';
import { useIntl } from 'react-intl';

import devNote from '@xing-com/dev-note';
import { cornerRadiusS, scale060 } from '@xing-com/tokens';

import * as Styled from './skeleton-body-copy.styles';
import type { SkeletonBodyCopyProps } from './skeleton-body-copy.types';

export const SkeletonBodyCopy = React.forwardRef<
  HTMLDivElement,
  SkeletonBodyCopyProps
>(
  (
    { alignment = 'left', amountRows = 2, className, variant, ...props },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();

    if (variant) {
      devNote.deprecate(
        "The variant property is deprecated and should be removed. Variant 'secondary' is automatically mapped to 'primary'."
      );
    }

    return (
      <Styled.BodyCopy
        $alignment={alignment}
        aria-busy="true"
        aria-label={intl.formatMessage({
          id: 'XDS_SKELETON_GENERIC_A11Y',
          defaultMessage: 'Loading',
        })}
        className={className}
        data-xds="SkeletonBodyCopy"
        ref={forwardedRef}
        role="status"
        {...props}
      >
        {times(amountRows, (i: number) => (
          <Styled.BodyCopyRow
            $amountRows={amountRows}
            cornerRadius={cornerRadiusS}
            height={scale060}
            key={i}
          />
        ))}
      </Styled.BodyCopy>
    );
  }
);

SkeletonBodyCopy.displayName = 'SkeletonBodyCopy';
