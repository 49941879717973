import type { XingletInterface } from '../../jobs-xinglet';
import { JobsXingletWrapper } from '../../jobs-xinglet';

export type SearchAlertsSearchAnimatedBannerProps = {
  isStickyHeader: boolean;
  empty: boolean;
};

export type SearchAlertsSearchAnimatedButtonXinglet =
  XingletInterface<SearchAlertsSearchAnimatedBannerProps>;

export const SearchAlertsSearchAnimatedButton =
  JobsXingletWrapper<SearchAlertsSearchAnimatedBannerProps>(
    '@xing-com/crate-jobs-domain-search-alerts-search-animated-button'
  );
