/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationStickerPublicationSettings = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationStickerPublicationSettings"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="var(--xdlColorIllustrationBackground)"
        d="m43.192 56.19.823-4.654-3.83 1.39-.193-.528-.918.332-.465-1.277-27.714 2.218a9 9 0 0 1-.642.026c-4.118 0-7.605-3.218-7.933-7.329L.336 21.533c-.351-4.378 2.928-8.224 7.303-8.571L47.46 9.777c.212-.015.427-.027.643-.027 4.117 0 7.6 3.22 7.93 7.33l.944 11.835 2.942.521-.823 4.655 3.83-1.39.193.528.918-.332 3.65 10.045-4.87 1.768c-.004.03-.026.159-.034.193l3.967 3.332-6.876 8.182-.748-.627-.362.43-3.121-2.62-.824 4.653z"
      />
      <path
        fill="#fff"
        d="m49.478 48.7-38.62 3.09a6.124 6.124 0 0 1-6.592-5.617l-1.98-24.76a6.124 6.124 0 0 1 5.618-6.592l38.62-3.09a6.124 6.124 0 0 1 6.592 5.617l1.98 24.76a6.127 6.127 0 0 1-5.618 6.592"
      />
      <path
        fill="#00D296"
        d="m2.274 22.217-.064-.835a6.086 6.086 0 0 1 5.58-6.55l38.696-3.094a6.086 6.086 0 0 1 6.55 5.58l.068.834z"
      />
      <path
        fill="#A456F6"
        d="M59.844 44.28c.068-.382.105-.76.124-1.134l4.205-1.526-2.369-6.517L57.6 36.63a9 9 0 0 0-1.742-1.47l.778-4.4-6.826-1.21-.778 4.402c-.748.17-1.47.434-2.142.782l-3.423-2.879-4.462 5.308 3.427 2.879q-.17.54-.272 1.107c-.068.381-.106.759-.125 1.133l-4.204 1.526 2.368 6.517 4.205-1.526c.514.559 1.1 1.054 1.741 1.47l-.778 4.4 6.827 1.21.778-4.402a9 9 0 0 0 2.142-.782l3.423 2.879 4.461-5.308-3.422-2.879c.109-.359.2-.725.268-1.107m-9.456 1.907a3.53 3.53 0 0 1-2.86-4.087 3.53 3.53 0 0 1 4.088-2.86 3.526 3.526 0 1 1-1.228 6.947"
      />
      <path
        fill="#1D2124"
        d="m46.255 55.02 7.2 1.273.782-4.47a9 9 0 0 0 1.583-.535l-.706-.594a9 9 0 0 1-2.142.782l-.778 4.401-5.906-1.045zM44.995 49.384a9 9 0 0 1-.591-.586l-3.472 1.26.257.708zM44.065 32.357l2.825 2.376q.369-.19.756-.347l-3.099-2.603zM58.777 53.287l.885.743 4.704-5.599-3.472-2.92c.102-.34.181-.68.242-1.009.056-.328.098-.676.12-1.03l4.266-1.55-2.497-6.872-1.096.398 2.244 6.172-4.205 1.526a9 9 0 0 1-.124 1.134 10 10 0 0 1-.269 1.107l3.423 2.878zM58.475 36.312a9 9 0 0 0-1.306-1.05l.79-4.465-7.197-1.273-.034.189 5.908 1.046-.778 4.401a9 9 0 0 1 1.742 1.47zM51.542 46.201l-.013-.002a3.343 3.343 0 0 1-2.705-3.869 3.34 3.34 0 0 1 3.868-2.705l.023.004a3.53 3.53 0 0 0-5.187 2.47 3.53 3.53 0 0 0 4.014 4.102"
      />
      <path
        fill="#1D2124"
        d="m52.345 56.096-7.2-1.273.789-4.465a9 9 0 0 1-1.59-1.337l-4.266 1.548-2.497-6.871 4.265-1.55c.023-.354.065-.702.121-1.03q.09-.501.242-1.013l-3.476-2.92 4.711-5.595 3.472 2.916a9 9 0 0 1 1.953-.71l.79-4.465 7.197 1.273-.79 4.465c.578.382 1.11.831 1.59 1.337l4.266-1.548 2.497 6.871-4.265 1.55a10 10 0 0 1-.121 1.03c-.06.333-.14.673-.242 1.01l3.475 2.92-4.703 5.598-3.472-2.916c-.623.31-1.28.551-1.953.71zm-6.759-1.579 6.457 1.141.767-4.337.124-.026a8.8 8.8 0 0 0 2.097-.763l.113-.057 3.378 2.837 4.215-5.017-3.377-2.837.038-.12q.17-.551.268-1.085c.064-.351.102-.725.121-1.11l.008-.125 4.144-1.504-2.237-6.162-4.144 1.508-.087-.095a8.6 8.6 0 0 0-1.707-1.435l-.106-.068.767-4.337-6.456-1.145-.767 4.337-.121.027a8.8 8.8 0 0 0-2.097.763l-.113.056-3.378-2.837-4.215 5.021 3.377 2.837-.038.121q-.17.551-.268 1.084a9 9 0 0 0-.121 1.11l-.008.126-4.144 1.503 2.237 6.162 4.144-1.504.087.095a8.6 8.6 0 0 0 1.707 1.435l.106.068zm5.425-8.084q-.33 0-.653-.057a3.72 3.72 0 0 1-3.011-4.307 3.718 3.718 0 0 1 6.705-1.48c.57.812.786 1.798.612 2.776a3.7 3.7 0 0 1-1.53 2.4 3.7 3.7 0 0 1-2.123.668m-.59-.43a3.32 3.32 0 0 0 2.494-.553 3.32 3.32 0 0 0 1.375-2.153 3.32 3.32 0 0 0-.551-2.493 3.32 3.32 0 0 0-2.153-1.375 3.34 3.34 0 0 0-3.869 2.705 3.34 3.34 0 0 0 2.705 3.868"
      />
      <path
        fill="#1D2124"
        fillRule="evenodd"
        d="M5.795 19.422c.234.196.522.302.824.302q.051.001.102-.004a1.266 1.266 0 0 0 1.163-1.367 1.27 1.27 0 1 0-2.089 1.069m.756-1.855c.022-.004.049-.004.072-.004.211 0 .415.072.578.215.18.155.29.37.31.609a.89.89 0 0 1-.212.65.89.89 0 0 1-.608.31.89.89 0 0 1-.65-.213.89.89 0 0 1-.31-.608.895.895 0 0 1 .82-.96M9.864 19.467A1.265 1.265 0 0 1 8.598 18.3a1.265 1.265 0 0 1 1.164-1.368c.702-.06 1.31.469 1.367 1.164a1.27 1.27 0 0 1-1.214 1.37zm0-2.165c-.023 0-.05 0-.072.004a.89.89 0 0 0-.608.31.89.89 0 0 0-.212.65.89.89 0 0 0 .31.608.895.895 0 0 0 1.47-.748.89.89 0 0 0-.888-.824M12.285 18.904a1.27 1.27 0 0 0 .875.3l.051-.002a1.265 1.265 0 0 0 1.163-1.367 1.265 1.265 0 0 0-1.367-1.164 1.266 1.266 0 0 0-1.164 1.368c.027.336.185.646.442.865m.828-1.862a.89.89 0 0 1 .888.823.89.89 0 0 1-.212.65.89.89 0 0 1-.608.31.89.89 0 0 1-.65-.212.89.89 0 0 1-.31-.608.89.89 0 0 1 .212-.65.89.89 0 0 1 .608-.31c.023-.003.05-.003.072-.003"
        clipRule="evenodd"
      />
      <path
        fill="#1D2124"
        fillRule="evenodd"
        d="m11.723 51.909-.922.073a6.265 6.265 0 0 1-4.598-1.485 6.27 6.27 0 0 1-2.198-4.302L2.07 22.013l-.05-.616a6.28 6.28 0 0 1 5.792-6.758l38.62-3.09q.16-.014.319-.019l.806-.064a6.25 6.25 0 0 1 4.598 1.488 6.28 6.28 0 0 1 2.199 4.303l1.062 13.286-1.534-.272-.953-11.915-50.452 4.035 1.9 23.774a5.9 5.9 0 0 0 2.067 4.046 5.9 5.9 0 0 0 4.322 1.397l29.054-2.325.137.377-28.035 2.243q-.05 0-.098.003zM2.448 22.017l-.008-.094-.041-.518a5.94 5.94 0 0 1 5.406-6.385l38.7-3.093q.23-.017.461-.02c1.402.005 2.743.5 3.819 1.417a5.9 5.9 0 0 1 2.062 4.012l.045.551.008.095z"
        clipRule="evenodd"
      />
      <path
        fill="#1D2124"
        d="m42.615 49.447 2.253-.18a9 9 0 0 1-.36-.359l-.524.042z"
      />
    </svg>
  );
};
export default IllustrationStickerPublicationSettings;
