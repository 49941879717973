import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';

import { GetSimilarJobByUserIdDocument } from '@xing-com/crate-jobs-graphql';
import type { GetSimilarJobByUserIdQuery } from '@xing-com/crate-jobs-graphql';
import { useHost } from '@xing-com/crate-xinglet';

import { trendingJobsStaticData as trendingJobsStaticDataProduction } from './data/trending-jobs-static-data';
import { trendingJobsStaticData as trendingJobsStaticDataLocal } from './data/trending-jobs-static-data-local';
import { trendingJobsStaticData as trendingJobsStaticDataPreview } from './data/trending-jobs-static-data-preview';
import { trendingJobsStaticData as trendingJobsStaticDataXingbox } from './data/trending-jobs-static-data-xingbox';

const getStaticData = (
  isPreview: boolean,
  isLocalHost: boolean,
  isXingbox: boolean
): typeof trendingJobsStaticDataProduction => {
  if (isLocalHost) {
    return trendingJobsStaticDataLocal;
  } else if (isXingbox) {
    return trendingJobsStaticDataXingbox;
  } else if (isPreview) {
    return trendingJobsStaticDataPreview;
  } else {
    return trendingJobsStaticDataProduction;
  }
};

type useJobsSectionDataResult = {
  loading: boolean;
  error: any;
  data: GetSimilarJobByUserIdQuery[];
};

export const useJobsSectionData = (
  isExperimentEnabled: boolean
): useJobsSectionDataResult => {
  const client = useApolloClient();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState<GetSimilarJobByUserIdQuery[]>([]);

  const { isPreview, getHostname } = useHost();
  const hostname = getHostname();
  const isLocalHost = hostname.includes('localhost');
  const isXingbox =
    hostname.includes('kenv.xing.com') || hostname.includes('oenv.xing.com');

  const staticData = getStaticData(isPreview, isLocalHost, isXingbox);

  const jobIds = staticData.data.map((job) => String(job.id));

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const jobData: GetSimilarJobByUserIdQuery[] = [];

      try {
        const promises = jobIds.map((jobId) => {
          // Perform the Apollo GraphQL query for each ID
          return client.query({
            query: GetSimilarJobByUserIdDocument,
            variables: { jobId: jobId },
            fetchPolicy: 'cache-first',
            errorPolicy: 'all',
          });
        });

        const results = await Promise.all(promises);

        results.forEach((result) => {
          if (result.data.job?.__typename === 'VisibleJob') {
            jobData.push(result.data);
          }
        });

        setData(jobData);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (isExperimentEnabled) fetchData();
  }, [isExperimentEnabled]);

  return { loading, error, data };
};

export default useJobsSectionData;
