import * as React from 'react';
import { useIntl } from 'react-intl';

import * as Styled from './notification-badge.styles';
import type {
  ChildrenType,
  NotificationBadgeProps,
  NotificationBadgeVariant,
} from './notification-badge.types';

const getNumeric = (n: ChildrenType): number =>
  typeof n === 'number' ? n : isNaN(parseInt(n)) ? 0 : parseInt(n);

const ceilMax = (max: number) => (n: ChildrenType) => {
  const numericValue = getNumeric(n);
  const numericMax = getNumeric(max);
  return numericValue >= numericMax ? `${numericMax}+` : n;
};

export const NotificationBadge = React.forwardRef<
  HTMLAnchorElement,
  NotificationBadgeProps
>(
  (
    { children, className, maxNumber = 99, ...props },
    forwardedRef
  ): JSX.Element | null => {
    const intl = useIntl();
    let variant: NotificationBadgeVariant = 'circle';
    const value = String(ceilMax(maxNumber)(getNumeric(children ?? 0)));
    const stretches =
      typeof children === 'string'
        ? String(children).length >= 2 // If it’s a String (case of "99+")
        : children !== undefined && children >= 10;
    const cropped = parseFloat(value) === maxNumber;
    const isValueNotNull =
      children !== undefined && children !== null && parseInt(value) > 0;

    if (stretches) {
      variant = 'pill';
    }

    if (cropped) {
      variant = 'widePill';
    }

    const ariaLabel = intl.formatMessage(
      {
        id:
          parseInt(value) === 1
            ? 'XDS_NOTIFICATION_BADGE_GENERIC_A11Y.one'
            : 'XDS_NOTIFICATION_BADGE_GENERIC_A11Y.other',
        defaultMessage: '{value} new notifications',
      },
      { value: value }
    );

    if (isValueNotNull) {
      return (
        <Styled.NotificationBadge
          $variant={variant}
          aria-atomic="true"
          aria-label={ariaLabel}
          aria-live="polite"
          className={className}
          data-xds="NotificationBadge"
          ref={forwardedRef}
          role="status"
          size="small"
          {...props}
        >
          {value}
        </Styled.NotificationBadge>
      );
    } else {
      return null;
    }
  }
);

NotificationBadge.displayName = 'NotificationBadge';
