/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const LogoXINGButterflyDuo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 31"
    data-xds="LogoXINGButterflyDuo"
    role="presentation"
    {...props}
  >
    <g fillRule="evenodd" clipPath="url(#clip0_47_1357)" clipRule="evenodd">
      <path
        fill="#0698A0"
        d="m1.81 5.986 3.655 6.479L0 22.021h6.515l5.429-9.556-3.656-6.479z"
      />
      <path
        fill="#B7DF4B"
        d="M21.207 0 10.67 18.644 17.464 31h6.615l-6.905-12.356L27.713 0z"
      />
    </g>
    <defs>
      <clipPath id="clip0_47_1357">
        <path fill="#fff" d="M0 0h28v31H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default LogoXINGButterflyDuo;
