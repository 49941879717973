/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from 'react';
import { useIntl } from 'react-intl';

import * as Styled from './input-bar.styles';
import type { InputBarProps } from './input-bar.types';

export const InputBar = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputBarProps
>(
  (
    {
      className,
      clearButtonProps,
      disabled,
      icon: Icon,
      innerRef,
      multiline = false,
      onChange,
      onClearInput,
      placeholder,
      size = 'medium',
      sizeConfined,
      sizeWide,
      value,
      variant = 'default',
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();
    const [showClearButton, setShowClearButton] = React.useState(false);
    const localRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const ref = innerRef || forwardedRef || localRef;

    const handleReset = (e: React.MouseEvent<HTMLButtonElement>): void => {
      if (onChange) {
        const event = {
          target: {
            value: '',
          },
        } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
        onChange(event);
      }
      if (onClearInput) onClearInput(e);
    };

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
      if (onChange) onChange(e as any);
    };

    React.useEffect(() => {
      setShowClearButton(value.length > 0);
    }, [value]);

    const isFilled = value.length > 0;
    const iconSize = size === 'small' ? 18 : 24;

    const commonProps = {
      $isFilled: isFilled,
      $size: size,
      $sizeConfined: sizeConfined,
      $sizeWide: sizeWide,
      $variant: variant,
      'data-xds': 'InputBar',
      disabled: disabled,
      placeholder: placeholder,
      ref: ref,
      value: value,
    };

    return (
      <Styled.Wrapper className={className}>
        {multiline && (
          <Styled.Textarea onChange={onChange} {...commonProps} {...props} />
        )}
        {!multiline && (
          <Styled.Input
            $iconSize={iconSize}
            $isIconSet={Boolean(Icon)}
            $showClearButton={showClearButton}
            onChange={handleChange}
            {...commonProps}
            {...props}
          />
        )}
        {Icon && !multiline && (
          <Styled.IconContainer
            $iconSize={iconSize}
            $size={size}
            $sizeConfined={sizeConfined}
            $sizeWide={sizeWide}
            $variant={variant}
          >
            <Icon width={iconSize} height={iconSize} aria-hidden="true" />
          </Styled.IconContainer>
        )}
        {showClearButton && !multiline && (
          <Styled.ClearButton
            $variant={variant}
            aria-label={intl.formatMessage({
              id: 'XDS_INPUT_BAR_CLEAR_BUTTON_A11Y',
              defaultMessage: 'Clear input',
            })}
            disabled={disabled}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleReset(e)}
            type="reset"
            {...clearButtonProps}
          >
            <Styled.Cross
              aria-hidden="true"
              height={iconSize}
              width={iconSize}
            />
          </Styled.ClearButton>
        )}
      </Styled.Wrapper>
    );
  }
);

InputBar.displayName = 'InputBar';
