import styled from 'styled-components';

import { adSlotConfig } from '../../ad-slot-config';
import { useDefineSlot } from '../use-define-slot';

const AdContainer = styled.div`
  height: 250px;
`;

export const DisplayAdStartpageBillboard: React.FC<{
  refresh: boolean;
}> = ({ refresh }) => {
  const { id, adUnitPath, size } = adSlotConfig.Startpage.Billboard;
  useDefineSlot({ id, adUnitPath, size, refresh });

  return <AdContainer id={id} />;
};
