import classnames from 'classnames';
import type * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS does not know about CSS files
import styles from './grid.css';

export type GridType = 'standard' | 'wide' | 'armstrong';

type GridContainerProps = {
  children?: React.ReactNode | undefined;
  /** override the style of the outermost div */
  className?: string;
  /** Type of the grid container
   * `wide` renders a fluid grid with a max-width of 1356px
   * `standard` (default) renders a fluid grid with a max-width of 984px */
  type?: GridType;
  /** Layouts with side navigation behave slightly differentlty than plain pages
   *  defaults to `false` */
  withSidenav?: boolean;
  /**
   * Let media Listener report a more detailed output (tiny, small, confined, wide, xWide)
   * instead the legacy one (handheld, tablet, desktop)
   */
  detailedMediaListenerReporting?: boolean;
};

/**
 * **Grid Container** is the bbounding box around a fluid grid and
 * comes with a max width based on its `type`
 *
 * Allows adding arbitrary props that are allowed on `div` elements
 * (e.g. `id`, `style` or `title`)
 *
 * @deprecated Use `@xing-com/grid` instead
 */
export const GridContainer: React.FC<GridContainerProps> = ({
  type = 'standard',
  withSidenav = false,
  className = '',
  children,
  ...other
}) => {
  const classes = classnames(
    styles.gridContainer,
    styles[`${type}GridContainer`],
    className,
    { [styles.withSidenav]: withSidenav }
  );

  return (
    <div key="container" className={classes} {...other}>
      {children}
    </div>
  );
};

GridContainer.displayName = 'GridContainer';
