import { useEffect } from 'react';

import type { SlotType } from '../ad-slot-config';

type AdSlots = {
  [key: string]: googletag.Slot;
};

const adSlots: AdSlots = {};

export const useDefineSlot = ({
  id,
  adUnitPath,
  size,
  refresh,
}: SlotType): void => {
  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        window.googletag?.cmd.push(() => {
          googletag.pubads().refresh([adSlots[id]]);
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    window.googletag?.cmd.push(() => {
      const slot = googletag.defineSlot(adUnitPath, size, id);

      if (slot) {
        slot.addService(googletag.pubads());
        googletag.display(slot);
        adSlots[id] = slot;
      }
    });

    return () => {
      window.googletag?.cmd.push(() => {
        if (adSlots[id]) {
          googletag.destroySlots([adSlots[id]]);
          delete adSlots[id];
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
