import { BodyCopy } from '@xing-com/typography';

export const keywordRenderer =
  (keywordsInput: string) =>
  (item: string): React.ReactNode => {
    const position = item.toLowerCase().indexOf(keywordsInput.toLowerCase());

    let formattedItem: React.ReactNode = item;

    if (position >= 0) {
      const left = item.substring(0, position);
      const middle = item.substring(position, position + keywordsInput.length);
      const right = item.substring(position + keywordsInput.length);

      formattedItem = (
        <>
          {left}
          <strong>{middle}</strong>
          {right}
        </>
      );
    }

    return (
      <BodyCopy size="large" ellipsis noMargin lineHeight="dense">
        {formattedItem}
      </BodyCopy>
    );
  };
