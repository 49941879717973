import { navigate, useMatch } from '@reach/router';
import type React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  AI_FORCE_CS_ANIMATION_KEY,
  AI_FORCE_CS_ANIMATION_VALUE,
} from '@xing-com/crate-jobs-constants';
import { AiSearchBanner } from '@xing-com/crate-jobs-domain-search-ai-search-banner';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { useExperiment } from '@xing-com/hub';
import {
  IconAIProfileSearch,
  IconAIFilled,
  IconAICareerChanger,
} from '@xing-com/icons';
import { mediaConfined, mediaTopBarVisible } from '@xing-com/layout-tokens';
import { Marker } from '@xing-com/marker';
import { Tag as BaseTag } from '@xing-com/tag';
import {
  spaceM,
  spaceXL,
  spaceXS,
  xdlColorHoverSecondary,
} from '@xing-com/tokens';

type PromotionProps = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  kind: keyof typeof AI_FORCE_CS_ANIMATION_VALUE;
  tracking: { element: string; sc_o: string };
};

const Promotion: React.FC<PromotionProps> = ({
  title,
  kind,
  Icon,
  tracking,
}) => {
  const trackNwt = useJobsNwtTracking();
  const isFindJobs = useMatch(ROUTES.findJobs);

  const onClick = (): void => {
    trackNwt({
      event: 'opened',
      element: tracking.element,
      elementDetail: 'pill',
      componentName: 'ai_search',
      eventSchema: 'aicomponent',
      page: isFindJobs ? 'jobs/index' : 'jobs_search/serp',
    });

    const params = new URLSearchParams({
      [AI_FORCE_CS_ANIMATION_KEY]: AI_FORCE_CS_ANIMATION_VALUE[kind],
      sc_o: tracking.sc_o,
      sc_o_PropActionOrigin: isFindJobs
        ? 'jobs_find_jobs_pill'
        : 'jobs_serp_pill',
    });
    navigate(`${ROUTES.searchAiLanding}?${params.toString()}`);
  };

  return (
    <Tag leadingIcon={Icon} hideBorder onClick={onClick}>
      <FormattedMessage id={title} />
      <Marker icon={IconAIFilled} variant="strong" aria-hidden="true">
        New
      </Marker>
    </Tag>
  );
};

export const Promotions: React.FC = () => {
  const isAiSearchForced = useExperiment('ABACUS-468') === 'B';

  return (
    <Container>
      <Promotion
        Icon={IconAIProfileSearch}
        title="JOBS_SEARCH_AI_SWIMP_SUGGESTION"
        kind="SWIMP"
        tracking={{
          element: 'search_with_my_profile',
          sc_o: 'search_with_my_profile_click',
        }}
      />
      <Promotion
        Icon={IconAICareerChanger}
        title="JOBS_SEARCH_AI_SWIMS_SUGGESTION"
        kind="SWIMS"
        tracking={{
          element: 'search_with_my_skills',
          sc_o: 'search_with_my_skills_click',
        }}
      />
      {isAiSearchForced ? null : (
        <Banner variant="xsmall" propActionOrigin="jobs_find_jobs" />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: ${spaceM};
  margin-top: ${spaceXL};
  padding: 0 20px;
  overflow: scroll;
  scrollbar-width: none;

  @media ${mediaConfined} {
    justify-content: center;
  }
`;

const Tag = styled(BaseTag)`
  width: fit-content;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${xdlColorHoverSecondary};
  }
  // needed to target the tag text content
  & div > span {
    display: flex;
    align-items: center;
    gap: ${spaceXS};
  }
`;

const Banner = styled(AiSearchBanner)`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;
