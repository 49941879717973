/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconArrowRight"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.707 6.293 20.414 12l-5.707 5.707-1.414-1.414L16.585 13H4v-2h12.585l-3.292-3.293z"
    />
  </svg>
);
export default IconArrowRight;
