import * as React from 'react';
import { useIntl } from 'react-intl';

import * as Styled from './discover-badge.styles';
import type { DiscoverBadgeProps } from './discover-badge.types';

export const DiscoverBadge = React.forwardRef<
  HTMLSpanElement,
  DiscoverBadgeProps
>(({ className, role = 'status', ...props }, forwardedRef): JSX.Element => {
  const intl = useIntl();

  return (
    <Styled.DiscoverBadge
      aria-label={intl.formatMessage({
        id: 'XDS_DISCOVER_BADGE_A11Y',
        defaultMessage: 'New feature',
      })}
      aria-live="polite"
      className={className}
      data-xds="DiscoverBadge"
      ref={forwardedRef}
      role={role}
      {...props}
    />
  );
});

DiscoverBadge.displayName = 'DiscoverBadge';
