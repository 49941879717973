import debounce from 'lodash/debounce';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { ContentSwitcherButton } from '../content-switcher-button';
import { ContentSwitcherMore } from '../content-switcher-more';

import * as Styled from './content-switcher.styles';
import type { ContentSwitcherProps } from './content-switcher.types';

export const ContentSwitcher = React.forwardRef<
  HTMLElement,
  ContentSwitcherProps
>(
  (
    {
      activeItemIndex = -1,
      allowDropdown = true,
      className,
      data = [],
      isFixed = false,
      moreButtonProps,
      size = 'medium',
      sizeConfined,
      sizeWide,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();
    const ref = React.useRef<HTMLUListElement>(null);
    const [firstIndexDropdown, setFirstIndexDropdown] = React.useState(-1);

    /* Data on the second row (hidden) should go to the dropdown */
    const handleOverflow = debounce(() => {
      if (allowDropdown && ref.current) {
        const children = ref.current.children;
        let foundIndex = -1;

        for (let i = 0; i < children.length && foundIndex < 0; i++) {
          const item = children[i];

          if (item !== null && item instanceof HTMLElement) {
            if (item.offsetTop > 0) {
              foundIndex = i;
            }
          }
        }

        setFirstIndexDropdown(foundIndex);
      }
    }, 150);

    React.useEffect(() => {
      window.addEventListener('resize', handleOverflow);
      handleOverflow();

      return () => {
        window.removeEventListener('resize', handleOverflow);
      };
    }, [size, sizeConfined, sizeWide, data, allowDropdown, handleOverflow]);

    const moreData = data.filter(
      (_, index) => firstIndexDropdown >= 0 && index >= firstIndexDropdown
    );

    return (
      <Styled.ContentSwitcher
        $size={size}
        $sizeConfined={sizeConfined}
        $sizeWide={sizeWide}
        className={className}
        data-xds="ContentSwitcher"
        ref={forwardedRef}
        role="navigation"
        {...props}
      >
        <Styled.ButtonList
          $noDropdown={!allowDropdown}
          $size={size}
          $sizeConfined={sizeConfined}
          $sizeWide={sizeWide}
          aria-orientation="horizontal"
          ref={ref}
          role="tablist"
        >
          {data.map((item, index) => (
            <li
              data-qa={`menu-item-${index}`}
              data-testid={`menu-item-${index}`}
              key={index}
              role="none"
            >
              <ContentSwitcherButton
                buttonListRef={ref}
                selected={index === activeItemIndex}
                size={size}
                sizeConfined={sizeConfined}
                sizeWide={sizeWide}
                variant={variant}
                {...item}
              >
                {item.copy}
              </ContentSwitcherButton>
            </li>
          ))}
        </Styled.ButtonList>
        {moreData.length > 0 && (
          <ContentSwitcherMore
            buttonListRef={ref}
            data={moreData}
            isFixed={isFixed}
            selected={activeItemIndex >= firstIndexDropdown}
            size={size}
            sizeConfined={sizeConfined}
            sizeWide={sizeWide}
            variant={variant}
            {...moreButtonProps}
          >
            {activeItemIndex >= firstIndexDropdown && data[activeItemIndex]
              ? data[activeItemIndex].copy
              : intl.formatMessage({
                  id: 'CONTENT_SWITCHER_MORE',
                  defaultMessage: 'More',
                })}
          </ContentSwitcherMore>
        )}
      </Styled.ContentSwitcher>
    );
  }
);

ContentSwitcher.displayName = 'ContentSwitcher';
