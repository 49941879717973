import { useState, useEffect } from 'react';

import {
  mediaSmallOrTiny,
  mediaConfinedOnly,
  mediaWide,
} from '@xing-com/layout-tokens';

export const BREAKPOINTS = {
  handheld: 'handheld',
  tablet: 'tablet',
  desktop: 'desktop',
} as const;

type Breakpoint = keyof typeof BREAKPOINTS;

const breakpointRanges: [Breakpoint, string][] = [
  ['handheld', mediaSmallOrTiny],
  ['tablet', mediaConfinedOnly],
  ['desktop', mediaWide],
];

const fallbackBreakpoint: Breakpoint = BREAKPOINTS.handheld;

let currentBreakpoint: Breakpoint = fallbackBreakpoint;
const listeners: ((name: Breakpoint) => void)[] = [];

if (typeof window !== 'undefined' && typeof window.matchMedia === 'function') {
  for (const [name, media] of breakpointRanges) {
    const mql = window.matchMedia(media);

    if (mql.matches) currentBreakpoint = name;

    const eventListener = ({ matches }: MediaQueryListEvent): void => {
      if (matches) {
        currentBreakpoint = name;
        listeners.forEach((listener) => listener(name));
      }
    };

    if (mql.addEventListener) {
      mql.addEventListener('change', eventListener);
    } else if (mql.addListener) {
      // addListener deprecated but still needed by Safari < 14
      mql.addListener(eventListener);
    }
  }
}

export const useMediaListener = (): Breakpoint => {
  const [matchedMedia, setMatchedMedia] =
    useState<Breakpoint>(fallbackBreakpoint);

  useEffect(() => {
    setMatchedMedia(currentBreakpoint);
    listeners.push(setMatchedMedia);
    return () => {
      listeners.splice(listeners.indexOf(setMatchedMedia), 1);
    };
  }, [setMatchedMedia]);

  return matchedMedia;
};
