import styled, { css } from 'styled-components';

import { AutoComplete } from '@xing-com/auto-complete';
import { Button } from '@xing-com/button';
import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaTopBarVisible,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import {
  scale080,
  scale100,
  scale150,
  scale180,
  space5XL,
  spaceL,
  spaceM,
  spaceXXS,
  xdlColorBorderSoft,
  xdlColorControlFillDark,
  xdlColorTextOnDark,
  xdlPaletteWhite,
} from '@xing-com/tokens';

type CommonProps = {
  $condensedOnMobile?: boolean;
};

export const Form = styled.form<CommonProps>`
  margin-left: 20px;
  margin-right: 20px;
  justify-items: stretch;
  align-items: center;
  gap: ${spaceL};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'key key'
    'loc but';

  @media ${mediaWideNavless} {
    gap: 0;
    grid-template-columns: 1.4fr 1fr;
    grid-template-areas: 'key loc';
    position: relative;
    margin-left: 0;
    margin-right: 0;
  }

  ${({ $condensedOnMobile }) =>
    $condensedOnMobile &&
    css`
      @media ${mediaSmallOrTiny} {
        grid-template-columns: auto;
        grid-template-areas:
          'key'
          'loc';
      }

      @media ${mediaTopBarVisible} {
        gap: ${spaceM};
      }
    `};
`;

const sharedStyles = css<CommonProps>`
  & input {
    // Disable browser native "X" button in the input field
    &::-webkit-search-cancel-button {
      display: none;
    }

    // Hide the clear button when the input is not focused or hovered
    &:not(:focus, :hover) ~ button[type='reset']:not(:focus, :hover) {
      color: transparent;
    }

    @media ${mediaWideNavless} {
      height: ${space5XL};
      border-radius: 45px;
      font-size: ${scale100};

      &::placeholder {
        font-size: ${scale100};
      }
    }

    @media ${mediaTopBarVisible} {
      ${({ $condensedOnMobile }) =>
        $condensedOnMobile &&
        css`
          height: ${scale150};
        `}
    }
  }
`;

export const KeywordsAutoComplete = styled(AutoComplete)<CommonProps>`
  grid-area: key;
  ${sharedStyles}

  @media ${mediaWideNavless} {
    & input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid ${xdlColorBorderSoft};
    }
  }
`;

export const LocationAutoComplete = styled(AutoComplete)<{
  $withFilters?: boolean;
  $condensedOnMobile?: boolean;
}>`
  && {
    grid-area: loc;
    ${sharedStyles}

    @media ${mediaWideNavless} {
      & input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-right: 115px;
      }

      // Move clear button to the left of the submit button
      & button[type='reset'] {
        height: 88px;
        top: ${spaceXXS};
        right: 78px;
        background-color: ${xdlPaletteWhite};
      }
    }

    @media ${mediaTopBarVisible} {
      & ul {
        width: ${({ $withFilters, $condensedOnMobile }) =>
          $withFilters || $condensedOnMobile
            ? '100%'
            : `calc(100% + ${spaceL} + ${scale180})`};
      }
    }
  }
`;

export const SubmitButton = styled(Button)<CommonProps>`
  grid-area: but;
  flex-shrink: 0;
  height: ${scale180};
  width: ${scale180};
  display: ${({ $condensedOnMobile }) =>
    $condensedOnMobile ? 'none' : 'block'};

  // Force black background color on press
  &:active {
    color: ${xdlColorTextOnDark};

    &::after {
      background-color: ${xdlColorControlFillDark};
    }
  }

  @media ${mediaConfined} {
    display: block;
  }

  @media ${mediaWideNavless} {
    position: absolute;
    right: 20px;
  }

  @media ${mediaTopBarVisible} {
    ${({ $condensedOnMobile }) =>
      $condensedOnMobile &&
      css`
        height: ${scale150};
        width: ${scale150};
        font-size: ${scale080};
      `}
  }
`;
