import { navigate } from '@reach/router';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { AI_SEARCH_AUTOFOCUS_PARAM } from '@xing-com/crate-jobs-constants';
import { Promotions } from '@xing-com/crate-jobs-domain-search-components-search-box/src/promotions';
import { useAnimatedPlaceholder } from '@xing-com/crate-jobs-domain-search-conversational-search-bar';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { IconSearch } from '@xing-com/icons';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { InputBar } from '@xing-com/text-field';
import { LightMode } from '@xing-com/theme-mode-switcher';
import { scale080, scale090, spaceL } from '@xing-com/tokens';

type Props = {
  withPromotions?: boolean;
};

export const FakeInput: React.FC<Props> = ({ withPromotions }) => {
  const { formatMessage } = useIntl();
  const isSmallScreen = useMatchMedia(mediaSmallOrTiny);
  const inputRef = useRef(null);
  const runPlaceholderAnimation = useAnimatedPlaceholder(inputRef);

  useEffect(() => {
    runPlaceholderAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToAiSearch = (): void => {
    navigate(`${ROUTES.searchAiLanding}?${AI_SEARCH_AUTOFOCUS_PARAM}=true`);
  };

  return (
    <LightMode>
      <Button
        onClick={navigateToAiSearch}
        tabIndex={0}
        aria-label={formatMessage({ id: 'JOBS_SEARCH_AI_ENTRYPOINT_BANNER' })}
      >
        <Input
          icon={isSmallScreen ? undefined : IconSearch}
          size="xlarge"
          variant="plain"
          value=""
          ref={inputRef}
          aria-hidden="true"
          tabIndex={-1}
        />
      </Button>
      {withPromotions ? <Promotions /> : null}
    </LightMode>
  );
};

const Button = styled.button`
  display: block;
  margin: 0 auto;
  padding: 0 ${spaceL};
  max-width: 648px;
  width: 100%;
  border: none;
  background: transparent;

  @media ${mediaConfined} {
    padding: 0;
  }
`;

const Input = styled(InputBar)`
  & input {
    cursor: pointer;
    transition: border 0.18s ease-in-out;

    &::placeholder {
      font-size: ${scale080};
    }

    @media ${mediaConfined} {
      height: 90px;
      border-radius: 45px;

      &::placeholder {
        font-size: ${scale090};
      }
    }
  }
`;
