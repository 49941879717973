import { useEffect, useState } from 'react';

export type NoSSRProps = React.PropsWithChildren<{
  fallback?: React.ReactNode;
}>;

export const NoSSR: React.FC<NoSSRProps> = ({ children, fallback }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  return <>{mounted ? children : fallback}</>;
};
