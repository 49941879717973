/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useId } from '@react-aria/utils';
import * as React from 'react';

import * as Styled from './dropdown.styles';
import type { DropdownProps } from './dropdown.types';

export const Dropdown = React.forwardRef<HTMLSelectElement, DropdownProps>(
  (
    {
      children,
      className,
      id,
      disabled,
      error,
      helperText = '',
      onChange,
      placeholder = '',
      size = 'medium',
      success,
      value,
      variant,
      innerRef,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const idRef = useId(id);
    const helperTextId = useId();

    const combinedRef = React.useCallback(
      (node: HTMLSelectElement | null) => {
        if (innerRef) {
          if (
            typeof (innerRef as React.MutableRefObject<unknown>).current !==
            'undefined'
          ) {
            (
              innerRef as React.MutableRefObject<HTMLSelectElement | null>
            ).current = node;
          }
        }

        if (typeof forwardedRef === 'function') {
          forwardedRef(node);
        } else if (forwardedRef) {
          forwardedRef.current = node;
        }
      },
      [forwardedRef, innerRef]
    );

    return (
      <Styled.Wrapper className={className}>
        <Styled.DropdownWrapper>
          <Styled.Dropdown
            $error={error}
            $isDisabled={disabled}
            $isFilled={Boolean(value)}
            $size={size}
            $success={success}
            $variant={variant}
            aria-describedby={helperText ? helperTextId : undefined}
            aria-disabled={disabled}
            data-qa="dropdown"
            data-testid="dropdown"
            data-xds="Dropdown"
            disabled={disabled}
            id={idRef}
            onChange={onChange}
            ref={combinedRef}
            value={value}
            {...props}
          >
            {placeholder && !value && (
              <Styled.Placeholder value="">{placeholder}</Styled.Placeholder>
            )}
            {children}
          </Styled.Dropdown>
          <Styled.Icon
            $isDisabled={disabled}
            $isFilled={Boolean(value)}
            $size={size}
            aria-hidden="true"
          />
          {placeholder && (
            <Styled.Label
              $isDisabled={disabled}
              $isFilled={Boolean(value)}
              $size={size}
              htmlFor={idRef}
            >
              <span aria-label={placeholder}>{placeholder}</span>
            </Styled.Label>
          )}
        </Styled.DropdownWrapper>

        {helperText && (
          <Styled.HelperText
            $error={error}
            $isDisabled={disabled}
            id={helperTextId}
            size="xsmall"
            role="status"
          >
            {helperText}
          </Styled.HelperText>
        )}
      </Styled.Wrapper>
    );
  }
);

Dropdown.displayName = 'Dropdown';
