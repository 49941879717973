/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const LogoXINGButterflyInvert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 31"
    data-xds="LogoXINGButterflyInvert"
    role="presentation"
    {...props}
  >
    <g fillRule="evenodd" clipPath="url(#clip0_47_1363)" clipRule="evenodd">
      <path
        fill="#fff"
        d="m1.83 5.984 3.688 6.479L0 22.018h6.585l5.479-9.555-3.688-6.48z"
      />
      <path
        fill="#B7DF4B"
        d="M21.428 0 10.786 18.642 17.648 31h6.678l-6.98-12.358L28 0z"
      />
    </g>
    <defs>
      <clipPath id="clip0_47_1363">
        <path fill="#fff" d="M0 0h28v31H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default LogoXINGButterflyInvert;
