import styled, { css } from 'styled-components';

import { spaceS } from '@xing-com/tokens';

import { SkeletonRectangle } from '../';

import type { SkeletonBodyCopyAlignment } from './skeleton-body-copy.types';

type BodyCopyTransientProps = {
  $alignment?: SkeletonBodyCopyAlignment;
};

type BodyCopyRowTransientProps = {
  $amountRows: number;
};

// *** Text Alignments ***
export const alignments = {
  left: css`
    justify-content: flex-start;
  `,
  center: css`
    justify-content: center;
  `,
  right: css`
    justify-content: flex-end;
  `,
};

// *** Components ***
export const BodyCopy = styled.div<BodyCopyTransientProps>`
  display: flex;
  flex-wrap: wrap;
  ${({ $alignment }) => $alignment && alignments[$alignment]};
`;

export const BodyCopyRow = styled(SkeletonRectangle)<BodyCopyRowTransientProps>`
  margin-block-end: ${spaceS};

  ${({ $amountRows }) =>
    $amountRows > 1 &&
    css`
      &:last-child {
        width: 66%;
      }
    `};

  &:last-child {
    margin-block-end: 0;
  }
`;
