import styled, { css } from 'styled-components';

import { IconArrowDown } from '@xing-com/icons';
import {
  cornerRadiusM,
  scale005,
  scale010,
  scale050,
  scale060,
  scale070,
  scale080,
  scale150,
  scale160,
  scale170,
  scale100,
  scale110,
  spaceL,
  spaceM,
  spaceXS,
  spaceXXL,
  spaceXXS,
  xdlColorBackground,
  xdlColorBackgroundTertiary,
  xdlColorBorderStrong,
  xdlColorControlBorder,
  xdlColorDisabledSoft,
  xdlColorDisabledStrong,
  xdlColorError,
  xdlColorSuccess,
  xdlColorText,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { Meta } from '@xing-com/typography';

import type { DropdownSize, DropdownVariant } from './dropdown.types';
import { Option as OptionXdl } from './option';

type DropdownTProps = {
  $error?: boolean;
  $isDisabled?: boolean;
  $isFilled?: boolean;
  $size: DropdownSize;
  $success?: boolean;
  $variant?: DropdownVariant;
};

type LabelTProps = {
  $isDisabled?: boolean;
  $isFilled: boolean;
  $size: DropdownSize;
};

type HelperTextTProps = {
  $error?: boolean;
  $isDisabled?: boolean;
};

type IconTProps = {
  $isDisabled?: boolean;
  $isFilled?: boolean;
  $size: DropdownSize;
};

export const Wrapper = styled.div`
  width: 100%;
`;

export const DropdownWrapper = styled.div`
  position: relative;
`;

const styles = css`
  background: ${xdlColorBackgroundTertiary};
  border-bottom: ${spaceXXS} solid ${xdlColorControlBorder};
  border-left: ${scale005} solid ${xdlColorBackgroundTertiary};
  border-radius: ${cornerRadiusM} ${cornerRadiusM} 0 0;
  border-right: ${scale005} solid ${xdlColorBackgroundTertiary};
  border-top: ${scale005} solid ${xdlColorBackgroundTertiary};
  color: ${xdlColorText};
  display: inline-block;
  outline: none;
  padding-left: ${spaceL};
  padding-right: ${spaceM};
  width: 100%;

  & option {
    color: ${xdlColorText} !important;
  }

  &:focus {
    && {
      background-color: ${xdlColorBackground};
      border-bottom: ${spaceXXS} solid ${xdlColorBorderStrong};
      border-left: ${scale005} solid ${xdlColorControlBorder};
      border-right: ${scale005} solid ${xdlColorControlBorder};
      border-top: ${scale005} solid ${xdlColorControlBorder};
    }
  }

  &:hover {
    border-bottom: ${spaceXXS} solid ${xdlColorBorderStrong};
  }
`;

// *** Sizes ***
export const sizes = {
  small: {
    dropdown: css`
      font-size: ${scale070};
      height: ${scale150};
      padding-bottom: 5px;
      padding-top: 5px;
    `,
    placeholder: css`
      font-size: ${scale070};
    `,
    label: css`
      font-size: ${scale070};
      top: ${spaceM};
    `,
  },
  medium: {
    dropdown: css`
      font-size: ${scale080};
      height: ${scale160};
      padding-bottom: ${scale050};
      padding-top: ${scale050};
    `,
    placeholder: css`
      font-size: ${scale080};
    `,
    label: css`
      font-size: ${scale080};
      top: ${scale070};
    `,
  },
  large: {
    dropdown: css`
      font-size: ${scale080};
      height: ${scale170};
      padding-bottom: ${scale060};
      padding-top: ${scale060};
    `,
    placeholder: css`
      font-size: ${scale080};
    `,
    label: css`
      font-size: ${scale080};
      top: ${spaceL};
    `,
  },
};

// *** State styles ***
const filledStyle = css`
  background-color: ${xdlColorBackground};
  border-bottom: ${scale010} solid ${xdlColorControlBorder};
  border-left: ${scale005} solid ${xdlColorControlBorder};
  border-right: ${scale005} solid ${xdlColorControlBorder};
  border-top: ${scale005} solid ${xdlColorControlBorder};
`;

const disabledStyle = css`
  border-bottom-width: ${scale010};
  border-color: ${xdlColorDisabledSoft};
  border-style: solid;
  color: ${xdlColorDisabledStrong};

  &:hover,
  &:focus {
    border-color: ${xdlColorDisabledSoft};
  }
`;

const disabledUnfilledStyle = css`
  background-color: ${xdlColorDisabledSoft};
  border: none;

  &:hover {
    border: none;
  }
`;

const disabledPlainStyle = css`
  background-color: ${xdlColorBackground};
  border-bottom: ${scale010} solid ${xdlColorDisabledSoft};
  border-left: ${scale005} solid ${xdlColorDisabledSoft};
  border-right: ${scale005} solid ${xdlColorDisabledSoft};
  border-top: ${scale005} solid ${xdlColorDisabledSoft};
`;

const errorStyle = css`
  border-bottom: 2px solid ${xdlColorError};

  &:hover,
  &:focus {
    border-bottom: ${scale010} solid ${xdlColorError};
  }
`;

const successStyle = css`
  border-bottom: ${spaceXXS} solid ${xdlColorSuccess};

  &:hover,
  &:focus {
    border-bottom: ${spaceXXS} solid ${xdlColorSuccess};
  }
`;

const filledStyleLabel = css`
  background: ${xdlColorBackground};
  font-size: ${scale060};
  height: 12px;
  left: 13px;
  padding: 0 ${spaceXS} 0 ${spaceXS};
  top: -9px;
  transform: none;
`;

const placeholderStyle = css<DropdownTProps>`
  color: transparent;
  font-weight: 400;
  ${({ $size }) => $size && sizes[$size].placeholder};
`;

export const Label = styled.label<LabelTProps>`
  bottom: 0;
  color: ${xdlColorTextSecondary};
  font-weight: 400;
  left: 17px;
  pointer-events: none;
  position: absolute;

  ${({ $size }) => $size && sizes[$size].label};
  ${({ $isFilled }) => $isFilled && filledStyleLabel};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: ${xdlColorDisabledStrong};
    `};

  ${({ $isDisabled, $isFilled }) =>
    $isDisabled &&
    !$isFilled &&
    css`
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    `};
`;

export const Dropdown = styled.select<DropdownTProps>`
  ${styles};

  ${({ $variant }) =>
    $variant === 'plain' &&
    css`
      background-color: ${xdlColorBackground};

      &:focus {
        background: ${xdlColorBackground};
      }
    `};

  appearance: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: calc(${spaceXXL} + ${scale100});

  ${({ $size }) => $size && sizes[$size].dropdown};

  ${({ $isFilled }) => !$isFilled && placeholderStyle};
  ${({ $isFilled }) => $isFilled && filledStyle};
  ${({ $isFilled, $variant }) =>
    $isFilled &&
    $variant === 'plain' &&
    css`
      background-color: ${xdlColorBackground};
    `};

  ${({ $success }) => $success && successStyle};
  ${({ $error }) => $error && errorStyle};

  ${({ $isDisabled }) => $isDisabled && disabledStyle};
  ${({ $isDisabled, $variant }) =>
    $isDisabled && $variant === 'plain' && disabledPlainStyle};

  ${({ $isDisabled, $isFilled }) =>
    $isDisabled && !$isFilled && disabledUnfilledStyle};
  ${({ $isDisabled, $isFilled, $variant }) =>
    $isDisabled && !$isFilled && $variant === 'plain' && disabledPlainStyle};

  &:-ms-expand {
    display: none;
  }

  & option {
    color: initial;
  }
`;

export const Placeholder = styled(OptionXdl)``;

export const Icon = styled(IconArrowDown)<IconTProps>`
  color: ${xdlColorTextSecondary};
  pointer-events: none;
  position: absolute;
  right: 0;
  right: ${scale100};
  top: 50%;

  ${({ $size }) =>
    $size === 'small'
      ? css`
          height: ${scale100};
          margin-top: calc(${scale100} / -2);
          width: ${scale100};
        `
      : css`
          height: ${scale110};
          margin-top: calc(${scale110} / -2);
          width: ${scale110};
        `};

  ${({ $isFilled }) =>
    $isFilled &&
    css`
      color: ${xdlColorText};
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: ${xdlColorDisabledStrong};
    `};
`;

export const HelperText = styled(Meta)<HelperTextTProps>`
  margin-top: 0;
  transform: translateY(0);

  ${({ $error }) =>
    $error &&
    css`
      && {
        color: ${xdlColorError};
      }
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      && {
        color: ${xdlColorDisabledStrong};
      }
    `}
`;
