import styled from 'styled-components';

import { RECOMMENDATIONS_COUNT } from '@xing-com/crate-jobs-constants';
import { GridContainer, GridItem } from '@xing-com/grid';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { SkeletonHeadline } from '@xing-com/skeleton';

import { JobTeaserCardSkeleton } from '../../../components/job-teaser-card';
import { JobTeaserListItemSkeleton } from '../../../components/job-teaser-list-item';

import { PreferencesSkeleton } from './preferences-skeleton';

const Headline = styled(SkeletonHeadline)`
  width: 50%;
`;

const TeaserListItem = styled(JobTeaserListItemSkeleton)`
  @media ${mediaConfined} {
    display: none;
  }
`;

const TeaserCard = styled(JobTeaserCardSkeleton)`
  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;

type Props = {
  hideHeadline?: boolean;
};

export const RecommendationsSkeleton: React.FC<Props> = ({
  hideHeadline = false,
}) => {
  return (
    <div>
      {hideHeadline ? null : (
        <>
          <GridItem>
            <Headline size="medium" amountRows={1} />
          </GridItem>
          <PreferencesSkeleton />
        </>
      )}
      <GridContainer variant="FullSize">
        {[...Array(RECOMMENDATIONS_COUNT)].map((_, i) => (
          <GridItem
            key={i}
            spanTiny={12}
            spanMedium={6}
            spanLarge={4}
            data-testid="recommendation-skeleton"
          >
            <TeaserListItem />
            <TeaserCard />
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};
