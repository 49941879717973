import styled, { css } from 'styled-components';

import { IconAIFilled } from '@xing-com/icons';
import { mediaTopBarHidden, mediaTopBarVisible } from '@xing-com/layout-tokens';
import {
  scale110,
  scale130,
  scale180,
  spaceM,
  spaceS,
  spaceXS,
  spaceXXL,
  xdlColorTextOnLight,
} from '@xing-com/tokens';
import { Hero } from '@xing-com/typography';

type StyleProps = { $isLanding?: boolean };

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 800px;

  @media ${mediaTopBarHidden} {
    max-width: 648px;
    min-height: 371px;
  }
`;

export const sharedStyles = (props: StyleProps): ReturnType<typeof css> =>
  props.$isLanding
    ? css`
        text-align: center;
        justify-content: center;
      `
    : css`
        @media ${mediaTopBarVisible} {
          display: none;
        }
      `;

export const FlagsContainer = styled.div<StyleProps>`
  ${sharedStyles}
  display: flex;
  margin-bottom: ${spaceM};
  gap: ${spaceXS};
`;

export const Title = styled(Hero)<StyleProps>`
  ${sharedStyles}
  margin-bottom: ${spaceXXL};
  color: ${xdlColorTextOnLight};
  text-wrap: balance;

  @media ${mediaTopBarVisible} {
    margin-inline: 20px;
  }

  ${({ $isLanding }) =>
    $isLanding &&
    css`
      font-size: ${scale130};
      line-height: 1.2;

      @media ${mediaTopBarHidden} {
        font-size: ${scale180};
        line-height: 1.1;
      }
    `}
`;

export const Icon = styled(IconAIFilled)<StyleProps>`
  transform: translateY(-${spaceS});
  height: ${scale110};

  @media ${mediaTopBarHidden} {
    height: ${scale130};
  }

  ${({ $isLanding }) =>
    $isLanding &&
    css`
      @media ${mediaTopBarHidden} {
        transform: translateY(-18px);
      }
    `}
`;
