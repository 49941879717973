import * as React from 'react';
import { useIntl } from 'react-intl';

import { SkeletonBodyCopy } from '../';

import * as Styled from './skeleton-profile-info.styles';
import type { SkeletonProfileInfoProps } from './skeleton-profile-info.types';

export const SkeletonProfileInfo = React.forwardRef<
  HTMLDivElement,
  SkeletonProfileInfoProps
>(
  (
    { className, variant = 'horizontal', ...props },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();

    return (
      <Styled.ProfileInfoContainer
        $variant={variant}
        aria-busy="true"
        aria-label={intl.formatMessage({
          id: 'XDS_SKELETON_GENERIC_A11Y',
          defaultMessage: 'Loading',
        })}
        className={className}
        ref={forwardedRef}
        role="status"
        {...props}
      >
        <Styled.ProfileInfoImage $variant={variant} size="medium" />
        <Styled.ProfileInfoContent>
          <Styled.ProfileInfoHeadline amountRows={1} size="medium" />
          <SkeletonBodyCopy amountRows={variant === 'horizontal' ? 2 : 3} />
        </Styled.ProfileInfoContent>
      </Styled.ProfileInfoContainer>
    );
  }
);

SkeletonProfileInfo.displayName = 'SkeletonProfileInfo';
