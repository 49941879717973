import type * as React from 'react';
import type { TextareaAutosizeProps } from 'react-textarea-autosize';

import * as Styled from './form-field.styles';
const { sizes } = Styled;

export type FormFieldSize = keyof typeof sizes;
export type FormFieldVariant = 'plain';

type CommonProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Area for a character counter component */
  characterCounter?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Disable the input or textarea */
  disabled?: boolean;
  /** Set error state */
  error?: boolean;
  /** Set helper text */
  helperText?: string;
  /** The id attribute of the input or textarea */
  id?: string;
  /** The label attribute for the input element */
  label?: string;
  /** Set maximum characters of internal character counter */
  maxCharacterCount?: number;
  /** Show internal character counter */
  showCharacterCounter?: boolean;
  /** The size on mobile screens or larger */
  size?: FormFieldSize;
  /** The size on tablet screens or larger */
  sizeConfined?: FormFieldSize;
  /** The size on desktop screens or larger */
  sizeWide?: FormFieldSize;
  /** Set success state */
  success?: boolean;
  /** The value attribute of the input or textarea */
  value?: string;
  /** The variant of the form-field */
  variant?: FormFieldVariant;
};

type InputProps = CommonProps & {
  /** Allow action items like a button to be added to the end */
  action?: React.ReactNode;
  /** Add leading icon */
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  /** @deprecated Please use the "ref" property instead */
  innerRef?: React.Ref<HTMLInputElement>;
  /** Render a textarea */
  multiline?: never;
  /** @callback */
  onBlur?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /** @callback */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** @callback */
  onFocus?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /** The type of the input element */
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;

type TextareaProps = CommonProps & {
  /** Allow action items like a button to be added to the end */
  action?: never;
  /** Add leading icon */
  icon?: never;
  /** @deprecated Please use the "ref" property instead */
  innerRef?: React.Ref<HTMLTextAreaElement>;
  /** Render a textarea */
  multiline?: boolean;
  /** @callback */
  onBlur?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  /** @callback */
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /** @callback */
  onFocus?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
} & React.HTMLAttributes<HTMLTextAreaElement & TextareaAutosizeProps>;

export type FormFieldProps = InputProps | TextareaProps;
