import { CarouselSection } from '@xing-com/crate-x4e-carousel-section';
import { useExperiment } from '@xing-com/hub';
import { Container } from '@xing-com/layout-primitives';

import { StyledCarouselSection } from '../startpage-logged-out.styles';

import { Card, CardSkeleton } from './card';
import { useJobsSectionData } from './use-jobs-section-data';

export const JobsSection: React.FC = () => {
  const isExperimentEnabled = useExperiment('ABACUS-448') === 'B';
  const { data, loading, error } = useJobsSectionData(isExperimentEnabled);

  if (!isExperimentEnabled || error) {
    return null;
  }
  return (
    <StyledCarouselSection>
      <Container variant="expanded">
        <CarouselSection
          titleKey={'LOSP_JOBS_SECTION_TITLE'}
          subtitleKey={'LOSP_JOBS_SECTION_SUBTITLE'}
          itemWidth="304px"
          itemMinWidth="304px"
          itemMaxWidth="304px"
          useFullWidth={true}
        >
          {loading || data.length === 0
            ? Array.from({ length: 4 }).map((_, index) => (
                <CardSkeleton key={`skeleton${index}`} />
              ))
            : data?.map(
                (item) =>
                  item.job?.__typename === 'VisibleJob' && (
                    <Card
                      key={item.job.id}
                      job={item.job}
                      matchingHighlights={item.job.matchingHighlights}
                    />
                  )
              )}
        </CarouselSection>
      </Container>
    </StyledCarouselSection>
  );
};
