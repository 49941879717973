import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { JobsCountDocument } from './queries/jobs-count.query.gql-types';
import type { JobsCountQuery } from './queries/jobs-count.query.gql-types';

export function useJobsCount(): {
  loading: boolean;
  jobsCount?: JobsCountQuery;
} {
  const { data, loading } = useQuery(JobsCountDocument);
  const result = useMemo(() => {
    if (loading || !data) return { loading };

    return {
      loading,
      jobsCount: data,
    };
  }, [loading, data]);

  return result;
}
