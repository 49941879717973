import type * as React from 'react';

import * as Styled from './skeleton-body-copy.styles';
const { alignments } = Styled;

export type SkeletonBodyCopyVariant = 'primary' | 'secondary';
export type SkeletonBodyCopyAlignment = keyof typeof alignments;

export type SkeletonBodyCopyProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Alignment of the skeleton body text */
  alignment?: SkeletonBodyCopyAlignment;
  /** Amount of body copy rows */
  amountRows?: number;
  /** Add custom CSS */
  className?: string;
  /** @deprecated: variant secondary is deprecated */
  variant?: SkeletonBodyCopyVariant;
} & React.HTMLAttributes<HTMLDivElement>;
