import * as React from 'react';
import { useIntl } from 'react-intl';

import { cornerRadiusM, scale150, scale160, scale170 } from '@xing-com/tokens';

import type { SkeletonFormFieldProps } from '..';

import * as Styled from './skeleton-form-field.styles';

export const SkeletonFormField = React.forwardRef<
  HTMLDivElement,
  SkeletonFormFieldProps
>(({ className, size = 'medium', ...props }, forwardedRef): JSX.Element => {
  const intl = useIntl();
  const sizes = {
    small: scale150,
    medium: scale160,
    large: scale170,
  };

  return (
    <Styled.FormField
      aria-busy="true"
      aria-label={intl.formatMessage({
        id: 'XDS_SKELETON_GENERIC_A11Y',
        defaultMessage: 'Loading',
      })}
      className={className}
      cornerRadius={cornerRadiusM}
      data-xds="SkeletonFormField"
      height={sizes[size]}
      ref={forwardedRef}
      role="status"
      {...props}
    />
  );
});

SkeletonFormField.displayName = 'SkeletonFormField';
