import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaConfined } from '@xing-com/layout-tokens';
import { scale050, scale100, spaceL } from '@xing-com/tokens';
import { Hero, Headline } from '@xing-com/typography';

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const Title = styled(Hero)`
  text-align: center;

  @media ${mediaConfined} {
    margin-bottom: ${scale050};
  }
`;

export const Subtitle = styled(Headline)`
  font-weight: 400;

  @media ${mediaConfined} {
    margin-bottom: calc(2 * ${scale100});
  }
`;

export const ButtonsContainer = styled.div`
  flex-direction: column;

  @media ${mediaConfined} {
    display: flex;
    justify-content: space-around;
  }
`;

export const RegisterButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${scale100};

  @media ${mediaConfined} {
    flex-direction: row;
    justify-content: center;
    gap: ${spaceL};
  }
`;
