/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateWorkplacePreferenceMutationVariables = Types.Exact<{
  input: Types.UpdateWorkplacePreferenceInput;
}>;

export type CreateWorkplacePreferenceMutation = {
  __typename?: 'Mutation';
  workplacePreference?:
    | { __typename: 'UpdateWorkplacePreferenceError'; message?: string | null }
    | {
        __typename: 'UpdateWorkplacePreferenceSuccess';
        office: boolean;
        partlyHome: boolean;
        mostlyHome: boolean;
        homeOffice: boolean;
      }
    | null;
};

export const CreateWorkplacePreferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createWorkplacePreference' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateWorkplacePreferenceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workplacePreference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateWorkplacePreferenceSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'partlyHome' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mostlyHome' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homeOffice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateWorkplacePreferenceError',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWorkplacePreferenceMutation,
  CreateWorkplacePreferenceMutationVariables
>;
