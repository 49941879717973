/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from 'react';

import { TagContent } from './tag-content';
import * as Styled from './tag.styles';
import type { InteractiveTagProps } from './tag.types';

export const InteractiveTag = React.forwardRef<
  HTMLDivElement,
  InteractiveTagProps
>(
  (
    {
      badgeProps,
      children,
      className,
      disabled,
      dragging,
      fontWeight,
      hideBorder,
      leadingIcon: LeadingIcon,
      maxWidth,
      onClick,
      profile,
      selected,
      trailingIcon: TrailingIcon,
      value,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const hasChildren = Boolean(children);
    const hasLeadingContent =
      !hasChildren || Boolean(LeadingIcon) || Boolean(profile);
    const hasTrailingContent =
      !hasChildren || Boolean(TrailingIcon) || Boolean(badgeProps);

    const handleOnClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      if (!disabled && onClick) {
        event.currentTarget.dataset.value = value;
        onClick(event);
      }
    };

    const handleKeyDown = (
      event: React.KeyboardEvent<HTMLButtonElement>
    ): void => {
      if ((event.key === 'Enter' || event.key === ' ') && onClick) {
        event.preventDefault();
        onClick(event as unknown as React.MouseEvent<HTMLButtonElement>);
      }
    };

    return (
      <Styled.InteractiveTag
        $disabled={disabled}
        $dragging={dragging}
        $fontWeight={fontWeight}
        $hasLeadingContent={hasLeadingContent}
        $hasTrailingContent={hasTrailingContent}
        $hideBorder={hideBorder}
        $profile={profile}
        $selected={selected}
        aria-disabled={disabled}
        aria-pressed={selected}
        as="button"
        className={className}
        data-xds="InteractiveTag"
        disabled={disabled}
        onClick={handleOnClick}
        onKeyDown={handleKeyDown}
        ref={forwardedRef}
        role={onClick ? 'button' : 'link'}
        tabIndex={disabled ? -1 : 0}
        type="button"
        value={value}
        {...props}
      >
        <TagContent
          badgeProps={badgeProps}
          disabled={disabled}
          leadingIcon={
            LeadingIcon && (
              <LeadingIcon aria-hidden="true" width={18} height={18} />
            )
          }
          maxWidth={maxWidth}
          profile={profile}
          selected={selected}
          trailingIcon={
            TrailingIcon && (
              <TrailingIcon aria-hidden="true" width={18} height={18} />
            )
          }
        >
          {children}
        </TagContent>
      </Styled.InteractiveTag>
    );
  }
);

InteractiveTag.displayName = 'InteractiveTag';
