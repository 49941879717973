import { IconPlus } from '@xing-com/icons';
import { Tag } from '@xing-com/tag';

import { StyledPillContainer, StyledHeader } from './pill-select.styles';

type PillSelectOption = {
  copy: string;
  value: string;
};

export type PillSelectProps = {
  header: React.ReactNode;
  options: PillSelectOption[];
  value?: string;
  onChange?: (value: string) => void;
  onPlusClick?: () => void;
  className?: string;
};

export const PillSelect: React.FC<PillSelectProps> = ({
  header,
  options,
  value,
  onChange,
  className,
}) => {
  const changeValue = (newValue: string): void => {
    if (value === newValue) {
      if (onChange) onChange('');
    } else {
      if (onChange) onChange(newValue);
    }
  };

  return (
    <span className={className}>
      <StyledHeader>{header}</StyledHeader>

      <StyledPillContainer>
        {options.map((option) => {
          return (
            <Tag
              key={option.value}
              leadingIcon={IconPlus}
              onClick={() => changeValue(option.value)}
              selected={value === option.value}
              data-qa={value === option.value ? 'selected' : ''}
            >
              {option.copy}
            </Tag>
          );
        })}
      </StyledPillContainer>
    </span>
  );
};
