import * as React from 'react';
import { useIntl } from 'react-intl';

import { scale130, scale150, scale160, scale180 } from '@xing-com/tokens';

import { SkeletonPillShape } from '..';
import type { SkeletonInputBarProps } from '..';

export const SkeletonInputBar = React.forwardRef<
  HTMLDivElement,
  SkeletonInputBarProps
>(({ className, size = 'medium', ...props }, forwardedRef): JSX.Element => {
  const intl = useIntl();
  const sizes = {
    small: scale130,
    medium: scale150,
    large: scale160,
    xlarge: scale180,
  };

  return (
    <SkeletonPillShape
      aria-busy="true"
      aria-label={intl.formatMessage({
        id: 'XDS_SKELETON_GENERIC_A11Y',
        defaultMessage: 'Loading',
      })}
      className={className}
      data-xds="SkeletonPillShape"
      height={sizes[size]}
      ref={forwardedRef}
      role="status"
      {...props}
    />
  );
});

SkeletonInputBar.displayName = 'SkeletonInputBar';
