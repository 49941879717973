import styled from 'styled-components';

import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceXXL } from '@xing-com/tokens';

export const Box = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: ${spaceXXL};

  @media ${mediaWideNavless} {
    max-width: 972px;
  }
`;
