/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from 'react';
import ReactDom from 'react-dom';

import { PopOverPrimitive } from './pop-over.primitive';
import type { PopOverProps } from './pop-over.types';
import { autoPositionByRef } from './utils/auto-position-item';

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

const EVENT_LISTENER_OPTIONS =
  parseInt(React.version.split('.')[0]) >= 17 ? { capture: true } : {};

type PopOverRef = HTMLDivElement & {
  reposition: () => void;
};

export const PopOver = React.forwardRef<HTMLDivElement, PopOverProps>(
  (
    {
      alwaysUp = false,
      children,
      className,
      isInFlow = false,
      isStatic,
      offset,
      onOutsideClick,
      show = false,
      triggerRef,
      ...props
    },
    forwardedRef
  ): JSX.Element | null => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [position, setPosition] = React.useState({});

    const reposition = React.useCallback(() => {
      if (show && !isStatic) {
        const newPosition = autoPositionByRef(
          triggerRef,
          containerRef,
          isInFlow,
          offset,
          alwaysUp
        );
        setPosition(newPosition);
      }
    }, [alwaysUp, isInFlow, isStatic, offset, show, triggerRef]);

    useIsomorphicLayoutEffect(() => {
      reposition();
    }, [triggerRef, containerRef, show, reposition]);

    React.useImperativeHandle(forwardedRef, () => {
      if (!containerRef.current) {
        return null as unknown as PopOverRef;
      }
      return {
        ...containerRef.current,
        reposition,
      } as PopOverRef;
    }, [reposition]);

    React.useEffect(() => {
      const checkOutSideClick = (e: MouseEvent): void => {
        if (
          (containerRef &&
            containerRef.current &&
            containerRef.current.contains(e.target as Node)) ||
          (triggerRef &&
            triggerRef.current &&
            triggerRef.current.contains(e.target as Node))
        ) {
          return;
        }

        if (onOutsideClick) onOutsideClick();
      };

      if (show) {
        window.addEventListener('resize', reposition, EVENT_LISTENER_OPTIONS);
        document.addEventListener(
          'click',
          checkOutSideClick,
          EVENT_LISTENER_OPTIONS
        );
      }

      return () => {
        window.removeEventListener(
          'resize',
          reposition,
          EVENT_LISTENER_OPTIONS
        );
        document.removeEventListener(
          'click',
          checkOutSideClick,
          EVENT_LISTENER_OPTIONS
        );
      };
    }, [onOutsideClick, reposition, show, triggerRef]);

    const commonProps = {
      className,
      containerRef,
      position,
    };

    if (!show) {
      return null;
    }

    if (isInFlow) {
      return (
        <PopOverPrimitive {...commonProps} {...props}>
          {children}
        </PopOverPrimitive>
      );
    }

    return ReactDom.createPortal(
      <React.Fragment>
        <PopOverPrimitive {...commonProps} {...props}>
          {children}
        </PopOverPrimitive>
      </React.Fragment>,
      document.body
    );
  }
);

PopOver.displayName = 'PopOver';
