import styled, { css } from 'styled-components';

import { mediaTopBarHidden, mediaTopBarVisible } from '@xing-com/layout-tokens';
import { InputBar } from '@xing-com/text-field';
import {
  scale100,
  scale080,
  xdlColorSliderTickMark,
  cornerRadiusXL,
} from '@xing-com/tokens';

const TEXTAREA_2_LINES_HEIGHT = 70;
const TEXTAREA_3_LINES_HEIGHT = 94;
export const TEXTAREA_5_LINES_HEIGHT = 142;
const TEXTAREA_3_LINES_HEIGHT_LG = 130;
export const TEXTAREA_6_LINES_HEIGHT_LG = 220;

export const DEFAULT_HEIGHT_CONSTRAINTS = {
  TOP_BAR_VISIBLE: {
    MIN_HEIGHT: TEXTAREA_2_LINES_HEIGHT,
    MAX_HEIGHT: TEXTAREA_2_LINES_HEIGHT,
  },
  LANDING: {
    MIN_HEIGHT: TEXTAREA_3_LINES_HEIGHT,
    MAX_HEIGHT: TEXTAREA_3_LINES_HEIGHT,
  },
  TOP_BAR_HIDDEN: {
    MIN_HEIGHT: TEXTAREA_3_LINES_HEIGHT_LG,
    MAX_HEIGHT: TEXTAREA_3_LINES_HEIGHT_LG,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getCSSHeightConstraints = (isLanding = false) => ({
  TOP_BAR_VISIBLE: css`
    min-height: ${DEFAULT_HEIGHT_CONSTRAINTS[
      isLanding ? 'LANDING' : 'TOP_BAR_VISIBLE'
    ].MIN_HEIGHT}px;
    max-height: ${DEFAULT_HEIGHT_CONSTRAINTS[
      isLanding ? 'LANDING' : 'TOP_BAR_VISIBLE'
    ].MAX_HEIGHT}px;
  `,
  TOP_BAR_HIDDEN: css`
    min-height: ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_HIDDEN.MIN_HEIGHT}px;
    max-height: ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_HIDDEN.MAX_HEIGHT}px;
  `,
});

type InputProps = {
  $isAnimating?: boolean;
  $isLanding?: boolean;
  $nested?: boolean;
};

export const Input = styled(InputBar)<InputProps>`
  ${({ $nested }) =>
    !$nested &&
    css`
      grid-area: key;
    `}

  & textarea {
    border-radius: ${cornerRadiusXL};
    /* Remove the scrollbar when the animation is running so that it does not show behind the animation container */
    overflow: ${({ $isAnimating }) => ($isAnimating ? 'hidden' : 'auto')};
    scrollbar-width: thin;
    scrollbar-color: ${xdlColorSliderTickMark} transparent;
    /* FIXME: This transition is causing the onBlur text truncation to fail */
    /* transition: max-height 0.2s ease-in-out; */
  }

  @media ${mediaTopBarVisible} {
    & textarea {
      ${({ $isLanding }) => getCSSHeightConstraints($isLanding).TOP_BAR_VISIBLE}
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:focus {
        max-height: ${TEXTAREA_5_LINES_HEIGHT}px;
      }

      &:not(:focus) {
        overflow: hidden;
      }

      &,
      &::placeholder {
        line-height: 1.5;
        font-size: ${scale080};
      }
    }
  }

  @media ${mediaTopBarHidden} {
    & textarea {
      ${getCSSHeightConstraints().TOP_BAR_HIDDEN}

      &:focus {
        max-height: ${TEXTAREA_6_LINES_HEIGHT_LG}px;
      }

      &:not(:focus) {
        overflow: hidden;
      }

      &,
      &::placeholder {
        line-height: 1.5;
        font-size: ${scale100};
      }
    }
  }
`;
