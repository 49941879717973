import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import { scale130, scale150, scale160 } from '@xing-com/tokens';

import { SkeletonPillShape } from '../';

import type { SkeletonButtonSize } from './skeleton-button.types';

type ButtonTransientProps = {
  $size?: SkeletonButtonSize;
  $sizeConfined?: SkeletonButtonSize;
  $sizeWide?: SkeletonButtonSize;
};

// *** Sizes ***
export const sizes = {
  small: css`
    height: ${scale130};
  `,
  medium: css`
    height: ${scale150};
  `,
  large: css`
    height: ${scale160};
  `,
};

// *** Components ***
export const Button = styled(SkeletonPillShape)<ButtonTransientProps>`
  ${({ $size }) => $size && sizes[$size]};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]};
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]};
      }
    `};
`;
