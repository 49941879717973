import styled from 'styled-components';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';

import { useDisplayAds } from '../../use-display-ads';
import { useAdRefresh } from '../use-ad-refresh';

import { DisplayAdProfileRectangle } from './rectangle-ad';

const AdWrapper = styled.div`
  width: 300px;
  height: 250px;
  position: relative;
  z-index: 1;
`;

export const DisplayAdProfileRectangleSlot: React.FC = () => {
  const isSmallScreen = useMatchMedia(mediaSmallOrTiny);
  const { displayAdsAllowed } = useDisplayAds();

  const { refreshMobile } = useAdRefresh({
    isSmallScreen,
    slots: {
      mobile: 'rectangle',
      desktop: '',
    },
  });

  if (!displayAdsAllowed || !isSmallScreen) {
    return null;
  }

  return (
    <AdWrapper>
      <NoSSR>
        <DisplayAdProfileRectangle refresh={refreshMobile} />
      </NoSSR>
    </AdWrapper>
  );
};
