import classnames from 'classnames';
import type * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS does not know about CSS files
import styles from './grid.css';

type RowProps = {
  /** adds custom className to Row */
  className?: string;
  /** ref of the dom element */
  innerRef?: React.RefObject<HTMLDivElement>;
};

/**
 * **Row** is a horizontal section inside a grid
 *
 * Allows adding arbitrary props that are allowed on `div` elements
 * (e.g. `id`, `style` or `title`)
 *
 * @deprecated Use `@xing-com/grid` instead
 */
export const Row: React.FC<RowProps & React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  innerRef,
  ...other
}) => {
  const classes = classnames(styles.row, className);

  return <div className={classes} ref={innerRef} {...other} />;
};

Row.displayName = 'Row';
