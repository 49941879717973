import { Styled } from './content-switcher-more-item.styles';
import type { ContentSwitcherMoreItemProps } from './content-switcher-more-item.types';

export const ContentSwitcherMoreItem = ({
  children,
  ...props
}: ContentSwitcherMoreItemProps): JSX.Element => {
  const {
    disabled,
    href,
    icon: Icon,
    innerRef,
    miniBadge,
    onClick,
    popOver,
    to,
  } = props;
  const onClickHandler = disabled
    ? (e: React.MouseEvent) => e.preventDefault()
    : (e: React.MouseEvent) => {
        popOver.handleHide();
        if (onClick) onClick(e);
      };
  const commonProps = {
    onClick: (e: React.MouseEvent) => onClickHandler(e),
    ref: innerRef,
    role: 'menuitem',
  };

  const moreItemContent = (
    <Styled.MoreItemInner>
      {miniBadge ? <Styled.MiniBadge /> : null}
      {Icon ? (
        <Styled.MoreItemIcon>
          <Icon height={24} width={24} aria-hidden="true" />
        </Styled.MoreItemIcon>
      ) : null}
      {children}
    </Styled.MoreItemInner>
  );

  return (
    <Styled.MoreListItem>
      {to || href ? (
        <Styled.MoreItemLink
          {...(to ? { to } : {})}
          {...(href ? { href } : {})}
          {...commonProps}
          {...props}
        >
          {moreItemContent}
        </Styled.MoreItemLink>
      ) : (
        <Styled.MoreItem disabled={disabled} {...commonProps} {...props}>
          {moreItemContent}
        </Styled.MoreItem>
      )}
    </Styled.MoreListItem>
  );
};
