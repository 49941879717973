import * as React from 'react';
import { useIntl } from 'react-intl';

import devNote from '@xing-com/dev-note';
import { cornerRadiusS } from '@xing-com/tokens';

import * as Styled from './skeleton-image.styles';
import type { SkeletonImageProps } from './skeleton-image.types';

export const SkeletonImage = React.forwardRef<
  HTMLDivElement,
  SkeletonImageProps
>(
  (
    { className, height = '100%', variant, width = '100%', ...props },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();

    if (variant) {
      devNote.deprecate(
        "The variant property is deprecated and should be removed. Variant 'secondary' is automatically mapped to 'primary'."
      );
    }

    return (
      <Styled.Image
        $height={height}
        $variant={variant}
        $width={width}
        aria-busy="true"
        aria-label={intl.formatMessage({
          id: 'XDS_SKELETON_GENERIC_A11Y',
          defaultMessage: 'Loading',
        })}
        className={className}
        cornerRadius={cornerRadiusS}
        data-xds="SkeletonImage"
        ref={forwardedRef}
        role="status"
        {...props}
      />
    );
  }
);

SkeletonImage.displayName = 'SkeletonImage';
