/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconMiniJob = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconMiniJob"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.4 5.5A3 3 0 0 1 16 4.17v2.136l.57.27a6.03 6.03 0 0 1 2.853 2.853l.271.571H21v4h-1.306l-.27.57a6.03 6.03 0 0 1-2.853 2.853l-.571.271V19h-2v-1h-4q-.439 0-.858-.06L8 17.775V19H6v-2.51l-.286-.29A6 6 0 0 1 10 6h4.112zM10 20h2v1h6v-2.07A8.04 8.04 0 0 0 20.93 16H23V8h-2.07A8.04 8.04 0 0 0 18 5.07V2h-1c-1.637 0-3.09.787-4 2h-3a7.99 7.99 0 0 0-6.485 3.315A1.51 1.51 0 0 1 2.75 6h-1.5c0 1.115.604 2.087 1.503 2.607A8 8 0 0 0 2 12c0 2.028.756 3.882 2 5.292V21h6zm2-12.25v1.5H8v-1.5zM17 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMiniJob;
