import * as React from 'react';
import { useIntl } from 'react-intl';

import { IconCross } from '@xing-com/icons';

import * as Styled from './tag.styles';
import type { DeleteTagProps } from './tag.types';

export const DeleteTag = React.forwardRef<HTMLDivElement, DeleteTagProps>(
  (
    {
      children,
      className,
      deleteButtonProps,
      disabled,
      fontWeight,
      maxWidth = '330px',
      onClick,
      onDelete,
      profile,
      selected,
      value,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();
    const hasChildren = Boolean(children);
    const hasLeadingContent = !hasChildren || Boolean(profile);

    const handleOnClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      if (!disabled && onClick) {
        event.currentTarget.dataset.value = value;
        onClick(event);
      }
    };

    const handleOnDelete = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      if (!disabled && onDelete) {
        event.currentTarget.dataset.value = value;
        onDelete(event);
      }
    };

    return (
      <Styled.DeleteButtonContainer
        $disabled={disabled}
        $fontWeight={fontWeight}
        $profile={profile}
        $selected={selected}
        aria-disabled={disabled}
        className={className}
        data-xds="DeleteTag"
        ref={forwardedRef}
        {...props}
      >
        {(profile || Boolean(children)) && (
          <Styled.DeleteButton
            $hasLeadingContent={hasLeadingContent}
            disabled={disabled}
            onClick={handleOnClick}
            role="button"
            tabIndex={disabled ? -1 : 0}
            value={value}
          >
            {profile && (
              <Styled.ProfileImageContainer>
                <Styled.Profile
                  $disabled={disabled}
                  profileName={profile.name}
                  src={profile.image}
                  size="xxsmall"
                />
              </Styled.ProfileImageContainer>
            )}
            {children && (
              <Styled.TextTruncationWrapper>
                <Styled.TextTruncation $maxWidth={maxWidth}>
                  {children}
                </Styled.TextTruncation>
              </Styled.TextTruncationWrapper>
            )}
          </Styled.DeleteButton>
        )}
        <Styled.DeleteButtonAction
          aria-label={intl.formatMessage({
            id: 'XDS_DELETE_TAG_BUTTON_A11Y',
            defaultMessage: 'Delete',
          })}
          data-qa="deletable"
          data-testid="deletable"
          disabled={disabled}
          onClick={handleOnDelete}
          type="reset"
          {...deleteButtonProps}
        >
          <Styled.IconContainer>
            <IconCross aria-hidden="true" width={18} height={18} />
          </Styled.IconContainer>
        </Styled.DeleteButtonAction>
      </Styled.DeleteButtonContainer>
    );
  }
);

DeleteTag.displayName = 'DeleteTag';
