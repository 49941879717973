import type * as React from 'react';

import type { GridType } from './container';
import { GridContainer } from './container';
import { Grid } from './grid';

type ContainedGridProps = {
  children?: React.ReactNode | undefined;
  className?: string;
  type: GridType;
};

/**
 * @deprecated Use `@xing-com/grid` instead
 */
export const ContainedGrid: React.FC<ContainedGridProps> = ({
  type,
  className,
  ...other
}) => (
  <GridContainer {...{ className, type }}>
    <Grid {...other} />
  </GridContainer>
);

ContainedGrid.displayName = 'ContainedGrid';
