import { useIntl } from 'react-intl';

import { PillSelect } from '@xing-com/crate-startpage-logged-out-pill-select';

import { preferencesData } from './data';
import type { VariantProps } from './types';

export const PreferencesForm: React.FC<VariantProps> = ({
  preferences,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  return preferencesData.options.map((option) => (
    <PillSelect
      key={option.preference}
      header={formatMessage({ id: option.header })}
      options={Object.keys(option.possibleValues).map((value) => ({
        copy: formatMessage({ id: value }),
        value,
      }))}
      onChange={(value) => onChange && onChange(option.preference, value)}
      value={preferences?.[option.preference] || ''}
    />
  ));
};
