function random(seed: number): number {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export function shuffle<T>([...array]: Array<T>, seed: number): Array<T> {
  for (let i = 0; i < array.length; i++) {
    const j = Math.min(
      Math.floor(random(seed++) * array.length),
      array.length - 1
    );

    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}
