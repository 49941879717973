import styled, { keyframes } from 'styled-components';

import {
  motionEasingEnter,
  motionEasingLeave,
  motionTimeXXL,
  scale010,
  scale030,
  scale050,
  xdlColorBackground,
  xdlColorMarkerHighlightText,
} from '@xing-com/tokens';

// *** Animations ***
const growAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0.6);
    opacity: 1;
    border-width: ${scale010};
  }

  100% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 1;
    border-width: ${scale010};
  }
`;

const fadeAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 1;
    border-width: ${scale010};
  }

  100% {
    transform: translate(-50%, -50%) scale(2.8);
    opacity: 0;
    border-width: ${scale030};
  }
`;

// *** Components ***
export const DiscoverBadge = styled.span`
  background: ${xdlColorMarkerHighlightText};
  border-color: ${xdlColorBackground};
  border-radius: 50%;
  border-style: solid;
  border-width: ${scale010};
  display: inline-flex;
  height: ${scale050};
  width: ${scale050};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border-style: solid;
    border-color: ${xdlColorMarkerHighlightText};

    /* First grow animation */
    animation-name: ${growAnimation};
    animation-duration: ${motionTimeXXL};
    animation-timing-function: ${motionEasingLeave};
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    /* Second fade animation */
    animation:
      ${growAnimation} ${motionTimeXXL} ${motionEasingLeave} forwards,
      ${fadeAnimation} ${motionTimeXXL} ${motionEasingEnter} ${motionTimeXXL}
        forwards;

    /* Repeat the entire sequence */
    animation-iteration-count: infinite;
  }
`;
