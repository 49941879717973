import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';

import { useDisplayAds } from '../../use-display-ads';
import { useAdRefresh } from '../use-ad-refresh';

import { DisplayAdProfileHalfpageBig } from './halfpage-sticky-ad-big';

const OverflowContainer = styled.div`
  overflow-x: clip;
`;

const Wrapper = styled.div`
  width: 1030px;
  margin: 0 auto;
  position: relative;
`;

const AdContainer = styled.div<{ $isFixed: boolean }>`
  position: absolute;
  width: 300px;
  height: 600px;
  left: 100%;
  top: 290px;

  ${({ $isFixed }) =>
    $isFixed &&
    css`
      position: fixed;
      left: auto;
      top: 85px;
      margin-left: 1030px;
    `}
`;

export const DisplayAdProfileHalfpageSlotBig: React.FC = () => {
  const isBigScreen = useMatchMedia('(min-width: 1700px)');
  const { displayAdsAllowed } = useDisplayAds();
  const [isFixed, setIsFixed] = useState(false);

  const { refreshDesktop } = useAdRefresh({
    isSmallScreen: !isBigScreen,
    slots: {
      mobile: '',
      desktop: 'halfpage',
    },
  });

  const setStickyPosition = (): void => {
    const topPosition = document.body.getBoundingClientRect().top;

    // Consider sticky navbar height
    setIsFixed(Math.abs(topPosition) >= 287);
  };

  useEffect(() => {
    if (!displayAdsAllowed) {
      return;
    }

    const stickyCbThrottled = throttle(setStickyPosition, 100);
    window.addEventListener('scroll', stickyCbThrottled);

    return () => {
      window.removeEventListener('scroll', stickyCbThrottled);
    };
  }, [displayAdsAllowed]);

  if (!displayAdsAllowed || !isBigScreen) {
    return null;
  }

  return (
    <OverflowContainer>
      <Wrapper>
        <NoSSR>
          <AdContainer $isFixed={isFixed}>
            <DisplayAdProfileHalfpageBig refresh={refreshDesktop} />
          </AdContainer>
        </NoSSR>
      </Wrapper>
    </OverflowContainer>
  );
};
