import {
  DisplayAdStartpageSlot01,
  DisplayAdStartpageSlot02,
  useDisplayAds,
} from '@xing-com/crate-ads-display-ads';

export const AdSection: React.FC<{ placement: string }> = ({ placement }) => {
  const { displayAdsAllowed } = useDisplayAds();

  if (!displayAdsAllowed) {
    return null;
  }

  return (
    <>
      {placement === 'top' && <DisplayAdStartpageSlot01 />}
      {placement === 'bottom' && <DisplayAdStartpageSlot02 />}
    </>
  );
};
