import styled from 'styled-components';

import { AutoComplete } from '@xing-com/auto-complete';
import { Button } from '@xing-com/button';
import { Divider as BaseDivider } from '@xing-com/divider';
import { mediaTopBarHidden, mediaTopBarVisible } from '@xing-com/layout-tokens';
import {
  scale090,
  scale100,
  scale180,
  spaceL,
  spaceXL,
  xdlColorButtonStrong,
  xdlColorButtonStrongHover,
  xdlColorTextInvert,
  xdlColorTextOnDark,
  xdlPaletteGrayPlain11,
  xdlPaletteWhite,
  scale080,
  spaceM,
  spaceS,
  scale130,
} from '@xing-com/tokens';

export const Form = styled.form`
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
    'key'
    'div'
    'loc';

  @media ${mediaTopBarHidden} {
    padding-left: 0;
    padding-right: 0;
    gap: ${spaceL};
    grid-template-columns: 1fr auto;
    grid-template-areas:
      'key key'
      'loc but';
  }
`;

export const Divider = styled(BaseDivider)`
  grid-area: div;

  @media ${mediaTopBarHidden} {
    display: none;
  }
`;

export const LocationFieldsWrapper = styled.div`
  grid-area: loc;
  display: flex;
  position: relative;
`;

export const LocationAutoComplete = styled(AutoComplete)`
  && {
    flex: 1 1 auto;

    @media ${mediaTopBarVisible} {
      & input {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: 48px;

        /*
        iOS border radius appearance bug: https://stackoverflow.com/a/2918716/1025732
        Appearance is set by normalized styles here: brewery-packages/layout/global-styles/src/normalize-styles.ts
        */
        appearance: none;

        &,
        &::placeholder {
          line-height: 1.5;
          font-size: ${scale080};
        }

        &::-webkit-search-cancel-button {
          display: none;
        }
      }

      & button[type='reset'] {
        height: ${scale130};
        top: ${spaceS};
        left: ${spaceM};
        right: auto;
        background-color: ${xdlPaletteWhite};
      }
    }

    @media ${mediaTopBarHidden} {
      & input {
        &,
        &::placeholder {
          line-height: 1.5;
          font-size: ${scale100};
        }

        &::-webkit-search-cancel-button {
          display: none;
        }
      }
    }
  }
`;

export const SubmitButton = styled(Button)`
  grid-area: but;
  color: ${xdlColorTextInvert};
  flex-shrink: 0;

  &:after {
    background-color: ${xdlColorButtonStrong};
  }

  &:hover:after {
    background-color: ${xdlColorButtonStrongHover};
  }

  &:active {
    color: ${xdlColorTextOnDark};

    &:after {
      background-color: ${xdlPaletteGrayPlain11};
    }
  }
`;

export const SubmitButtonBig = styled(SubmitButton)`
  display: none;

  @media ${mediaTopBarHidden} {
    display: block;
    height: ${scale180};
    font-size: ${scale090};
    padding-inline: ${spaceXL};
  }
`;

export const SubmitButtonSmall = styled(SubmitButton)`
  position: absolute;
  right: ${spaceM};
  top: ${spaceS};

  @media ${mediaTopBarHidden} {
    display: none;
  }
`;
