import styled, { css } from 'styled-components';

import {
  scale010,
  scale100,
  scale130,
  xdlColorControlFillQuaternary,
  xdlColorTextOnDark,
} from '@xing-com/tokens';

import { Badge } from '../badge';

import type { ConnectionDegreeVariants } from './connection-degree.types';

type ConnectionDegreeTransientProps = {
  $variant?: ConnectionDegreeVariants;
};

// *** Variants ***
export const variants = {
  responsive: css`
    align-items: center;
    border-radius: 50px;
    display: flex;
    fill: ${xdlColorTextOnDark};
    font-weight: 700;
    height: 18%;
    justify-content: center;
    max-height: ${scale130};
    max-width: ${scale130};
    min-height: ${scale100};
    min-width: ${scale100};
    position: absolute;
    width: 18%;
  `,
};

// *** Components ***
export const ConnectionDegreeBadge = styled(
  Badge
)<ConnectionDegreeTransientProps>`
  background: ${xdlColorControlFillQuaternary};
  border-color: ${xdlColorTextOnDark};
  border-style: solid;
  border-width: ${scale010};
  color: ${xdlColorTextOnDark};
  ${({ $variant }) => $variant && variants[$variant]};
`;
