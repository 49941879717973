import { Helmet } from 'react-helmet-async';
import serialize from 'serialize-javascript';

export const SearchboxStartpageMetadata: React.FC = () => {
  const sitelinkSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://www.xing.com/',
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate:
          'https://www.xing.com/jobs/search?keywords={search_term_string}',
      },
      'query-input': 'required name={search_term_string}',
    },
  };
  return (
    <Helmet>
      <script type="application/ld+json">
        {serialize(sitelinkSchema, { isJSON: true })}
      </script>
    </Helmet>
  );
};
