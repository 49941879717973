import * as React from 'react';
import { useIntl } from 'react-intl';

import { scale110 } from '@xing-com/tokens';

import { SkeletonCircle } from '../';
import type { SkeletonRadioButtonProps } from '../';

export const SkeletonRadioButton = React.forwardRef<
  HTMLDivElement,
  SkeletonRadioButtonProps
>(({ className, ...props }, forwardedRef): JSX.Element => {
  const intl = useIntl();

  return (
    <SkeletonCircle
      aria-busy="true"
      aria-label={intl.formatMessage({
        id: 'XDS_SKELETON_GENERIC_A11Y',
        defaultMessage: 'Loading',
      })}
      className={className}
      data-xds="SkeletonRadioButton"
      ref={forwardedRef}
      role="status"
      size={scale110}
      {...props}
    />
  );
});

SkeletonRadioButton.displayName = 'SkeletonRadioButton';
