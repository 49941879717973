/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconAIFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconAIFilled"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m17.5 12 .81 2.58a2.86 2.86 0 0 0 2.12 2.12l2.57.8-2.58.81a2.86 2.86 0 0 0-2.12 2.12L17.5 23l-.81-2.58a2.86 2.86 0 0 0-2.12-2.12L12 17.5l2.58-.81a2.86 2.86 0 0 0 2.12-2.12zM10 18.67l-2-4.96c-.31-.77-.94-1.4-1.71-1.71l-4.96-2 4.96-2c.77-.31 1.4-.94 1.71-1.71l2-4.96 2 4.96c.31.77.94 1.4 1.71 1.71l4.96 2-4.96 2c-.77.31-1.4.94-1.71 1.71z"
    />
  </svg>
);
export default IconAIFilled;
