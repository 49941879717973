import styled from 'styled-components';

import { spaceM, spaceS } from '@xing-com/tokens';
import { Headline, BodyCopy } from '@xing-com/typography';

export const StyledIconWrapper = styled.span`
  margin-bottom: ${spaceM};
`;

export const StyledHeadline = styled(Headline)<{ $wrapHeadline?: boolean }>`
  margin-bottom: ${spaceS};
  white-space: ${({ $wrapHeadline }) => ($wrapHeadline ? 'normal' : 'nowrap')};
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const StyledBodyCopy = styled(BodyCopy)`
  margin-bottom: 0;
`;
