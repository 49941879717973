import styled from 'styled-components';

import { adSlotConfig } from '../../ad-slot-config';
import { useDefineSlot } from '../use-define-slot';

const AdContainer = styled.div`
  height: 600px;
`;

export const DisplayAdProfileHalfpageSmall: React.FC<{ refresh: boolean }> = ({
  refresh,
}) => {
  const { id, adUnitPath, size } = adSlotConfig.Profile.HalfpageStickySmall;
  useDefineSlot({ id, adUnitPath, size, refresh });

  return <AdContainer id={id} />;
};
