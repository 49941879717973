import styled, { keyframes, css } from 'styled-components';

import { Button } from '@xing-com/button';
import {
  mediaTopBarHidden,
  mediaTopBarVisible,
  zIndexElevatedLayer4,
} from '@xing-com/layout-tokens';
import {
  spaceL,
  spaceM,
  xdlColorBackground,
  xdlColorModalBorder,
  cornerRadiusXL,
  scale005,
  dropShadowM,
  xdlColorSliderTickMark,
  scale080,
  scale100,
  xdlColorBackgroundTertiary,
} from '@xing-com/tokens';

import {
  TEXTAREA_5_LINES_HEIGHT,
  TEXTAREA_6_LINES_HEIGHT_LG,
  DEFAULT_HEIGHT_CONSTRAINTS,
} from '../input';

export const CLASSES = {
  CONTAINER_ANIMATION: 'with-animation',
  ANIMATED_SPAN: 'animated-element',
};
export const VARIABLES = {
  HEIGHT: '--container-height',
  MAX_HEIGHT: '--container-max-height',
  GRADIENT_LEFT: '--left',
  GRADIENT_RIGHT: '--right',
  ERROR_BANNER_HEIGHT: '--error-banner-height',
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const withErrorStyles = css`
  && textarea {
    padding-bottom: calc(var(${VARIABLES.ERROR_BANNER_HEIGHT}) + 20px);
    scroll-padding-bottom: calc(var(${VARIABLES.ERROR_BANNER_HEIGHT}) + 20px);

    @media ${mediaTopBarVisible} {
      min-height: calc(
        ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_VISIBLE.MIN_HEIGHT}px +
          var(${VARIABLES.ERROR_BANNER_HEIGHT})
      );
      max-height: calc(
        ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_VISIBLE.MAX_HEIGHT}px +
          var(${VARIABLES.ERROR_BANNER_HEIGHT})
      );
    }

    @media ${mediaTopBarHidden} {
      min-height: calc(
        ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_VISIBLE.MIN_HEIGHT}px +
          var(${VARIABLES.ERROR_BANNER_HEIGHT})
      );
      max-height: calc(
        ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_HIDDEN.MAX_HEIGHT}px +
          var(${VARIABLES.ERROR_BANNER_HEIGHT})
      );
    }
  }
`;

type ContainerProps = { $withErrorBanner?: boolean };
export const SuggestionsContainer = styled.div<ContainerProps>`
  margin-top: auto;
  grid-area: key;
  position: relative;

  @media ${mediaTopBarVisible} {
    min-height: ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_VISIBLE.MIN_HEIGHT}px;
  }

  @media ${mediaTopBarHidden} {
    min-height: ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_HIDDEN.MIN_HEIGHT}px;
  }

  ${({ $withErrorBanner }) => ($withErrorBanner ? withErrorStyles : '')}
`;

export const AnimationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @media ${mediaTopBarVisible} {
    min-height: ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_VISIBLE.MIN_HEIGHT}px;
  }

  @media ${mediaTopBarHidden} {
    min-height: ${DEFAULT_HEIGHT_CONSTRAINTS.TOP_BAR_HIDDEN.MIN_HEIGHT}px;
  }

  &.${CLASSES.CONTAINER_ANIMATION} {
    z-index: ${zIndexElevatedLayer4};

    align-content: flex-start;
    background-color: ${xdlColorBackground};
    border-radius: ${cornerRadiusXL} ${cornerRadiusXL} 0 0;
    border: 1px solid transparent;
    display: flex;
    flex-wrap: wrap;
    height: var(${VARIABLES.HEIGHT});
    padding: 10px 18px;
    padding-right: 16px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: ${xdlColorSliderTickMark} transparent;
    scrollbar-width: thin;

    @media ${mediaTopBarVisible} {
      line-height: 1.5;
      font-size: ${scale080};
      max-height: ${TEXTAREA_5_LINES_HEIGHT}px;
    }

    @media ${mediaTopBarHidden} {
      padding: 19px 20px;
      border-radius: ${cornerRadiusXL};
      line-height: 1.5;
      font-size: ${scale100};
      max-height: ${TEXTAREA_6_LINES_HEIGHT_LG}px;
    }
  }

  /* Inner spans animation */

  @property ${VARIABLES.GRADIENT_LEFT} {
    syntax: '<color>';
    initial-value: #1d2124;
    inherits: false;
  }
  @property ${VARIABLES.GRADIENT_RIGHT} {
    syntax: '<color>';
    initial-value: #1d2124;
    inherits: false;
  }

  & .animated-element {
    ${VARIABLES.GRADIENT_LEFT}: #1d2124;
    ${VARIABLES.GRADIENT_RIGHT}: #1d2124;
    background: linear-gradient(90deg, var(--left) 0%, var(--right) 100%);
    transition:
      ${VARIABLES.GRADIENT_LEFT} 0.5s ease-out,
      ${VARIABLES.GRADIENT_RIGHT} 0.5s ease-out;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  & .animated-element:nth-last-child(-n + 5) {
    ${VARIABLES.GRADIENT_LEFT}: #1d2124;
    ${VARIABLES.GRADIENT_RIGHT}: #1d2124;
  }

  & .animated-element:nth-last-child(-n + 4) {
    ${VARIABLES.GRADIENT_LEFT}: #1d2124;
    ${VARIABLES.GRADIENT_RIGHT}: #a456f6;
  }

  & .animated-element:nth-last-child(-n + 3) {
    ${VARIABLES.GRADIENT_LEFT}: #a456f6;
    ${VARIABLES.GRADIENT_RIGHT}: #c666b1;
  }

  & .animated-element:nth-last-child(-n + 2) {
    ${VARIABLES.GRADIENT_LEFT}: #c666b1;
    ${VARIABLES.GRADIENT_RIGHT}: #e8766b;
  }

  & .animated-element:not(#final-animation):last-child() {
    animation: ${fadeIn} 0.15s ease-in-out infinite;
    ${VARIABLES.GRADIENT_LEFT}: #e8766b;
    ${VARIABLES.GRADIENT_RIGHT}: #fe7a6c;
  }
`;

/**
 * Once issue with the bottom banner is that, since it does not go to the bottom of the
 * autocomplete, if the user scrolls to the top and there's enough text to go under the banner
 * it will be displayed, where there is space betweehn the bottom and the bottom of the banner.
 * This is a workaround to fill the space with the background color.
 */
export const ErrorBannerContainer = styled.div`
  position: absolute;
  bottom: 2px;
  left: 20px;
  right: 20px;
  padding-bottom: 18px;
  background-color: ${xdlColorBackground};
  border-top-left-radius: ${cornerRadiusXL};
  border-top-right-radius: ${cornerRadiusXL};
`;

export const ErrorBanner = styled.div`
  background-color: ${xdlColorBackgroundTertiary};
  padding: ${spaceM};
  padding-right: 42px;
  border-radius: ${cornerRadiusXL};
`;

export const ErrorClose = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const FloatingCard = styled.div`
  background: ${xdlColorBackground};
  border-color: ${xdlColorModalBorder};
  border-radius: 0 0 ${cornerRadiusXL} ${cornerRadiusXL};
  border-style: solid;
  border-width: ${scale005};
  box-shadow: ${dropShadowM};
  position: absolute;
  z-index: ${zIndexElevatedLayer4};

  padding: ${spaceM} ${spaceL};

  list-style: none;
  margin: 0;
  width: 100%;

  &:empty {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  @media ${mediaTopBarHidden} {
    border-radius: ${cornerRadiusXL};
  }
`;
