import type { XingletInterface } from '../../jobs-xinglet';
import { JobsXingletWrapper } from '../../jobs-xinglet';

export type SearchAlertsJobDetailsModalProps = {
  isThirdParty: boolean;
};

export type SearchAlertsJobDetailsModalXinglet =
  XingletInterface<SearchAlertsJobDetailsModalProps>;

export const SearchAlertsJobDetailsModal =
  JobsXingletWrapper<SearchAlertsJobDetailsModalProps>(
    '@xing-com/crate-jobs-domain-search-alerts-job-details-modal'
  );
