import { useLocation } from '@reach/router';
import { useIntl } from 'react-intl';

import type { ButtonProps } from '@xing-com/button';
import { CarouselSection } from '@xing-com/crate-x4e-carousel-section';
import { EntityCard } from '@xing-com/crate-x4e-entity-card';
import { useServerData } from '@xing-com/crate-xinglet';
import { Container } from '@xing-com/layout-primitives';
import type { MarkerVariant } from '@xing-com/marker';
import { xdlColorBackgroundSecondary, scale430 } from '@xing-com/tokens';

import { shuffle } from '../shuffle';
import {
  StyledCarouselSection,
  StyledIcon,
  StyledAnchor,
} from '../startpage-logged-out.styles';

import { RecruitersData } from './data/recruiters-data';

const tracking = {
  recruiter: {
    card: 'losp_recruiter_profile_click',
    button: 'losp_recruiter_profile_click',
  },
};

const handleTracking = ({
  url,
  trackingId,
  cardPosition,
}: {
  url: string;
  trackingId: string;
  cardPosition: number;
}): void => {
  const fullUrl = `${url}?sc_o=${trackingId}&sc_o_PropActionOrigin=losp_recruiter_and_employer_card_position_${cardPosition}`;

  window.location.assign(fullUrl);
};

export const RecruitersSection: React.FC = () => {
  const { search } = useLocation();
  const { formatMessage } = useIntl();
  const queryParams = new URLSearchParams(search);
  const seed = useServerData('recruitersStartPageSeed', Math.random());
  const recruiters =
    queryParams.get('allRecruiters') === 'true'
      ? RecruitersData.sort((a, b) => a.id - b.id)
      : shuffle(RecruitersData, seed).slice(0, 20);
  const DescriptionIcon = (): JSX.Element => (
    <StyledIcon width={20} height={25} />
  );
  const defaultMarker: { variant: MarkerVariant; children: string } = {
    variant: 'highlight',
    children: formatMessage({
      id: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    }),
  };

  return (
    <StyledCarouselSection $bgColor={xdlColorBackgroundSecondary}>
      <Container variant="expanded">
        <CarouselSection
          titleKey="CRATE_LOSP_RECRUITERS_SECTION_TITLE"
          subtitleKey="CRATE_LOSP_RECRUITERS_SECTION_SUBTITLE"
          itemWidth="346px"
          itemMinWidth="346px"
          itemMaxWidth="346px"
          // @ts-expect-error FIXME: SC6
          itemHeight={scale430}
          itemMinHeight={scale430}
          itemMaxHeight={scale430}
          itemSnapPosition="center"
          useFullWidth={true}
        >
          {recruiters.map((cardData, index) => {
            const buttonProps: ButtonProps = {
              variant: 'tertiary',
              href: cardData.profileUrl,
              onClick: (
                event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
              ) => {
                event.preventDefault();
                event.stopPropagation();

                handleTracking({
                  cardPosition: index + 1,
                  url: cardData.profileUrl,
                  trackingId: tracking.recruiter.button,
                });
              },
              children: formatMessage(
                {
                  id: 'CP_TOP_COMPANIES_CARD_BUTTON.other',
                },
                { value: '' }
              ),
            };
            const image = {
              src: cardData.photoUrl,
            };
            const description = formatMessage({
              id: cardData.insight,
            });
            const onCardClick = (event: React.MouseEvent): void => {
              event.preventDefault();
              event.stopPropagation();

              handleTracking({
                cardPosition: index + 1,
                url: cardData.profileUrl,
                trackingId: tracking.recruiter.card,
              });
            };

            return (
              <StyledAnchor
                key={cardData.id}
                href={cardData.profileUrl}
                onClick={onCardClick}
              >
                <EntityCard
                  key={cardData.id}
                  to={cardData.profileUrl}
                  headline={cardData.name}
                  companyLogo={cardData.companyLogo}
                  copy1={cardData.jobTitle}
                  copy2={cardData.company}
                  profileImage={image}
                  markers={[defaultMarker]}
                  button={buttonProps}
                  description={description}
                  descriptionIcon={DescriptionIcon}
                />
              </StyledAnchor>
            );
          })}
        </CarouselSection>
      </Container>
    </StyledCarouselSection>
  );
};
